





































































































































































import axios from "@/services/axiosService";
import { Component, Vue } from 'vue-property-decorator';
import { IRegistrationDetails } from "@/interfaces/registrationDetails";
import TermsOfUseModal from '@/components/elements/TermsOfUseModal.vue';
import Recaptcha from 'vue-recaptcha';
import ToastService from '@/services/toastService';
import { IRequestResponseMessage } from '@/interfaces/requestResponseMessage';

import { namespace } from 'vuex-class';

const Auth = namespace("Auth");

@Component({
    components: {
        TermsOfUseModal,
        Recaptcha
    },
})
export default class RegisterTab extends Vue {

    private showEmailSentMessage = false;
    private isCustomerSelected = true;
    private loaFile: (File | null) = null;
    private sefeFile: (File | null) = null;
    private isTpiSelected = !this.isCustomerSelected;
    public registrationDetails: IRegistrationDetails = { Firstname: "", Lastname: "", Email: "", Telephone: "", CompanyName: "", AccountNumberFinanceReference: "", LOAFile: "", LetterOfAgreementFileName: "", AccessibleUntil: "", CaptchaToken: "", RegistrationTypeCustomer: this.isCustomerSelected, RegistrationTypeTpi: this.isTpiSelected, RegistrationType: "Single", SessionGuid: "00000000-0000-0000-0000-000000000000", LOA: "", Actions: "", SefeFileData: "", SefeFileName: "" };
    public loadingAddRequest = false;
    public loadingRegister = false;
    private readTermOfUse = false;
    public savedRegistrations: IRegistrationDetails[] = [];
    public savedRegistrationsFields = ['Firstname', 'Lastname', 'Email', 'Telephone', 'CompanyName', 'AccountNumberFinanceReference', 'LOA','Actions'];
 
    private isActive = false;
    private isSFActive = false;

    private minimumAccessibleUntilDate = new Date();

    get isInMultipleRequestMode(): boolean {
        return this.savedRegistrations.length > 0;
    }

    public formatAccountNumber(accountNumber: string): string {
        return (accountNumber.length <= 40 ? accountNumber : accountNumber.substring(0, 40) + '...');
    }

    public handleOnTabClicked(arg: any): void {

        this.readTermOfUse = false;
        this.showEmailSentMessage = false;
        this.savedRegistrations = [];
        this.resetForm();            
    }

    public loadFile(file: File) {
        let ext = "";
        let validExt = true;
        if (file !== null) {
            ext = ((file as any).name.match(/.([^.]+)$/)[1]).toLowerCase();

            switch (ext) {
                case 'pdf':
                case 'doc':
                case 'docx':
                case 'png':
                case 'jpg':
                case 'jpeg':
                case 'heic':
                    break;
                default:
                    validExt = false;
            }

            if (validExt) {
                this.isActive = true;
                const reader = new FileReader();
                let model = this.registrationDetails;
                reader.onload = function () {
                    const loaContent = reader.result as string;
                    model.LOAFile = loaContent;
                    model.LetterOfAgreementFileName = file.name;
                }
                reader.readAsDataURL(file);
            } else {
                this.$bvToast.toast("Only .pdf, .doc, .docx, .jpg, .jpeg, .png, .heic files can be uploaded.", ToastService.ErrorMessageDefaults());
                this.loaFile = null;
                this.isActive = false;
            }
        }
    }

    public loadSefeFile(file: File) {
        let ext = "";
        let validExt = true;
        if (file !== null) {
            ext = ((file as any).name.match(/.([^.]+)$/)[1]).toLowerCase();

            switch (ext) {
                case 'pdf':
                case 'doc':
                case 'docx':
                case 'png':
                case 'jpg':
                case 'jpeg':
                case 'heic':
                    break;
                default:
                    validExt = false;
            }

            if (validExt) {
                this.isSFActive = true;
                const reader = new FileReader();
                let model = this.registrationDetails;
                reader.onload = function () {
                    const sefeContent = reader.result as string;
                    model.SefeFileData = sefeContent;
                    model.SefeFileName = file.name;
                }
                reader.readAsDataURL(file);
            } else {
                this.$bvToast.toast("Only .pdf, .doc, .docx, .jpg, .jpeg, .png, .heic files can be uploaded.", ToastService.ErrorMessageDefaults());
                this.sefeFile = null;
                this.isSFActive = false;
            }
        }
    }

    public handleRegisterAllTpis(): void {

        this.registrationDetails.RegistrationType = "Submit"

        this.$bvModal.show('modal-confirm-registration');
    }

    public handleRegistration(): void {

        if (this.registrationDetails.RegistrationType == "Multiple") {
            this.loadingAddRequest = true;
        } else {
            this.loadingRegister = true;
        }
        
        this.$validator.validateAll().then((isValid) => {
            if (!isValid) {
                this.loadingRegister = false;
                this.loadingAddRequest = false;
                return;
            }

            if (!this.readTermOfUse) {
                this.$bvToast.toast("You must click the 'Terms of Use' link and agree to the terms and conditions.", ToastService.ErrorMessageDefaults());
            }

            if (!this.registrationDetails.CaptchaToken) {
                this.$bvToast.toast("Please indicate you're not a robot.", ToastService.ErrorMessageDefaults());
            }

            if (this.registrationDetails.Firstname && this.registrationDetails.Lastname && this.registrationDetails.Email
                && this.registrationDetails.Telephone && this.registrationDetails.CompanyName && this.registrationDetails.AccountNumberFinanceReference && this.readTermOfUse && this.registrationDetails.CaptchaToken) {
                if (this.registrationDetails.RegistrationType == 'Single') {
                    this.$bvModal.show('modal-confirm-registration'); // only show the confirmation when doing a single request
                } else {
                    this.handleConfirmOk(null);
                }

                return;
            }
            this.loadingRegister = false;
            this.loadingAddRequest = false;
        });
        return;
    }
    

    public handleDeleteClicked(registrationId: string): void {

        axios.get<IRequestResponseMessage>("/userAccess/register/deleteRegistration?TpiRegistrationDetailsRequestId=" + registrationId)
            .then(response => {
                this.loadingRegister = false;

                for (let i = 0; i < this.savedRegistrations.length; i++) {
                    let registration = this.savedRegistrations[i];

                    if (String(registration.TpiRegistrationDetailId) === registrationId) {
                        this.savedRegistrations.splice(i, 1);

                        if (this.savedRegistrations.length === 0) {                                
                            this.resetForm();
                        }

                        return;
                    }
                }

            }, (error) => {
                console.error(error);
                this.loadingRegister = false;
            });
    }
 

    public handleAcceptTermOfUse(bvModalEvent: any): void {
        this.readTermOfUse = true;
    }

    public handleDeclineTermOfUse(): void {
        this.readTermOfUse = false;
    }

    public handleConfirmOk(bvModalEvent: any): void {

        if (this.isCustomerSelected) {
            axios.post<IRequestResponseMessage>("/userAccess/register", this.registrationDetails)
                .then(response => {
                    this.loadingRegister = false;

                    if ((response as any).content != null) {
                        var tpiResponse = JSON.parse((response as any).content);

                        this.showEmailSentMessage = true;
                    }
                }, (error) => {
                    console.error(error);
                    this.loadingRegister = false;
                });

            return;
        }

        if (this.registrationDetails.RegistrationType == "Submit") {
            axios.post<IRequestResponseMessage>("/userAccess/register/submitRegistrations", this.registrationDetails)
                .then(response => {
                    this.loadingRegister = false;

                    this.showEmailSentMessage = true;

                }, (error) => {
                    console.error(error);
                    this.loadingRegister = false;
                });

        } else {

            axios.post<IRequestResponseMessage>("/userAccess/register/tpi", this.registrationDetails)
                .then(response => {
                    this.loadingRegister = false;
                    this.loadingAddRequest = false;

                    if (this.registrationDetails.RegistrationType == "Multiple") {

                        if ((response as any).content != null) {
                            var tpiResponse = JSON.parse((response as any).content);
                            var newRegistrationDetails = tpiResponse as IRegistrationDetails;

                            newRegistrationDetails.LOA = String(newRegistrationDetails.TpiRegistrationDetailId);
                            
                            this.savedRegistrations.push(newRegistrationDetails);

                            this.registrationDetails.SessionGuid = tpiResponse.SessionGuid;

                            this.resetForm();
                        }
                    } else if (this.registrationDetails.RegistrationType == "Single") {

                        if ((response as any).content != null) {
                            var tpiResponse2 = JSON.parse((response as any).content);
                            
                            this.showEmailSentMessage = true;
                        }
                    }
                }, (error) => {
                    console.error(error);

                    this.loadingRegister = false;
                    this.loadingAddRequest = false;

                });
        }

    }
    public resetForm(): void {

        if (!this.isInMultipleRequestMode) { // in multiple request mode, do not clear the customer fields
            this.registrationDetails.Firstname = "";
            this.registrationDetails.Lastname = "";
            this.registrationDetails.Email = "";
            this.registrationDetails.Telephone = "";
            this.registrationDetails.CompanyName = "";
            this.registrationDetails.SefeFileData = "";
            this.registrationDetails.SefeFileName = "";
        }

        this.registrationDetails.AccountNumberFinanceReference = "";
        this.registrationDetails.LOAFile = "";
        this.registrationDetails.LetterOfAgreementFileName = "";
        this.registrationDetails.AccessibleUntil = "";

        this.removeSelectedFile();
        this.removeSefeFile();

        this.$validator.reset();
    }        

    public handleConfirmCancel(bvModalEvent: any): void {            
        this.loadingRegister = false;
    }

    public onVerify(response: any): void {
        if (response != null && response != undefined && response != "") {
            this.registrationDetails.CaptchaToken = response;
        }
    }

    public onExpired(): void {
        console.log('Expired');
    }

    public removeSelectedFile() {
        this.loaFile = null;
        this.isActive = false;
    }

    public removeSefeFile() {
        this.sefeFile = null;
        this.isSFActive = false;
    }

    public handleAddNewRequestClicked(): void {
        this.registrationDetails.RegistrationType = "Multiple";
    }

    public toggleIfCustomerSelected() {

        if (this.isCustomerSelected) {
            return; // already selected.
        }

        this.isCustomerSelected = !this.isCustomerSelected;
        this.isTpiSelected = !this.isCustomerSelected;

        this.registrationDetails.RegistrationTypeCustomer = this.isCustomerSelected;
        this.registrationDetails.RegistrationTypeTpi = this.isTpiSelected;
    }

    public toggleIfTpiSelected() {

        if (this.isTpiSelected) {
            return; // already selected.
        }

        this.isTpiSelected = !this.isTpiSelected;
        this.isCustomerSelected = !this.isTpiSelected;

        this.registrationDetails.RegistrationTypeCustomer = this.isCustomerSelected;
        this.registrationDetails.RegistrationTypeTpi = this.isTpiSelected;
    }

    unmounted() {
        this.showEmailSentMessage = false;
        this.isCustomerSelected = true;
        this.loaFile = null;
        this.sefeFile = null;
        this.isTpiSelected = !this.isCustomerSelected;
        this.registrationDetails = { Firstname: "", Lastname: "", Email: "", Telephone: "", CompanyName: "", AccountNumberFinanceReference: "", LOAFile: "", LetterOfAgreementFileName: "", AccessibleUntil: "", CaptchaToken: "", RegistrationTypeCustomer: this.isCustomerSelected, RegistrationTypeTpi: this.isTpiSelected, RegistrationType: "Single", SessionGuid: "00000000-0000-0000-0000-000000000000", LOA: "", Actions: "", SefeFileData: "", SefeFileName: "" };
        this.loadingRegister = false;
        this.loadingAddRequest = false;
        this.readTermOfUse = false;
    }
}
