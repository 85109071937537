import Axios from "@/services/axiosService";

const CONTROLLER = "/featuretoggle/";

export default class FeatureToggleService {

    public async getStatus(data: any) {
        const axios = Axios.create();
        const response = await axios.get(`${CONTROLLER}GetStatus`, { params: { userId: data.userId, featureToggleName: data.featureToggleName } });
        return response.data;
    }
}