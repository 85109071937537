import axios from '@/services/axiosService';
import newAxios from "@/services/invoicesAxiosService";
import { IManageChangeEmergencyContactRequestParams } from "@/interfaces/manageChangeEmergencyContactRequestParams";
import { IManageChangeBillingAddressRequestParams } from "@/interfaces/manageChangeBillingAddressRequestParams";
import { IManageChangeSiteAddressRequestParams } from "@/interfaces/manageChangeSiteAddressRequestParams";

import { IManageBillingAddressPrepopulated } from "@/interfaces/manageBillingAddressPrepopulated";
import { IManageSiteAddressPrepopulated } from "@/interfaces/manageSiteAddressPrepopulated";

const CONTROLLER = "/Manage/";

export default class ManageService {    

    public async sendEmergencyContactDetails(data: IManageChangeEmergencyContactRequestParams): Promise<boolean> {
        let emailSent = false;

        const response = await newAxios
            .get(`${CONTROLLER}EmergencyContactForm`, { params: data })
            .then(result => {
                emailSent = JSON.parse(result.data.content)
            });

        return emailSent;
    }

    public async sendBillingAddressDetails(data: IManageChangeBillingAddressRequestParams): Promise<boolean> {
        let emailSent = false;

        const response = await newAxios
            .get(`${CONTROLLER}ChangeBillingAddress`, { params: data })
            .then(result => {
                emailSent = JSON.parse(result.data.content)
            });

        return emailSent;
    }

    public async sendSiteAddressDetails(data: IManageChangeSiteAddressRequestParams): Promise<boolean> {
        let emailSent = false;

        const response = await newAxios
            .get(`${CONTROLLER}ChangeSiteAddress`, { params: data })
            .then(result => {
                emailSent = JSON.parse(result.data.content)
            });

        return emailSent;
    }

    public async getPrepopulatedData(userId: string, accountNumber: string, siteRef: string): Promise<IManageBillingAddressPrepopulated> {
        const response = await newAxios.get(`${CONTROLLER}GetCurrentBillingAddress?userId=${userId}&accountNumber=${accountNumber}&siteRef=${siteRef}`);
        const result = JSON.parse(response.data.content) as IManageBillingAddressPrepopulated;

        return result;
    }

    public async getSiteAddress(userId: string, accountNumber: string, siteRef: string): Promise<IManageSiteAddressPrepopulated> {
        const response = await newAxios.get(`${CONTROLLER}GetCurrentSiteAddress?userId=${userId}&accountNumber=${accountNumber}&siteRef=${siteRef}`);
        const result = JSON.parse(response.data.content) as IManageSiteAddressPrepopulated;

        return result;
    }
}