import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import InvoicePowerService from '@/services/invoicesPowerService';
import InvoicePowerFlexService from '@/services/invoicesPowerFlexService';
import { IInvoiceDetailsPower } from '@/interfaces/invoiceDetailsPower';
import { IFileDownload } from '@/interfaces/fileDownload';

@Module({ namespaced: true })
export default class PowerInvoices extends VuexModule {
    private invoicesService = new InvoicePowerService();
    private invoicesFlexService = new InvoicePowerFlexService();
    private invoices: IInvoiceDetailsPower[] = [];
    private downloadFileData: IFileDownload | null = null;

    get Invoices() {
        return this.invoices;
    }

    get getDownloadFileData() {
        return this.downloadFileData;
    }

    //Mutations
    @Mutation
    private setInvoices(invoices: any): void {
        this.invoices = invoices;
    }

    @Mutation
    private setDownloadFile(downloadFileData: any): void {
        this.downloadFileData = downloadFileData;
    }

    //Actions
    @Action({ rawError: true })
    private async getInvoices(params: { accountNum: string, siteRefNum: string, userId: string }): Promise<void> {
        const invoices = await this.invoicesService.fetchInvoices(params.accountNum, params.userId);
        this.context.commit('setInvoices', invoices);
    }

    @Action({ rawError: true })
    private async getFlexInvoices(params: { accountNum: string, siteRefNum: string, userId: string }): Promise<void> {
        const invoices = await this.invoicesFlexService.fetchInvoices(params.accountNum, params.userId);
        this.context.commit('setInvoices', invoices);
    }

    @Action({ rawError: true })
    public async invoiceDownload(params: { accountNum, invoiceNo, userId }): Promise<void> {
        const downloadFileResponse = await this.invoicesService.invoiceDownload(params.accountNum, params.invoiceNo, params.userId);
        this.context.commit('setDownloadFile', downloadFileResponse);
    }

    @Action({ rawError: true })
    public async flexInvoiceDownload(params: { accountNum, invoiceNo, userId }): Promise<void> {
        const downloadFileResponse = await this.invoicesFlexService.invoiceDownload(params.accountNum, params.invoiceNo, params.userId);
        this.context.commit('setDownloadFile', downloadFileResponse);
    }
}