















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from "vuex-class";
//import components
import BasePanel from '@/components/panels/BasePanel.vue';
import ClipLoader from '@/components/elements/ClipLoader.vue';
import ItemLabel from '@/components/elements/ItemLabel.vue';
import MainItemLabel from '@/components/elements/MainItemLabel.vue';
import CtaButton from '@/components/elements/CtaButton.vue';
import { IAccountBalanceInformation } from '../../interfaces/accountBalanceInformation';

const Auth = namespace("Auth");
const GasAccounts = namespace("GasAccounts");
const Panel = namespace("Panel");

@Component({
    components: {
        BasePanel,
        ClipLoader,
        ItemLabel,
        MainItemLabel,
        CtaButton
    },
    filters: {
        formatAmount(value: any) {

            var formattedAmount = '£ ' + value.replace("-", "");

            return formattedAmount;
        },
        setBalanceStatus(value: any) {
            if (value > 0) return "Credit";
            if (value < 0) return "Debit";
            return "";
        },
        setBadgeStatus(value: any) {

            if (value > 0) return "badge-credit";
            if (value < 0) return "badge-debit";
            return "";
        }

    }
})
export default class MyBalancePanel extends Vue {

    @Prop() readonly componentTitle!: string;
    @Prop() readonly isUnavailable!: boolean;

    public unwatch: any;

    @Auth.Getter
    private getUserId!: string;

    @GasAccounts.Getter
    private getSelectedSiteRefNumber!: string;

    @GasAccounts.Getter
    private getSelectedContractId!: number;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @GasAccounts.Getter
    private isLargeUser!: boolean;

    @Panel.Getter
    private AccountBalanceInformation!: IAccountBalanceInformation | null;

    @Panel.Action
    private getBalanceInformation!: (userData: any) => Promise<any>;

    private loading = true;
    public componentLabels = {
        title: "My account balance",
        totalBalanceLabel: "Total balance",
        overdueBalanceLabel: "Overdue balance",
        DueDateLabel: "Balance due",
        ctaButtonLabel: "View balance information",
        ctaButtonHref: { name: "Balance" },
        isUnavailable: false
    };

    async created() {
        try {
            this.unwatch = this.$store.watch(
                (state, getters) => getters["GasAccounts/getSelectedSiteRefNumber"],
                (newValue, oldValue) => {
                    if (newValue === null || newValue === oldValue) {
                        return;
                    }
                    this.loading = true;

                    const siteRefNumber = this.getSelectedSiteRefNumber;
                    const contractId = this.getSelectedContractId;

                    if (siteRefNumber !== null && contractId !== 0) {
                        this.getBalanceInformation({ UserId: this.getUserId, AccountNumber: this.getSelectedAccount, SiteRefNumber: siteRefNumber, ContractId: contractId, isLargeUser: this.isLargeUser }).then(() => {
                            this.loading = false;
                        }).catch(reason => {
                            this.loading = false;
                            console.error(reason);
                        });
                    }
                }
                , { immediate: true });
        } catch (e) {
            this.loading = false;
            console.error(e);
        }
    }

    beforeDestroy() {
        this.unwatch();
    }
}
