import axios from '@/services/axiosService';
import newAxios from "@/services/reportsAxiosService";
import { IFileDownload } from "@/interfaces/fileDownload";
import getFileName from "@/helpers/fileNameHelper"
import { IDailyDownloadConsumptionReportRequestParams } from "@/interfaces/dailyDownloadConsumptionReportRequestParams";

const CONTROLLER = "/DailyConsumptionReports/";

export default class DailyConsumptionReportsService {    

    public async getIsActiveDM(userId: string, accountNum : string, siteRef: string): Promise<boolean> {
        let IsActiveDM = false;

        const response = await newAxios.get(`${CONTROLLER}GetAgreementIsActiveDM`, {
            params:
            {
                userId: userId,
                accountNum: accountNum,
                siteRef: siteRef
            }, responseType:"json"
        }).then(result => {
            IsActiveDM = JSON.parse(result.data);
        });

        return IsActiveDM;
    }

    public async downloadDailyConsumptionReport(userId: string, mprn: string, siteRefNum: string, accountNum: string, startDate: string, endDate: string): Promise<any> {

        try {
            const response = await newAxios.get(`${CONTROLLER}DownloadDailyConsumptionReport`, {
                params:
                {
                    userId: userId,
                    mprn: mprn,
                    siteRefNum: siteRefNum,
                    accountNum: accountNum,
                    startDate: startDate,
                    endDate: endDate
                }, responseType: "blob"
            });

            const fileName = getFileName(response.headers['content-disposition']);

            if (!fileName) {
                throw new Error("Report could not be downloaded");
            }

            const file = {
                fileName: fileName,
                data: response.data as Blob
            } as IFileDownload;

            return file;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }
}