














import BasePanel from '@/components/panels/BasePanel.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        BasePanel
    }
})
export default class SimplePanel extends Vue {
    @Prop({ type: String, default: "" }) readonly componentHrefLink!: string;
    @Prop({ type: String, default: "" }) readonly componentHrefLinkLabel!: string;
    @Prop({ type: String, default: "" }) readonly componentHeaderLabel!: string;
}

