








import { Component, Vue } from "vue-property-decorator";
import AuthService from '@/services/authService'

import CenteredLayout from "@/components/layouts/CenteredLayout.vue";
import ResetPasswordTab from "@/components/ResetPasswordTab.vue"

@Component<ResetPassword>({
    components: {
        CenteredLayout,
        ResetPasswordTab
    },
    async beforeRouteEnter(to, from, next): Promise<any> {
        let authService = new AuthService(null);
        const token = encodeURI((to as any).query.token);
        const result = await authService.verifyResetToken(token);

        next((vm) => {
            vm.showResetPasswordTab = Boolean(result);
        })
    }
})
export default class ResetPassword extends Vue {
    public showResetPasswordTab = false;

}
