

























































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const Auth = namespace("Auth");

@Component
export default class HeaderSection extends Vue {

    public username = "";
    public cleanName = "";

    public impersonatedUserEmail: string | null = null;
    public showImpersonatedBanner = false;

    @Auth.Getter
    private getUserEmail!: string;

    @Auth.Getter
    private getUserId!: string;


    @Auth.Getter
    private isInImpersonationMode!: boolean;

    @Auth.Getter
    private getImpersonatedUserEmail!: string;
    
    @Auth.Getter
    private isLoggedIn!: boolean;

    @Auth.Getter
    private isGazpromAdmin!: boolean;

    @Auth.Action        
    private cancelImpersonationMode!: () => Promise<void>;

    @Auth.Action
    private signOut!: () => any;

    @Auth.Action
    private loadImpersonatedUser!: (params: { userId: string, impersonatedUserEmail: string }) => Promise<boolean>;

    @Auth.Action
    private loadImpersonatedUserViaUserId!: (params: { userId: string, impersonatedUserId: string }) => Promise<void>;

    public handleLogout(): void {
        this.signOut();

        setTimeout(() => {
            this.$router.push("/");
            this.$store.dispatch("clearAllStates");
        }, 1000); // give enough time for the IFrame in the signOut method to sign the user out of old portal
    }

    public handleImpersonateUser(): void {

        this.loadImpersonatedUser({ userId: this.getUserId, impersonatedUserEmail: this.impersonatedUserEmail ?? "" }).then((success : boolean) => {
            //window.location.search += '?userId=' + this.getUserId;
            if (success) {
                location.reload();
            }

        }).catch(reason => {

            console.error(reason);
        });
    }

    public handleImpersonateModeClick(): void {

        this.showImpersonatedBanner = true;
    }

    public handleCancelImpersonationModeClick(): void {
        this.showImpersonatedBanner = false;

        this.cancelImpersonationMode();

        location.reload();            
    }

    mounted() {
        this.username = this.getUserEmail;
        this.cleanName = this.username.substring(0, this.username?.indexOf('@'));

        this.checkForImpersonatedUser();
    }

    public checkForImpersonatedUser(): void {

        if (this.isInImpersonationMode) {
            this.loadImpersonatedUserViaUserId({ userId: this.getUserId, impersonatedUserId: this.getUserId }).then(() => { 
                this.impersonatedUserEmail = this.getImpersonatedUserEmail;
                this.showImpersonatedBanner = true;

            }).catch(reason => {

                console.error(reason);
            });
        }
    }
}     

