import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import EnergyCostReportsService  from '@/services/energyCostReportsService';
import { IConsumptionReportsRequestParams } from "@/interfaces/consumptionReportsRequestParams";

@Module({ namespaced: true })
export default class EnergyCostReports extends VuexModule {

    private EnergyCostReportsService = new EnergyCostReportsService();

    private downloadFileData: { data: Blob | null, fileName: string | null } | null = null;

    get getDownloadFileData() {
        return this.downloadFileData;
    }

    @Mutation
    private setDownloadFile(data: any) {
        this.downloadFileData = data;
    }   

    @Action({ rawError: true })
    public async submitEnergyCostReportsRequest(params: IConsumptionReportsRequestParams): Promise<void> {
        const downloadFileData = await this.EnergyCostReportsService.generateEnergyCostReports(params);

        this.context.commit('setDownloadFile', downloadFileData);
    }

}