








































import BasePanel from '@/components/panels/BasePanel.vue';
import ClipLoader from '@/components/elements/ClipLoader.vue';
import ItemLabel from '@/components/elements/ItemLabel.vue';
import MainItemLabel from '@/components/elements/MainItemLabel.vue';
import CtaButton from '@/components/elements/CtaButton.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IInvoice } from '../../interfaces/invoice';
import { IDeliveryAgreement } from "@/interfaces/deliveryAgreement";

const Auth = namespace("Auth");
const GasAccounts = namespace("GasAccounts");
const Panel = namespace("Panel");
const Invoices = namespace("Invoices");
const FeatureToggle = namespace("FeatureToggle");

@Component({
    components: {
        BasePanel,
        ClipLoader,
        ItemLabel,
        MainItemLabel,
        CtaButton
    },
    filters: {
        formatAmount(value: any) {

            if (value === null) {
                return "";
            }

            var formattedAmount = '£ ' + value.replace("-", "");

            return formattedAmount;
        },
    }
})



export default class LatestInvoicePanel extends Vue {
    private loading = true;
    private showDownloadProgressBar = false;
    public componentLabels = {
        title: "My latest invoice",
        invoiceAmountLabel: "Invoice amount",
        dueDateLabel: "Date issued",
        ctaButtonLabel: "View all invoices",
        ctaButtonHref: { 'name': "Invoicing" },
        latestInvoiceLinkHref: "InvoiceGas/SiteInvoices/Download",
        latestInvoiceLinkTitle: "Download latest invoice as PDF",
        isUnavailable: false
    };

    public unwatch: any;

    @Auth.Getter
    private getUserId!: string;

    @GasAccounts.Getter
    private getSelectedDeliveryAgreement!: IDeliveryAgreement;

    @GasAccounts.Getter
    private getSelectedSiteRefNumber!: string;

    @GasAccounts.Getter
    private getSelectedContractId!: number;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @GasAccounts.Getter
    private isLargeUser!: boolean;

    @FeatureToggle.Getter
    private getInvoiceAndMeterReadPanelFeatureToggleStatus!: boolean | false;

    @Panel.Getter
    private Invoices!: IInvoice | null;
    
    @Panel.Action
    private getInvoices!: (userData: any) => Promise<any>;

    @Invoices.Action
    private invoiceDownload!: (params: { accountNum: string, invoiceNo: string, userId: string }) => Promise<void>;

    @Invoices.Getter
    private getDownloadFileData!: { data: Blob | null, fileName: string | null };
    
    @FeatureToggle.Action
    private getInvoiceAndMeterReadPanelFeatureToggleStatusAction!: (userId: any) => Promise<any>;

    async created() {
        try {
            this.unwatch = this.$store.watch(
                (state, getters) => getters["GasAccounts/getSelectedDeliveryAgreementNumber"],
                (newValue, oldValue) => {
                    if (newValue === null || newValue === oldValue) {
                        return;
                    }
                    this.loading = true;

                    const siteRefNumber = this.getSelectedSiteRefNumber;
                    const contractId = this.getSelectedContractId;
                    

                    this.getInvoiceAndMeterReadPanelFeatureToggleStatusAction(this.getUserId).then(x => {
                        console.log("FeatureToggleStatus: " + this.getInvoiceAndMeterReadPanelFeatureToggleStatus)
                        if (this.getInvoiceAndMeterReadPanelFeatureToggleStatus && siteRefNumber !== null && contractId !== 0) {
                            this.getInvoices({ UserId: this.getUserId, AccountNumber: this.getSelectedAccount, SiteRefNumber: siteRefNumber, ContractId: contractId, isLargeUser: this.isLargeUser }).then(() => {
                                this.loading = false;
                            }).catch(reason => {
                                this.loading = false;
                                console.error(reason);
                            });
                        } else {
                            this.loading = false;
                        }
                    });
                }
                , { immediate: true });
        } catch (e) {
            this.loading = false;
            console.error(e);
        }
    }

    beforeDestroy() {
        this.unwatch();
    }      
    
    private onDownloadClick() {
        this.showDownloadProgressBar = true;
        const accountNum = this.getSelectedAccount;
        const userId = this.getUserId;

        const invoiceNo = this.Invoices?.invoiceNo;

        if (invoiceNo == null) {
            return;
        }

        this.invoiceDownload({ accountNum, invoiceNo, userId }).then(() => {
            this.fileProcess();
            this.showDownloadProgressBar = false;
        }).catch(reason => {
            console.error(reason);
            this.showDownloadProgressBar = false;
        });

        return false;
    }

    private fileProcess() {

        if (this.getDownloadFileData == null) {
            return null;
        }

        var fileName = this.getDownloadFileData.fileName;

        if (window.navigator && window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob((this.getDownloadFileData.data) as Blob, fileName as string);
        } else {
            var fileURL = window.URL.createObjectURL((this.getDownloadFileData.data) as Blob);
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', fileName as string);
            document.body.appendChild(fileLink);
            fileLink.click();
        }
    }



}

