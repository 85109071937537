






























import { Component, Vue } from "vue-property-decorator";
import { namespace } from 'vuex-class';
import TermsOfUseModal from '@/components/elements/TermsOfUseModal.vue';
import { ILoginDetails } from "@/interfaces/loginDetails";
import { GtagHelper } from '../utilities/GtagHelper';

const Auth = namespace("Auth");
const Terms = namespace("Terms");

@Component({
    components: {
        TermsOfUseModal
    }
})
export default class LoginTab extends Vue {
    private account: ILoginDetails = { Email: "", Password: "", RememberMe: false };
    public loading = false;

    private GtagHelper = new GtagHelper();

    @Auth.Getter
    private isLoggedIn!: boolean;

    @Auth.Getter
    private getUserId!: string;

    @Auth.Getter
    private getRememberMeAccountEmail!: string;

    @Auth.Action
    private login!: (data: ILoginDetails) => Promise<any>;

    mounted() {
        if (this.isLoggedIn) {
            this.$router.push("/home");
        }
        this.account.Email = this.getRememberMeAccountEmail;
    }

    public handleLogin(): void {
        this.loading = true;
        this.$validator.validateAll().then((isValid) => {
            if (!isValid) {
                this.loading = false;
                return;
            }


            if (this.account.Email && this.account.Password) {
                this.login(this.account).then((data) => {
                   
                    if (this.$route.query["redirectUrl"] != undefined) {
                        this.$router.push(this.$route.query["redirectUrl"] as string);
                    } else {

                        this.$router.push("/home");
                    }                    

                    this.GtagHelper.Event("LumiLogin", this.getUserId);

                    try {   
                        this.$ga.set('dimension3', this.getUserId); // set the userId custom dimension
                    } catch (error) {
                        console.log('GA Event error: ' + 'dimension3');
                    }
                }, (error) => {
                    this.loading = false;
                    console.error(error);
                })
            }
            this.loading = false;
        });
    }
}
