import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import ManageService  from '@/services/manageService';
import { IManageChangeEmergencyContactRequestParams } from "@/interfaces/manageChangeEmergencyContactRequestParams";
import { IManageChangeBillingAddressRequestParams } from "@/interfaces/manageChangeBillingAddressRequestParams";
import { IManageChangeSiteAddressRequestParams } from "@/interfaces/manageChangeSiteAddressRequestParams";

import { IManageBillingAddressPrepopulated } from "@/interfaces/manageBillingAddressPrepopulated";
import { IManageSiteAddressPrepopulated } from "@/interfaces/manageSiteAddressPrepopulated";

@Module({ namespaced: true })
export default class Manage extends VuexModule {

    private manageService = new ManageService();

    private emailSentStatus: boolean | false = false;

    private manageBillingAddressPrepopulated: IManageBillingAddressPrepopulated | null = null;

    private manageSiteAddressPrepopulated: IManageSiteAddressPrepopulated | null = null;


    get getEmailSentStatus() {
        return this.emailSentStatus;
    }

    get getPrepopulatedBillingAddress() {
        return this.manageBillingAddressPrepopulated;
    }

    get getPrepopulatedSiteAddress() {
        return this.manageSiteAddressPrepopulated;
    }

    @Mutation
    private setEmailSentStatus(data: any) {
        this.emailSentStatus = data;
    }

    @Mutation
    private setPrepopulatedBillingAddress(data: any) {
        this.manageBillingAddressPrepopulated = data;
    }

    @Mutation
    private setPrepopulatedSiteAddress(data: any) {
        this.manageSiteAddressPrepopulated = data;
    }

    @Action({ rawError: true })
    public async sendEmergencyContactDetails(params: IManageChangeEmergencyContactRequestParams): Promise<void> {
        const emailSent = await this.manageService.sendEmergencyContactDetails(params);

        this.context.commit('setEmailSentStatus', emailSent);
    }

    @Action({ rawError: true })
    public async sendBillingAddressDetails(params: IManageChangeBillingAddressRequestParams): Promise<void> {
        const emailSent = await this.manageService.sendBillingAddressDetails(params);

        this.context.commit('setEmailSentStatus', emailSent);
    }


    @Action({ rawError: true })
    public async sendSiteAddressDetails(params: IManageChangeSiteAddressRequestParams): Promise<void> {
        const emailSent = await this.manageService.sendSiteAddressDetails(params);

        this.context.commit('setEmailSentStatus', emailSent);
    }

    @Action({ rawError: true })
    public async getBillingAddressPrepopulated(params: { userId: string, accountNumber, siteRef: string }): Promise<void> {
        const data = await this.manageService.getPrepopulatedData(params.userId, params.accountNumber, params.siteRef);

        this.context.commit('setPrepopulatedBillingAddress', data);
    }

    @Action({ rawError: true })
    public async getSiteAddressPrepopulated(params: { userId: string, accountNumber: string, siteRef: string }): Promise<void> {
        const data = await this.manageService.getSiteAddress(params.userId, params.accountNumber, params.siteRef);

        this.context.commit('setPrepopulatedSiteAddress', data);
    }
}