






import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ClipLoader extends Vue {
    @Prop({ type: Boolean, default: true }) readonly loading!: boolean;
    @Prop({ type: String, default: '#5dc596' }) readonly color!: string;
    @Prop({ type: String, default: '35px' }) readonly size!: boolean;
    @Prop({ type: String, default: '100%' }) readonly radius!: string;

    get spinnerStyle() {
        return {
            height: this.size,
            width: this.size,
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: this.color + ' ' + this.color + ' transparent',
            borderRadius: this.radius,
            background: 'transparent'
        };
    }
}
