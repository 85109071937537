





























































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DropdownHover from "@/components/DropdownHover.vue"

const Auth = namespace("Auth");
const Products = namespace("Products");
const GasAccounts = namespace("GasAccounts");
const DMConsumptionReports = namespace("DMConsumptionReports");
const PowerAccounts = namespace("PowerAccounts");


@Component({
    components: {
        DropdownHover
    }
})
export default class NavMenu extends Vue {
    @Auth.Getter
    private getUserId!: string;
    private watchGasAgreementSelection: any;
    private helpHref = "";
    private locationHref = "";
    private processing = false;

    @Products.Getter
    private getSelectedProduct!: string;

    @GasAccounts.Getter
    private getSelectedSiteRefNumber!: string;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @Auth.Getter
    private isGazpromAdmin!: boolean;

    @Auth.Getter
    private canViewAdminMenu!: boolean;

    @Auth.Getter
    private canViewContentMenu!: boolean;

    @Auth.Getter
    private canAccessAllBaskets!: boolean;

    @Auth.Getter
    private getPortalAuthTokenUrl!: string;

    @Auth.Getter
    private canDownloadCogReports!: boolean;

    @Auth.Getter
    private getIsNavDisabled!: boolean;

    @Auth.Getter
    private canViewTPIRequestMenu!: boolean;

    @DMConsumptionReports.Getter
    private getIsActiveDM!: boolean;

    @Auth.Action
    private fetchPortalAuthTokenUrl!: (params: { userId: string }) => Promise<void>;

    @DMConsumptionReports.Action
    private submitIsActiveDM!: (params: { userId: string, accountNum: string, siteRef: string }) => Promise<void>;

    @PowerAccounts.Getter
    private isHalfHourly!: boolean;


    async mounted() {
        try {
            this.locationHref = window.location.href;
            this.fetchPortalAuthTokenUrl({ userId: this.getUserId }).then(() => {
                this.setAdminLinks();
            }, (error) => {
                console.error(error);
            });

            this.submitIsActiveDM({ userId: this.getUserId, accountNum: this.getSelectedAccount, siteRef: this.getSelectedSiteRefNumber });
          
            try {
                this.watchGasAgreementSelection = this.$store.watch((state, getters) => getters["GasAccounts/getSelectedSiteRefNumber"], (newValue, oldValue) => {
                    if (newValue === null || newValue === oldValue) {
                        return;
                    }

                    this.submitIsActiveDM({ userId: this.getUserId, accountNum: this.getSelectedAccount, siteRef: this.getSelectedSiteRefNumber });

                    this.setAdminLinks();

                });                  
                
            } catch (e) {

                console.error(e);
            }


        } catch (e) {
            console.error(e);
        }
    }

    private setAdminLinks() {
      
        let portalUrl = this.getPortalAuthTokenUrl;

        portalUrl = `${portalUrl}&accountNum=${this.getSelectedAccount}&siteRefNum=${this.getSelectedSiteRefNumber}`;

        this.helpHref = `${portalUrl}&chosenController=HelpAdvice&chosenAction=Index`;
    }

    beforeDestroy() {
        this.watchGasAgreementSelection();
    }

    private setReportingActive(): void {
        let ele = document.querySelector("#navMenu-reporting li");
        if (ele != null && (this.locationHref.includes("billing") || this.locationHref.includes("consumption"))) {
            ele.classList.add('nav-selected');
        }
    }

    private setManageActive(): void {
        let ele = document.querySelector("#navMenu-manageMyAccount li");
        if (ele != null && (this.locationHref.includes("change-password"))) {
            ele.classList.add('nav-selected');
        }
    }
}
