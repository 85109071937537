import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import DailyConsumptionReportService  from '@/services/dailyConsumptionReportsService';
import { IDailyDownloadConsumptionReportRequestParams } from "@/interfaces/dailyDownloadConsumptionReportRequestParams";

@Module({ namespaced: true })
export default class DailyConsumptionReports extends VuexModule {

    private dailyConsumptionReportsService = new DailyConsumptionReportService();

    private downloadFileData: { data: Blob | null, fileName: string | null } | null = null;

    private IsActiveDM: boolean | false = false;

    get getDownloadFileData() {
        return this.downloadFileData;
    }

    get getIsActiveDM() {
        return this.IsActiveDM;
    }

    @Mutation
    private setDownloadFile(data: any) {
        this.downloadFileData = data;
    }

    @Action({ rawError: true })
    public async submitIsActiveDM(params: { userId: string, accountNum: string, siteRef: string }): Promise<void> {
        const DmFlag = await this.dailyConsumptionReportsService.getIsActiveDM(params.userId,params.accountNum,params.siteRef);

        this.context.commit('setDmMeterFlag', DmFlag);
    }

    @Action({ rawError: true })
    public async submitDailyDownloadConsumptionReports(params: { userId: string, mprn: string, siteRefNum: string, accountNum: string, startDate: string, endDate: string }): Promise<void> {

        const downloadFileData = await this.dailyConsumptionReportsService.downloadDailyConsumptionReport(params.userId, params.mprn, params.siteRefNum, params.accountNum, params.startDate, params.endDate);

        this.context.commit('setDownloadFile', downloadFileData);
    }

}