import { Vue } from 'vue-property-decorator';

export class VeeValidate_Custom extends Vue {

    public setupValidationMessages() {


        const DMConsumptionValdiationMessages = {
            custom: {
                dmConsumptionReportDateFrom: {
                    required: 'Report date from is required'
                },
                dmConsumptionReportDateTo: {
                    required: 'Report date to is required'
                }

            }
        };

        const AMRConsumptionValidationMessages = {
            custom: {
                amrConsumptionReportDateFrom: {
                    required: 'Report date from is required'
                },
                amrConsumptionReportDateTo: {
                    required: 'Report date to is required'
                }

            }
        };

        const BulkInvoicesValidationMessages = {
            custom: {
                customInvoiceReportDateFrom: {
                    required: 'Start date is required'
                },
                customInvoiceReportDateTo: {
                    required: 'End date is required'
                }
            }
        };

        this.$validator.localize('en', DMConsumptionValdiationMessages);
        this.$validator.localize('en', AMRConsumptionValidationMessages);
        this.$validator.localize('en', BulkInvoicesValidationMessages);
    }
}
