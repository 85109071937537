import axios from '@/services/axiosService';
import { IGasAccountsViewModel } from "@/interfaces/gasAccountsViewModel"
import { IDeliveryAgreement } from "@/interfaces/deliveryAgreement";

const API_URL = "/GasAccounts/";
export default class GasAccountsService {
    public async fetchUserAccountsCount(userId: string): Promise<number> {
        const response = await axios.get(`${API_URL}GetUserAccountsCount`, { params: { userId: userId } });
        const result = JSON.parse((response as any).content) as Promise<number>;

        return result;
    }

    public async fetchUserAccounts(userId: string, size: number, accountNumberQuery: (string | null), isLargeUser: boolean): Promise<IGasAccountsViewModel> {
        const response = await axios.get(`${API_URL}GetUserAccounts`, { params: { userId: userId, size: size, accountNumberQuery: accountNumberQuery, isLargeUser: isLargeUser } });
        const result = JSON.parse((response as any).content) as IGasAccountsViewModel;
        return result;
    }

    public async searchUserAccounts(userId: string, query: string, page: number, size: number, isLargeUser: boolean): Promise<IGasAccountsViewModel> {
        const response = await axios.get(`${API_URL}SearchUserAccounts`, { params: { userId: userId, query: query, page: page, size: size, isLargeUser: isLargeUser } });
        const result = JSON.parse((response as any).content) as IGasAccountsViewModel;

        return result;
    }

    public async fetchDeliveryAgreementsForAccount(userId: string, accountNumber: string, isLargeUser: boolean): Promise<[IDeliveryAgreement[], string, Array<string>, string] | null> {
        const response = await axios.get(`${API_URL}GetDeliveryAgreementsForAccount`, { params: { userId: userId, accountNumber: accountNumber, isLargeUser: isLargeUser } });

        if (!response) {
            return null;
        }

        const result = JSON.parse((response as any).content);

        const agreements = result.Agreements as IDeliveryAgreement[];
        const selectedAgreementId = result.SelectedAgreementId as string;
        const distinctMPRNS = result.DistinctMPRNS as Array<string>;
        const selectedMPRN = result.SelectedMPRN as string;

        return [agreements, selectedAgreementId, distinctMPRNS, selectedMPRN];
    }

    public async addCategoryLabelsToAgreements(agreements: Array<IDeliveryAgreement>,userId :string ): Promise<IDeliveryAgreement[]> {
        const response = await axios.post(`${API_URL}GetAgreementsWithCategoryLabels`, { agreements, userId: userId }   );

        const result = JSON.parse((response as any).content) as IDeliveryAgreement[];

        return result;
    }

    public async getAgreementIdToSelect(agreements: Array<IDeliveryAgreement>,userId:string): Promise<[string, string]> {
        const response = await axios.post(`${API_URL}GetAgreementIdToSelect`, { agreements, userId: userId });

        const result = JSON.parse((response as any).content);

        const agreement = result.agreement as string;
        const mprn = result.mprn as string;

        return [agreement, mprn];
    }
}