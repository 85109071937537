import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import ConsumptionReportsService  from '@/services/consumptionReportsService';
import { IConsumptionReportsRequestParams } from "@/interfaces/consumptionReportsRequestParams";
import { IConsumptionReportsAllAccountsRequestParams } from "@/interfaces/consumptionReportsAllAccountsRequestParams";

@Module({ namespaced: true })
export default class ConsumptionReports extends VuexModule {

    private consumptionReportsService = new ConsumptionReportsService();

    private downloadFileData: { data: Blob | null, fileName: string | null } | null = null;

    get getDownloadFileData() {
        return this.downloadFileData;
    }

    @Mutation
    private setDownloadFile(data: any) {
        this.downloadFileData = data;
    }   

    @Action({ rawError: true })
    public async submitConsumptionReportsRequest(params: IConsumptionReportsRequestParams): Promise<void> {
        const downloadFileData = await this.consumptionReportsService.generateConsumptionReports(params);

        this.context.commit('setDownloadFile', downloadFileData);
    }

    @Action({ rawError: true })
    public async submitConsumptionReportsAllAccountsRequest(params: IConsumptionReportsAllAccountsRequestParams): Promise<void> {
        const downloadFileData = await this.consumptionReportsService.generateConsumptionReportsAllAccounts(params);

        this.context.commit('setDownloadFile', downloadFileData);
    }

}