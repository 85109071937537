






























import { Component, Vue } from "vue-property-decorator";
import { namespace } from 'vuex-class';

const Auth = namespace("Auth");

@Component
export default class ForgottenTab extends Vue {
    private email = "";

    public loading = false;
    public showEmailSentMessage=false;

    @Auth.Getter
    private isLoggedIn!: boolean;

    @Auth.Action
    private sendPasswordResetLink!: (email: string) => Promise<any>;

    mounted() {
        if (this.isLoggedIn) {
            this.$router.push("/home");
        }
    }

    public handleSubmit(): void {
        this.loading = true;

        this.$validator.validateAll().then((isValid) => {
            if (!isValid) {
                this.loading = false;
                return;
            }

            if (this.email) {                    
                this.sendPasswordResetLink(this.email).then((response) => {
                    this.showEmailSentMessage = response;
                    this.loading = false;
                }, (error) => {
                    this.loading = false;
                })
            }
        });
    }

}
