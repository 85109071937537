import Vue from 'vue';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import AuthService from '@/services/authService'
import { ILoginDetails } from "@/interfaces/loginDetails"
import { IResetPasswordDetails } from "@/interfaces/resetPasswordDetails"
import { IRegistrationDetails } from '@/interfaces/registrationDetails';
import { ILoginRequestResponse } from '@/interfaces/loginRequestResponse';
import { IChangePassword } from '@/interfaces/changePassword';
import { Claims } from '@/utilities/Claims';

@Module({ namespaced: true })
export default class Account extends VuexModule {

    public authService: AuthService | null = new AuthService(Vue.$cookies);
    public userAccount: ILoginRequestResponse | null = Vue.$cookies.get("account") as ILoginRequestResponse;
    public impersonatedUserAccount: ILoginRequestResponse | null = Vue.$cookies.get("impersonatedAccount") as ILoginRequestResponse;
    public rememberMeAccountEmail = Vue.$cookies.get("accountEmail");

    public status = this.userAccount ? { loggedIn: true } : { loggedIn: false };

    private portalAuthTokenUrl: string | null = null;

    private impersonatedUserEmail: string | null = null;
    private impersonatedUserId: string | null = null;
    private isNavDisabled = false;

    get getUserAccount(): ILoginRequestResponse | null {

        if (this.isInImpersonationMode) {
            return this.impersonatedUserAccount;
        } else {
            return this.userAccount;
        }
    }

    get getUserId(): string {

        if (this.impersonatedUserAccount != null)
        {
            return this.impersonatedUserAccount.identityUser.Id;
        }

        return (this.userAccount as ILoginRequestResponse).identityUser.Id;
    }

    get isInImpersonationMode(): boolean {

        if (this.impersonatedUserAccount != null)
        {
            return true;
        }

        return false;
    }

    get getUserEmail(): string {
        return (this.userAccount as ILoginRequestResponse).identityUser.Email;
    }

    get getImpersonatedUserEmail(): string | null {
        return this.impersonatedUserEmail;
    }

    get getRememberMeAccountEmail(): string {
        return this.rememberMeAccountEmail ? this.rememberMeAccountEmail : "";
    }

    get isGazpromAdmin(): boolean {
        const claim = (this.getUserAccount as ILoginRequestResponse).identityUser.Claims.filter(claim => claim.Type == Claims.canAccessAnyRecord);
        if (claim.length > 0) {
            return (claim[0].Value.toString() == "true");
        }
        return false;
    }

    get canCreateUser(): boolean {
        const claim = (this.getUserAccount as ILoginRequestResponse).identityUser.Claims.filter(claim => claim.Type == 'CanCreateUser');
        if (claim.length > 0) {
            return (claim[0].Value.toString() == "true");
        }
        return false;
    }

    get canManageInternalUser(): boolean {
        const claim = (this.getUserAccount as ILoginRequestResponse).identityUser.Claims.filter(claim => claim.Type == Claims.canManageInternalUser);
        if (claim.length > 0) {
            return (claim[0].Value.toString() == "true");
        }
        return false;
    }

    get canManageExternalUser(): boolean {
        const claim = (this.getUserAccount as ILoginRequestResponse).identityUser.Claims.filter(claim => claim.Type == Claims.canManageExternalUser);
        if (claim.length > 0) {
            return (claim[0].Value.toString() == "true");
        }
        return false;
    }

    get canManageStandardUser(): boolean {
        const claim = (this.getUserAccount as ILoginRequestResponse).identityUser.Claims.filter(claim => claim.Type == Claims.canManageStandardUser);
        if (claim.length > 0) {
            return (claim[0].Value.toString() == "true");
        }
        return false;
    }

    get getPortalAuthTokenUrl(): string | null {

        return this.portalAuthTokenUrl;
    }

    get canViewAdminMenu(): boolean {
        const claim = (this.getUserAccount as ILoginRequestResponse).identityUser.Claims.filter(claim => claim.Type == Claims.canViewAdminMenu);
        if (claim.length > 0) {
            return (claim[0].Value.toString() == "true");
        }
        return false;
    }
    get canViewContentMenu(): boolean {
        const claim = (this.getUserAccount as ILoginRequestResponse).identityUser.Claims.filter(claim => claim.Type == 'CanViewContentMenu');
        if (claim.length > 0) {
            return (claim[0].Value.toString() == "true");
        }
        return false;
    }
    // this is the claim for can manage COG users
    get canAccessAllBaskets(): boolean {
        const claim = (this.getUserAccount as ILoginRequestResponse).identityUser.Claims.filter(claim => claim.Type == Claims.canAccessAllBaskets);
        if (claim.length > 0) {
            return (claim[0].Value.toString() == "true");
        }
        return false;
    }

    get canDownloadCogReports(): boolean {

        const claim = (this.getUserAccount as ILoginRequestResponse).identityUser.Claims.filter(claim => claim.Type == Claims.canDownloadCogReports);
        if (claim.length > 0) {
            return (claim[0].Value.toString() == "true");
        }

        if (this.isGazpromAdmin) {
            return true;
        }

        return false;
    }

    get isLoggedIn(): boolean {
        return this.status.loggedIn;
    }

    get getIsNavDisabled(): boolean {
        return this.isNavDisabled;
    }

    get canViewTPIRequestMenu(): boolean {
        const claim = (this.getUserAccount as ILoginRequestResponse).identityUser.Claims.filter(claim => claim.Type == 'CanRequestAccessToAdditionalAccounts');
        if (claim.length > 0) {
            return (claim[0].Value.toString() == "true");
        }
        return false;
    }

    @Mutation
    public loginSuccess(account: any): void {
        this.status.loggedIn = true;
        this.userAccount = account;
    }

    @Mutation
    public rememberMe(): void {
        this.rememberMeAccountEmail = (this.userAccount as ILoginRequestResponse).identityUser.Email;
        Vue.$cookies.set("accountEmail", (this.userAccount as ILoginRequestResponse).identityUser.Email);
    }

    @Mutation
    public dontRememberMe(): void {
        this.rememberMeAccountEmail = "";
        Vue.$cookies.remove("accountEmail");
    }

    @Mutation
    public loginFailure(): void {
        this.status.loggedIn = false;
        this.userAccount = null as any;
        this.impersonatedUserAccount = null as any;
    }

    @Mutation
    public logout(): void {
        this.status.loggedIn = false;
        this.userAccount = null as any;
        this.impersonatedUserAccount = null as any;
    }

    @Mutation
    public clearState(): void {
        this.userAccount = null;
        this.impersonatedUserAccount = null;
        this.rememberMeAccountEmail = null;
    }

    @Mutation
    private setPortalAuthTokenUrl(data: string) {
        this.portalAuthTokenUrl = data;
    }

    @Mutation
    private setImpersonatedUserId(data: string | null) {
        this.impersonatedUserId = data;
    }

    @Mutation
    private setImpersonatedUserEmail(data: string | null) {
        this.impersonatedUserEmail = data;
    }

    @Mutation
    private setNavDisabled(disabled: boolean) {
        this.isNavDisabled = disabled;
    }

    @Action({ rawError: true })
    public async cancelImpersonationMode(): Promise<void> {
        Vue.$cookies.remove("impersonatedAccount");
        this.context.commit('setImpersonatedUserEmail', null);
        this.context.commit('setImpersonatedUserId', null);
    }

    @Action({ rawError: true })
    login(data: ILoginDetails): Promise<any> {
        return (this.authService as AuthService).login(data)
            .then(account => {
                const result: ILoginRequestResponse = JSON.parse((account as any).content);
                this.context.commit('loginSuccess', result);
                if (data.RememberMe) {
                    this.context.commit('rememberMe')
                } else {
                    this.context.commit('dontRememberMe');
                }
                return Promise.resolve(result);
            },
                error => {
                    this.context.commit('loginFailure');
                    const message =
                        (error.response && error.response.data && error.response.data.message) ?
                            error.message :
                            error.toString();
                    return Promise.reject(message);

                });
    }

    @Action
    signOut(): void {

        const userId = this.getUserId;

        (this.authService as AuthService).logout(userId);
        this.context.commit('logout');
    }

    @Action({ rawError: true })
    sendPasswordResetLink(email: string): any {
        return (this.authService as AuthService).sendPasswordResetLink(email)
            .then(response => {
                return response;
            },
                error => {
                    const message =
                        (error.response && error.response.data && error.response.data.message) ?
                            error.message :
                            error.toString();
                    return message;
                });
    }

    @Action({ rawError: true })
    public async fetchPortalAuthTokenUrl(params: { userId: string }): Promise<void> {
        const authTokenUrl = await this.authService?.getPortalAuthTokenUrl(params.userId);
        this.context.commit('setPortalAuthTokenUrl', authTokenUrl);
    }

    @Action({ rawError: true })
    public async loadImpersonatedUser(params: { userId, impersonatedUserEmail: string }): Promise<boolean> {
        const impersonatedUserId = await this.authService?.getImpersonatedUserId(params.userId, params.impersonatedUserEmail);

        if (impersonatedUserId == null) {
            return false;
        }

        this.context.commit('setImpersonatedUserId', impersonatedUserId);
        this.context.commit('setImpersonatedUserEmail', params.impersonatedUserEmail);

        return true;
    }

    @Action({ rawError: true })
    public async loadImpersonatedUserViaUserId(params: { userId, impersonatedUserId: string }): Promise<void> {
        const impersonatedUserEmail = await this.authService?.getImpersonatedUserEmail(params.userId, params.impersonatedUserId);

        this.context.commit('setImpersonatedUserEmail', impersonatedUserEmail);
        this.context.commit('setImpersonatedUserId', params.impersonatedUserId);
    }

    @Action({ rawError: true })
    resetPassword(resetPasswordDetails: IResetPasswordDetails): Promise<any> {
        return (this.authService as AuthService).resetPassword(resetPasswordDetails)
            .then(response => {
                return response;
            },
                error => {
                    const message =
                        (error.response && error.response.data && error.response.data.message) ?
                            error.message :
                            error.toString();
                    return message;
                });
    }

    @Action({ rawError: true })
    changePassword(changePassword: IChangePassword): Promise<any> {
        return (this.authService as AuthService).changePassword(changePassword)
            .then(response => {
                return response;
            },
                error => {
                    const message =
                        (error.response && error.response.data && error.response.data.message) ?
                            error.message :
                            error.toString();
                    return message;
                });
    }

    @Action({ rawError: true })
    requestAccess(requestDetails: IRegistrationDetails): Promise<any> {
        return (this.authService as AuthService).requestTpiAccess(requestDetails)
            .then(response => {
                return response;
            },
                error => {
                    const message =
                        (error.response && error.response.data && error.response.data.message) ?
                            error.message :
                            error.toString();
                    return message;
                });
    }

    @Action({ rawError: true })
    registerAccount(registrationDetails: IRegistrationDetails): Promise<any> {
        if (registrationDetails.RegistrationTypeTpi) {
            return (this.authService as AuthService).registerTpiAccount(registrationDetails)
                .then(response => {
                    return response;
                },
                    error => {
                        const message =
                            (error.response && error.response.data && error.response.data.message) ?
                                error.message :
                                error.toString();
                        return message;
                    });

        } else {
            return (this.authService as AuthService).registerAccount(registrationDetails)
                .then(response => {
                    return response;
                },
                    error => {
                        const message =
                            (error.response && error.response.data && error.response.data.message) ?
                                error.message :
                                error.toString();
                        return message;
                    });
        }
    }
}