














































































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from 'vuex-class';
import { IResetPasswordDetails } from "@/interfaces/resetPasswordDetails"

const Auth = namespace("Auth");

@Component
export default class ResetPasswordTab extends Vue {
    @Prop() private showInputForm!: boolean;

    private passwordChanged = false;

    private resetPasswordDetails: IResetPasswordDetails = { Email: "", Password: "", Token:"" };
    public loading = false;
    private confirmPassword = "";

    private showPassword = false;
    private showConfirmPassword = false;



    @Auth.Action
    private resetPassword!: (resetPasswordDetails: IResetPasswordDetails) => any;

    mounted() {
        this.resetPasswordDetails.Token = (this.$route as any).query.token;
    }

    public toggleShowPassword(): void {
        this.showPassword = !this.showPassword;           
    }

    public toggleShowConfirmPassword(): void {
        this.showConfirmPassword = !this.showConfirmPassword;
    }

    public handleReset(): void {
        this.loading = true;
        this.$validator.validateAll().then((isValid) => {
            if (!isValid) {
                this.loading = false;
                return;
            }

            if (this.resetPasswordDetails.Password && this.confirmPassword) {
                this.resetPassword(this.resetPasswordDetails).then((response: any) => {
                    if (response === true) {
                        this.passwordChanged = true;
                        this.showInputForm = false;
                    }
                }, (error: any) => {
                    this.loading = false;
                });
            }
            this.loading = false;
        });
    }
   
}
