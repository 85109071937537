import axios from '@/services/axiosService';
import newAxios from "@/services/invoicesAxiosService";
import { IMeterReadsViewModel } from "@/interfaces/meterReadsViewModel";
import { IMeterReadsParams } from "@/interfaces/meterReadsParams";
import { IFileDownload } from "@/interfaces/fileDownload";
import getFileName from "@/helpers/fileNameHelper"
import { IMeterReadSubmitParams } from "@/interfaces/meterReadSubmitParams";
import { IMeterReadingDetail } from '@/interfaces/meterReadingDetail';
import { IMeterReadSubmitResponse} from '@/interfaces/meterReadSubmitResponse';
import { IMultipleMeterReadSubmitParams } from '../interfaces/multipleMeterReadSubmitParams';
import { IMultipleMeterReadSubmitAndSendParams } from '../interfaces/multipleMeterReadSubmitAndSendParams';
import { IMultipleMeterReadSubmitResponse } from '../interfaces/multipleMeterReadSubmitResponse';
import { IMultipleMeterReadSubmitAndSendResponse } from '../interfaces/multipleMeterReadSubmitAndSendResponse';

const CONTROLLER = "/MeterReadsGas/";
const BULK_CONTROLLER = "/BulkMeterReadsUpload/";

export default class MeteringGasService {
    public async getMeterReads(params: IMeterReadsParams): Promise<IMeterReadsViewModel> {
        const response = await axios.get(`${CONTROLLER}GetMeterReads`, { params: params });
        const result = JSON.parse((response as any).content) as IMeterReadsViewModel;
        return result;
    }

    public async downloadReport(params: IMeterReadsParams): Promise<IFileDownload> {
        const response = await newAxios.get(`${CONTROLLER}DownloadReport`, { params: params, responseType: "blob" });

        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Report could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }

    public async downloadMultipleMeterReadsTemplate(userId: string): Promise<IFileDownload> {
        const response = await newAxios.get(`${BULK_CONTROLLER}DownloadMultipleMeterReadsTemplate`, { params: { userId: userId }, responseType: "blob" });

        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Multiple meter readings template could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }


    public async downloadCompletedMultipleMeterReadsTemplate(userId: string, fileName: string): Promise<IFileDownload> {
        const response = await newAxios.get(`${BULK_CONTROLLER}DownloadCompletedMultipleMeterReadsTemplate`, { params: { userId: userId, fileName: fileName }, responseType: "blob" });

        const responseFileName = getFileName(response.headers['content-disposition']);

        if (!responseFileName) {
            throw new Error("Completed Multiple meter readings template could not be downloaded");
        }

        const file = {
            fileName: responseFileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }


    public async getPreviousMeterRead(params: IMeterReadsParams): Promise<IMeterReadingDetail> {
        const response = await axios.get(`${CONTROLLER}GetPreviousMeterRead`, { params: params });
        const result = JSON.parse((response as any).content) as IMeterReadingDetail;

        return result;
    }

    public async submitMeterRead(data: IMeterReadSubmitParams): Promise<IMeterReadSubmitResponse> {
        const response = await axios.post(`${CONTROLLER}SubmitMeterRead`, data);
        const result = JSON.parse((response as any).content) as IMeterReadSubmitResponse;

        return result;
    }

    public async submitMultipleMeterReads(data: IMultipleMeterReadSubmitParams): Promise<IMultipleMeterReadSubmitResponse> {
        const response = await axios.post(`${BULK_CONTROLLER}UploadMultipleMeterReads`, data);
        const result = JSON.parse((response as any).content) as IMultipleMeterReadSubmitResponse;

        return result;
    }

    public async submitAndSendMultipleMeterReads(data: IMultipleMeterReadSubmitAndSendParams): Promise<IMultipleMeterReadSubmitAndSendResponse> {
        const response = await axios.post(`${BULK_CONTROLLER}SendMultipleMeterReads`, data);
        const result = JSON.parse((response as any).content) as IMultipleMeterReadSubmitAndSendResponse;

        return result;
    }



}