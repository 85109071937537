import axios from '@/services/invoicesAxiosService';
import { IPowerGroupInvoiceSummary } from "@/interfaces/powerGroupInvoiceSummary";

const CONTROLLER = "/PowerGroupInvoices/";

export default class PowerGroupInvoicesService {
    public async getGroupInvoiceSummary(userId: string, groupAccountNumber: string): Promise<IPowerGroupInvoiceSummary[]> {
        const response = await axios.get(`${CONTROLLER}GetGroupInvoiceSummary`, { params: { userId: userId, groupAccountNumber: groupAccountNumber } });
        const result = JSON.parse((response as any).data.content) as Promise<IPowerGroupInvoiceSummary[]>;

        return result;
    }

    public async downloadGroupInvoice(userId: string, groupAccountNumber: string, groupInvoiceNumber: string) {
        const response = await axios.get(`${CONTROLLER}DownloadGroupInvoice`, { params: { userId: userId, groupAccountNumber: groupAccountNumber, groupInvoiceNumber: groupInvoiceNumber }, responseType: "blob" });

        return response;
    }

    public async downloadGroupSummary(userId: string, groupAccountNumber: string, groupInvoiceNumber: string) {
        const response = await axios.get(`${CONTROLLER}DownloadGroupSummary`, { params: { userId: userId, groupAccountNumber: groupAccountNumber, groupInvoiceNumber: groupInvoiceNumber }, responseType: "blob" });

        return response;
    }

    public async downloadGroupStatement(userId: string, groupAccountNumber: string, groupInvoiceNumber: string) {
        const response = await axios.get(`${CONTROLLER}DownloadGroupStatement`, { params: { userId: userId, groupAccountNumber: groupAccountNumber, groupInvoiceNumber: groupInvoiceNumber }, responseType: "blob" });

        return response;
    }

    public getFileName(contentDisposition: string): string {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
            return matches[1].replace(/['"]/g, '');
        }
        return "file.zip";
    }
}