import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import TermsService from '@/services/termsService';
import { ITermsOfUse } from '../../interfaces/termsOfUse';


@Module({ namespaced: true })
export default class Terms extends VuexModule {
    private termsOfUseService = new TermsService();

    // State
    private hasAcceptedTerms = false;
    private termsOfUseDto: ITermsOfUse | null = null;

    // Getters
    get getHasAcceptedTermsOfUse(): boolean {
        return this.hasAcceptedTerms;
    }

    get getTermsOfUseDto(): (ITermsOfUse | null) {
        return this.termsOfUseDto;
    }

    // Mutations
    @Mutation
    private setHasAcceptedTerms(hasAccepted: boolean) {
        this.hasAcceptedTerms = hasAccepted;
    }

    @Mutation
    private setTermsOfUseDto(termsOfUse: ITermsOfUse) {
        this.termsOfUseDto = termsOfUse;
    }

    @Mutation
    public clearState(): void {
        this.hasAcceptedTerms = false;
        this.termsOfUseDto = null;
    }

    // Actions
    @Action({ rawError: true })
    public async hasAcceptedTermsOfUse(userId: string): Promise<void> {
        const result = await this.termsOfUseService.hasUserAcceptedTerms(userId);
        this.context.commit('setHasAcceptedTerms', result);
    }

    // Actions
    @Action({ rawError: true })
    public async AcceptsTermsOfUse(data: ITermsOfUse): Promise<void> {
        const result = await this.termsOfUseService.AcceptsTerms(data);
        this.context.commit('setTermsOfUseDto', result);
    }
}