









































import { Component, Watch, Vue } from "vue-property-decorator";
import BasePage from "@/components/pages/BasePage.vue";
import WizardStepContentSelector from "@/components/wizard/contentmanager/WizardStepContentSelector.vue"
import BannerManager from "@/components/BannerManager.vue"
import { JourneyNode } from '@/utilities/JourneyNode';
import { namespace } from 'vuex-class';
const InvoicingWizard = namespace("InvoicingWizard");
const Auth = namespace("Auth");
@Component({
    components: {
        BasePage,
        BannerManager,
        WizardStepContentSelector
    },
})
export default class ContentManager extends Vue {
    private transitionName = 'fade';
    private isExactActive = false;
    private next_step_id = ["step-1", "step-2"];
    private processing = false;

    @Auth.Getter
    private canViewContentMenu!: boolean;

    @InvoicingWizard.Getter
    private getRootJourney!: JourneyNode | null;

    @InvoicingWizard.Action
    private resetJourneyNode!: () => Promise<void>;

    @InvoicingWizard.Action
    private initJourneyNode!: (option: number) => Promise<void>;

    @InvoicingWizard.Action
    private appendJourneyToJourneyNode!: (option: number) => Promise<void>;

    @Watch('$route.path', { immediate: true, deep: true })

    public async onSelectedContentOption(option: number) {
        await this.initJourneyNode(option);
    }

    public findJourney(option: number): boolean {
        let root = this.getRootJourney;
        if (root !== null) {
            while (root !== null) {
                if ((root as JourneyNode).Value == option) {
                    return true;
                }
                root = (root as JourneyNode).Next;
            }
        }
        return false;
    }

    updated() {
        this.isExactActive = typeof this.$refs.rv === 'undefined';
    }

    mounted() {
        if (!this.canViewContentMenu) {
            window.location.href = `${window.location.origin}/home`;
        }
        this.isExactActive = typeof this.$refs.rv === 'undefined';
    }
}
