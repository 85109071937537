import Vue from 'vue';
import moment from 'moment';

Vue.filter('toCurrency', function toCurrency(value) {

    if (value === null) {
        return "";
    }

    const shift = function (num: number, exponent: number) {
        const numArray = ("" + num).split("e");
        return +(numArray[0] + "e" + (numArray[1] ? (+numArray[1] + exponent) : exponent));
    };

    const numberWithCommas = function (value: string) {
        const parts = value.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }

    const precision = 2;

    const result = shift(Math.round(shift(value, +precision)), -precision);

    return numberWithCommas(result.toFixed(2));
});

Vue.filter('roundToDp', function roundToDp(value, decimalPlaces) {

    const shift = function (num: number, exponent: number) {
        const numArray = ("" + num).split("e");
        return +(numArray[0] + "e" + (numArray[1] ? (+numArray[1] + exponent) : exponent));
    };

    const precision = decimalPlaces;

    return shift(Math.round(shift(value, +precision)), -precision);
});

Vue.filter('p_per_Kwh', function p_per_Kwh(value) {
    return value + "p per kWh";
});

