import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import CostOfGasReportsService  from '@/services/costOfGasReportsService';
import { ICogBasket } from '../../interfaces/cogBasket';
import { ICogReport } from '../../interfaces/cogReport';

@Module({ namespaced: true })
export default class CostOfGasReports extends VuexModule {

    private costOfGasReportsService = new CostOfGasReportsService();

    private downloadFileData: { data: Blob | null, fileName: string | null } | null = null;

    private baskets: ICogBasket[] = [];

    private cogReports: ICogReport[] = [];

    get getDownloadFileData() {
        return this.downloadFileData;
    }

    get getBaskets(): ICogBasket[] {
        return this.baskets;
    }

    get getCogReports(): ICogReport[] {
        return this.cogReports;
    }

    @Mutation
    private setDownloadFile(data: any) {
        this.downloadFileData = data;
    }

    @Mutation
    private setBaskets(baskets: ICogBasket[]) {
        this.baskets = baskets;
    }

    @Mutation
    private setCogReports(cogReports: ICogReport[]) {
        this.cogReports = cogReports;
    }

    @Mutation
    public clearState() {

        this.cogReports = [];
        this.baskets = [];
        this.downloadFileData = null;
    }

    
    @Action({ rawError: true })
    public async fetchBaskets(params: { userId: string }): Promise<void> {

        const baskets = await this.costOfGasReportsService.getBaskets(params.userId);

        this.context.commit('setBaskets', baskets);
    }

    @Action({ rawError: true })
    public async fetchCogReports(params: { userId: string, basketId :number }): Promise<void> {

        const cogReports = await this.costOfGasReportsService.getCogReports(params.userId,params.basketId);

        this.context.commit('setCogReports', cogReports);
    }
  
    @Action({ rawError: true })
    public async downloadCogReport(params: {userId:string, filePath: string}): Promise<void> {
        const downloadFileData = await this.costOfGasReportsService.downloadCogReport(params.userId,params.filePath);

        this.context.commit('setDownloadFile', downloadFileData);
    }

    @Action({ rawError: true })
    public async downloadCogReports(params: { userId: string, basketId: number | null }): Promise<void> {

        const downloadFileData = await this.costOfGasReportsService.downloadCogReports(params.userId,params.basketId);

        this.context.commit('setDownloadFile', downloadFileData);
    }  
}