import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { JourneyNode } from '@/utilities/JourneyNode';

@Module({ namespaced: true })
export default class MeteringWizard extends VuexModule {
    private rootJourney: (JourneyNode | null) = null;
    private currentJourney: (JourneyNode | null) = null;

    //Getters
    get getRootJourney() {
        return this.rootJourney;
    }

    get getCurrentJourney() {
        return this.currentJourney;
    }

    //Mutations
    @Mutation
    public addJourneyNode(option: number) {
        (this.currentJourney as JourneyNode).Next = new JourneyNode(option);
    }

    @Mutation
    public initJourney(option: number) {
        this.rootJourney = new JourneyNode(option);
        this.currentJourney = this.rootJourney;
    }

    @Mutation
    public resetJourney() {
        this.rootJourney = null;
        this.currentJourney = null;
    }

    // Actions
    @Action({ rawError: true })
    public async appendJourneyToJourneyNode(option: number): Promise<void> {        
        this.context.commit('addJourneyNode', option);
    }

    @Action({ rawError: true })
    public async initJourneyNode(option: number): Promise<void> {
        this.context.commit('initJourney', option);
    }

    @Action({ rawError: true })
    public async resetJourneyNode(): Promise<void> {
        this.context.commit('resetJourney');
    }

}