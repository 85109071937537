




































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IUserAccount } from "@/interfaces/userAccount";
import { IPowerSite } from "@/interfaces/powerSite";
import ToastService from '@/services/toastService';

const Auth = namespace("Auth");
const PowerAccounts = namespace("PowerAccounts");

@Component
export default class PowerAccountSelector extends Vue {
    private disableTooltip = false;
    private observer: any = null;
    private searchString = '';        


    private enableTooltips(): void {
        this.disableTooltip = false;
    }

    private disableTooltips(): void {
        this.disableTooltip = true;
        this.$root.$emit('bv::hide::tooltip')
    }

    @Auth.Getter
    private getUserId!: string;

    @PowerAccounts.Getter
    private getUserAccountsCount!: number;

    @PowerAccounts.Getter
    private getUserAccounts!: Array<IUserAccount>;

    @PowerAccounts.Getter
    private getUserPowerSites!: Array<IPowerSite>;

    @PowerAccounts.Getter
    private getUserPowerSitesLinkedToMpan!: Array<IPowerSite>;

    @PowerAccounts.Getter
    private getUserPowerDistinctMpans!: Array<string>;

    @PowerAccounts.Getter
    private getSelectedGroupAccount!: string;

    @PowerAccounts.Getter
    private getSelectedAccount!: string;

    @PowerAccounts.Getter
    private getSelectedMpan!: string;

    @PowerAccounts.Getter
    private getIsMorePages!: boolean;

    @PowerAccounts.Getter
    private getIsMpanSelectionDisabled!: boolean;

    @PowerAccounts.Getter
    private getIsAccountSelectionDisabled!: boolean;

    @PowerAccounts.Mutation
    private setSelectedGroupAccount!: (groupAccountNumber: string) => void;

    @PowerAccounts.Mutation
    private setSelectedPowerSite!: (accountNumber: string) => void;

    @PowerAccounts.Mutation
    private setSelectedPowerMpan!: (Mpan: string) => void;

    @PowerAccounts.Mutation
    private setUserPowerSitesLinkedToMPAN!: (Mpan: string) => void;

    @PowerAccounts.Mutation
    private resetPageNumber!: () => void;

    @PowerAccounts.Mutation
    private nextPage!: () => number;

    @PowerAccounts.Action
    private fetchUserAccountsCount!: (userId: string) => Promise<void>;

    @PowerAccounts.Action
    private onMpanSelection!: (Mpan: string) => Promise<void>;

    @PowerAccounts.Action
    private fetchUserAccounts!: (params: { userId: string, groupAccountNumberQuery: string | null }) => Promise<void>;

    @PowerAccounts.Action
    private searchUserAccounts!: (params: { userId: string, groupAccountNumberQuery: string, appendResult: boolean }) => Promise<void>;

    @PowerAccounts.Action
    private fetchPowerSitesForGroupAccount!: (params: { userId: string, groupAccountNumber: string }) => Promise<void>;

    @PowerAccounts.Action
    private loadUserPowerSelections!: (userId: string) => void;

    private onSetSelectedGroupAccount(groupAccountNumber: string): void {
        this.setSelectedGroupAccount(groupAccountNumber);
        this.fetchPowerSitesForGroupAccount({ userId: this.getUserId, groupAccountNumber });
    }

    private onSetSelectedMpan(Mpan: string): void {
        this.onMpanSelection(Mpan);
    }

    get isFilterable(): boolean {
        return this.getUserAccountsCount <= 200 ?? false;
    }

    private async onOpen() {
        await this.$nextTick()

        if (this.$refs.load === undefined) return;

        this.observer.observe(this.$refs.load);
    }

    private onClose() {
        this.observer.disconnect();
    }

    private async onSearch(search: string, loading: (isLoading: boolean) => void) {
        if (this.isFilterable) {
            loading(false);
            return;
        }

        this.onClose();

        this.searchString = search;

        if (search.length === 0) {
            this.resetPageNumber();
            await this.fetchUserAccounts({ userId: this.getUserId, groupAccountNumberQuery: this.getSelectedGroupAccount });
            this.onOpen();
        }

        if (search.length > 2) {
            loading(true);
            this.search(search, loading);
        }
    }

    private async search(search: string, loading: (isLoading: boolean) => void) {
        try {
            this.resetPageNumber();
            await this.searchUserAccounts({ userId: this.getUserId, groupAccountNumberQuery: search, appendResult: false });
            this.onOpen();
        } catch (e) {
            console.error(e);
        } finally {
            loading(false);
        }
    }

    async mounted() {
        try {
            this.observer = new IntersectionObserver(async (entry) => {
                if ((entry[0] as any).isIntersecting) {
                    const ul = ((entry[0] as any).target).offsetParent;
                    const scrollTop = ((entry[0] as any).target).offsetParent.scrollTop;
                    this.nextPage();
                    await this.searchUserAccounts({ userId: this.getUserId, groupAccountNumberQuery: this.searchString, appendResult: true });
                    await this.$nextTick();
                    ul.scrollTop = scrollTop;
                }
            });

            await this.fetchUserAccountsCount(this.getUserId);
            this.loadUserPowerSelections(this.getUserId);
            await this.fetchUserAccounts({ userId: this.getUserId, groupAccountNumberQuery: this.isFilterable ? null : this.getSelectedGroupAccount });

        } catch (e) {
            console.error(e);
        }
    }

    private copyToClipboard(copyType: string): void {
        var TextToCopy = '';
        if (copyType === 'MPAN') {
            TextToCopy = this.getSelectedMpan;
        } else if (copyType === 'Group account') {
            TextToCopy = this.getSelectedGroupAccount;
        } else if (copyType === 'Account') {
            TextToCopy = this.getSelectedAccount;
        }

        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText(TextToCopy).then(() => {
                this.notifyTextCopied(copyType);
            })
            .catch(() => {
                alert("something went wrong");
            });
        } else {
            this.unsecuredCopyToClipboard(TextToCopy);
            this.notifyTextCopied(copyType);
        }
    }

    private notifyTextCopied(copyType: string) {
        const SuccessMessageDefaults = ToastService.SuccessMessageDefaults();
        SuccessMessageDefaults.autoHideDelay = 3000;
        SuccessMessageDefaults.variant = '#cfdf9a';
        SuccessMessageDefaults.bodyClass = 'copy-success-message';
        SuccessMessageDefaults.headerClass = 'copy-success-message';
        SuccessMessageDefaults.noCloseButton = true;
        SuccessMessageDefaults.title = '';
        this.$bvToast.toast(copyType + " copied", SuccessMessageDefaults);
    }

    private unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value = text; document.body.appendChild(textArea); textArea.focus(); textArea.select(); try { document.execCommand('copy') } catch (err) { console.error('Unable to copy to clipboard', err) } document.body.removeChild(textArea) };
}
