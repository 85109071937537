export class JourneyNode {
    public Value: (number|null);
    public Next: (JourneyNode | null);

    constructor(value?: number) {
        if (value) {
            this.Value = value;
            this.Next = null;
        } else {
            this.Value = null;
            this.Next = null;
        }
    }

}