import axios from '@/services/axiosService';
import newAxios from "@/services/invoicesAxiosService";
import { IFileDownload } from "@/interfaces/fileDownload";
import getFileName from "@/helpers/fileNameHelper"
import { IAccountBalanceDetails } from "@/interfaces/accountBalanceDetails";

const CONTROLLER = "/BALANCE/";

export default class BalanceService {    

    public async getAccountBalanceDetails(userId: string, accountNumber: string): Promise<IAccountBalanceDetails> {
        const response = await newAxios.get(`${CONTROLLER}GetBalanceDetails`, {
            params:
            {
                userId: userId,
                accountNumber: accountNumber
            }, responseType: "json"
        });

        const result = JSON.parse((response.data.content as any)) as IAccountBalanceDetails;

        return result;
    }

    public async DownloadDDMandate(userId: string, accountNumber: string): Promise<IFileDownload> {
        const response = await newAxios.get(`${CONTROLLER}DownloadMandate`, {
            params:
            {
                userId: userId,
                accountNumber: accountNumber
            }, responseType: "blob"
        });

        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Report could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }

    public async submitDownloadBalanceXlsx(userId: string, accountNumber: string): Promise<IFileDownload> {
        const response = await newAxios.get(`${CONTROLLER}DownloadXlsx`, {
            params:
            {
                userId: userId,
                accountNumber: accountNumber
            }, responseType: "blob"
        });

        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Report could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }

    public async submitDownloadBalancePDF(userId: string, accountNumber: string): Promise<IFileDownload> {
        const response = await newAxios.get(`${CONTROLLER}DownloadPDF`, {
            params:
            {
                userId: userId,
                accountNumber: accountNumber
            }, responseType: "blob"
        });

        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Report could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }
}