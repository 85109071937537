

export default class CookiesConsenseService {

    public cookies: any;

    constructor(cookies: any) {
        this.cookies = cookies;
    }

    public setConsentStatus(): void {        

        this.cookies.set("cookiesconsent_status", true, "365d");
    }
    public setAnalytics(): void {        

        this.cookies.set("cookiesAnalytics", true, "365d");
    }

    public removeAnalytics(): void {
        //PR .sefe-energy.co.uk from my.sefe-energy.co.uk
        //PP .gazpromuk.intra from myportalgazprompp.gazpromuk.intra
        const hostname = window.location.hostname.toString();
        const domainname = hostname.substring(hostname.indexOf("."));
        document.cookie = "_ga_VE9WS73ERP=; domain=" + domainname + "; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }

    public getConsentStatus(): boolean {
        const status = this.cookies.get("cookiesconsent_status");

        if (status != null || status != undefined) {
            return status as boolean;
        }
        return false;
    }

    public getAnalytics(): boolean {
        const status = this.cookies.get("cookiesAnalytics");

        if (status != null || status != undefined) {
            return status as boolean;
        }
        return false;
    }

    public getGA(): string {
        const status = this.cookies.get("_ga_VE9WS73ERP");

        if (status != null || status != undefined) {
            return status as string;
        }
        return "";
    }
}