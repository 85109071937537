
























































import BasePanel from '@/components/panels/BasePanel.vue';
import ClipLoader from '@/components/elements/ClipLoader.vue';
import ItemLabel from '@/components/elements/ItemLabel.vue';
import MainItemLabel from '@/components/elements/MainItemLabel.vue';
import CtaButton from '@/components/elements/CtaButton.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from "vuex-class";
import { IContractDetails } from '../../interfaces/contractDetails';

const Auth = namespace("Auth");
const GasAccounts = namespace("GasAccounts");
const Panel = namespace("Panel");

@Component({
    components: {
        BasePanel,
        ClipLoader,
        ItemLabel,
        MainItemLabel,
        CtaButton
    },
    filters: {
        formatStandingCharge(value: any) {

            if (value === null || value === "") {
                return "";
            }

            return "£ " + value + " per day";
        }
    }
})
export default class ContractDetailPanel extends Vue {

    private loading = true;
    public componentLabels = {
        title: "My contract details",
        contractTypeLabel: "Contract type",
        baseRateLabel: "Base rate",
        UnitRateLabel: "Unit rate",
        standingChargeLabel: "Standing charge",
        contractedAqLabel: "Contracted AQ",
        ctaButtonLabel: "Renew or new contract",
        ctaButtonHref: "https://www.sefe-energy.co.uk/get-a-quote/",
        contractStartDateLabel: "Contract start date",
        contractEndDateLabel: "Contract end date",
        rollingAqLabel: "Rolling AQ"
    };

    public unwatch: any;

    get ContractValueIfEndOfContract() {
        if (this.ContractDetails?.isEndOfContract) {
            return "Out of contract";
        } else {
            return this.ContractDetails?.contractDate;
        }
    }

    get ContractValueIfStartOfContract() {
            return this.ContractDetails?.contractStartDate;
    }

    @Auth.Getter
    private getUserId!: string;

    @GasAccounts.Getter
    private getSelectedSiteRefNumber!: string;

    @GasAccounts.Getter
    private getSelectedContractId!: number;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @GasAccounts.Getter
    private isLargeUser!: boolean;

    @Panel.Getter
    private ContractDetails!: IContractDetails | null;

    @Panel.Action
    private getContractDetails!: (userData: any) => Promise<any>;

    get isUnitRateUnavailable() {
        return this.ContractDetails?.unitRate == 'Unavailable';
    }

    get isStandingChargeUnavailable() {
        return this.ContractDetails?.standingCharge == 'Unavailable';
    }

    async created() {
        try {
            this.unwatch = this.$store.watch(
                (state, getters) => getters["GasAccounts/getSelectedContractId"],
                (newValue, oldValue) => {
                    if (newValue === null || newValue === oldValue) {
                        return;
                    }
                    this.loading = true;
                    const siteRefNumber = this.getSelectedSiteRefNumber;
                    const contractId = this.getSelectedContractId;
                    if (siteRefNumber !== null && contractId !== 0) {
                        this.getContractDetails({ UserId: this.getUserId, AccountNumber: this.getSelectedAccount, SiteRefNumber: siteRefNumber, ContractId: contractId, isLargeUser: this.isLargeUser }).then(() => {
                            this.loading = false;
                        }).catch(reason => {
                            this.loading = false;
                            console.error(reason);
                        });
                    }
                }
                , { immediate: true });
        } catch (e) {
            this.loading = false;
            console.error(e);
        }

    }

    beforeDestroy() {
        this.unwatch();
    }

}
