import axios from '@/services/axiosService';
import { IBanner } from "../interfaces/banner";

const CONTROLLER = "/bannermanager/";

export default class BannerContentService {

    public async add(userId: string, bannerText: string): Promise<boolean> {
        const response = await axios.post(`${CONTROLLER}Add`, { userId: userId, bannerContent: bannerText });
        return response.data;
    }

    public async getLatest(userId: string): Promise<IBanner> {
        const url = `${CONTROLLER}latest?userId=${userId}`;
        const response = await axios.get<IBanner>(url);
        return (response as any) as IBanner;
    }
}