import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import AmrSmartMeteringReportsService from '@/services/amrSmartMeteringReportsService';
import { IAmrSmartMeterReportDetails } from '@/interfaces/amrSmartMeterReport';
import { IAmrSmartMeterChartDetails } from '@/interfaces/amrSmartMeterChart';
import { IDeliveryAgreement } from '../../interfaces/deliveryAgreement';
import { IUserAccount } from "@/interfaces/userAccount";

@Module({ namespaced: true })
export default class AmrSmartMeteringReports extends VuexModule {

    private amrSmartMeteringReportsService = new AmrSmartMeteringReportsService();

    private downloadFileData: { data: Blob | null, fileName: string | null } | null = null;

    private amrChartData = Array<IAmrSmartMeterChartDetails>();
    private yearlyAmrChartData = Array<IAmrSmartMeterChartDetails>();

    private latestAmrChartDataDate: string | null = null;

    private contractStartDate: string | null = null;
    private contractEndDate: string | null = null;
    
    get getDownloadFileData() {
        return this.downloadFileData;
    }  

    get AmrChartData() {
        return this.amrChartData;
    }

    get LatestAmrChartDataDate() {
        return this.latestAmrChartDataDate;
    }

    get YearlyAmrChartData() {
        return this.yearlyAmrChartData;
    }

    get ContractStartDate() {
        return this.contractStartDate;
    }
    get ContractEndDate() {
        return this.contractEndDate;
    }

    @Mutation
    private setDownloadFile(data: any) {
        this.downloadFileData = data;
    }

    @Mutation
    private setAmrChartData(amrChartData: IAmrSmartMeterChartDetails[]): void {
        this.amrChartData = amrChartData;
    }

    @Mutation
    private setLatestAmrChartDataDate(latestAmrChartDataDate: string): void {
        this.latestAmrChartDataDate = latestAmrChartDataDate;
    }

    @Mutation
    private setYearlyAmrChartData(yearlyAmrChartData: IAmrSmartMeterChartDetails[]): void {
        this.yearlyAmrChartData = yearlyAmrChartData;
    }

    @Mutation
    private setContractStartDate(contractStartDate: string): void {
        this.contractStartDate = contractStartDate;
    }

    @Mutation
    private setContractEndDate(contractEndDate: string): void {
        this.contractEndDate = contractEndDate;
    }

    @Action({ rawError: true })
    public async downloadReport_gas(params: { userId: string, mprn: string, siteRefNum: string, accountNum: string, startDate: string, endDate: string }): Promise<void> {
        const downloadFileData = await this.amrSmartMeteringReportsService.downloadReport_gas(params.userId, params.mprn, params.siteRefNum, params.accountNum, params.startDate, params.endDate);

        this.context.commit('setDownloadFile', downloadFileData);
    }

    @Action({ rawError: true })
    public async downloadReport_power(params: { userId: string, mpan: string, account: string, startDate: string, endDate: string }): Promise<void> {
        const downloadFileData = await this.amrSmartMeteringReportsService.downloadReport_power(params.userId, params.mpan, params.account, params.startDate, params.endDate);

        this.context.commit('setDownloadFile', downloadFileData);
    }

    @Action({ rawError: true })
    public async downloadBulkReport_gas(params: { userId: string, accountNum: string, startDate: string, endDate: string, mprns: Array<IDeliveryAgreement> }): Promise<void> {
        const downloadFileData = await this.amrSmartMeteringReportsService.downloadBulkReport_gas(params.userId, params.accountNum, params.startDate, params.endDate, params.mprns);

        this.context.commit('setDownloadFile', downloadFileData);
    }

    @Action({ rawError: true })
    public async downloadBulkReport_power(params: { userId: string, account: string, startDate: string, endDate: string, mpans: Array<string> }): Promise<void> {
        const downloadFileData = await this.amrSmartMeteringReportsService.downloadBulkReport_power(params.userId, params.account, params.startDate, params.endDate);

        this.context.commit('setDownloadFile', downloadFileData);
    }

    @Action({ rawError: true })
    public async downloadBulkReportsAllAccounts_gas(params: { userId: string, startDate: string, endDate: string, userAccounts: Array<IUserAccount> }): Promise < void> {
        const downloadFileData = await this.amrSmartMeteringReportsService.downloadBulkReportsAllAccounts_gas(params.userId, params.startDate, params.endDate, params.userAccounts);

        this.context.commit('setDownloadFile', downloadFileData);
    }

    @Action({ rawError: true })
    public async getAmrChartData_gas(params: { userId: string, mprn: string, siteRefNum: string, accountNum: string }): Promise<void> {

        //Clear existing data
        this.context.commit('setAmrChartData', []);
        this.context.commit('setLatestAmrChartDataDate', null);
        this.context.commit('setContractStartDate', '');
        this.context.commit('setContractEndDate', '');
        this.context.commit('setYearlyAmrChartData', []);

        const amrChartData = await this.amrSmartMeteringReportsService.getAmrChartData_gas(params.userId, params.mprn, params.siteRefNum, params.accountNum);

        if (!amrChartData) {
            return;
        }

        const parsedData = <IAmrSmartMeterChartDetails[]>JSON.parse(amrChartData.toString()); 

        const latestAmrChartDataDate = parsedData.filter(x => x.y != null).slice(-1)[0].x;

        this.context.commit('setAmrChartData', amrChartData);     
        this.context.commit('setLatestAmrChartDataDate', latestAmrChartDataDate);
    }

    @Action({ rawError: true })
    public async getAmrChartData_power(params: { userId: string, mpan: string, account: string }): Promise<void> {

        //Clear existing data
        this.context.commit('setAmrChartData', []);
        this.context.commit('setLatestAmrChartDataDate', null);
        this.context.commit('setContractStartDate', '');
        this.context.commit('setContractEndDate', '');
        this.context.commit('setYearlyAmrChartData', []);

        const amrChartData = await this.amrSmartMeteringReportsService.getAmrChartData_power(params.userId, params.mpan, params.account);
        if (!amrChartData) {
            return;
        }

        const parsedData = <IAmrSmartMeterChartDetails[]>JSON.parse(amrChartData.toString());

        const latestAmrChartDataDate = parsedData.filter(x => x.y != null).slice(-1)[0].x;

        this.context.commit('setAmrChartData', amrChartData);
        this.context.commit('setLatestAmrChartDataDate', latestAmrChartDataDate);
    }

    @Action({ rawError: true })
    public async aggregateAmrChartData_gas(): Promise<void> {

        this.context.commit('setYearlyAmrChartData', []);

        if (!this.amrChartData) {
            return;
        }

        const yearlyAmrChartData: (IAmrSmartMeterChartDetails)[] = JSON.parse(this.AmrChartData.toString());

        const earliestDataDateAllowed = new Date();
        earliestDataDateAllowed.setFullYear(earliestDataDateAllowed.getFullYear() - 1);

        const latestDataDateAllowed = new Date();

        const firstDataTime = new Date(yearlyAmrChartData[0].x);
        const lastDataTime = new Date(yearlyAmrChartData[yearlyAmrChartData.length - 1].x);


        if (earliestDataDateAllowed < firstDataTime) {
            let months;
            months = (firstDataTime.getFullYear() - earliestDataDateAllowed.getFullYear()) * 12;
            months = months - earliestDataDateAllowed.getMonth() + firstDataTime.getMonth();

            const newEarlyDate = earliestDataDateAllowed;
            newEarlyDate.setMonth(newEarlyDate.getMonth() - 1);

            for (let i = 0; i < months; i++) {
                newEarlyDate.setMonth(newEarlyDate.getMonth() + 1);
                const datestring = newEarlyDate.getFullYear() + "-" + ("0" + (newEarlyDate.getMonth() + 1)).slice(-2) + "-01";
                yearlyAmrChartData.push({ x: datestring, y: null });
            }
        }

        if (latestDataDateAllowed > lastDataTime) {
            let months;
            months = (latestDataDateAllowed.getFullYear() - lastDataTime.getFullYear()) * 12;
            months = months - lastDataTime.getMonth() + latestDataDateAllowed.getMonth() - 1;

            const newLateDate = lastDataTime;
            newLateDate.setDate(1);

            for (let i = 0; i <= months; i++) {    
                newLateDate.setMonth(newLateDate.getMonth() + 1);
                const datestring = newLateDate.getFullYear() + "-" + ("0" + (newLateDate.getMonth()+1)).slice(-2) + "-01"
                yearlyAmrChartData.push({ x: datestring, y: null });
            }
        }

        const yearAggregatedData: (IAmrSmartMeterChartDetails)[] = Object.values(yearlyAmrChartData.reduce((aggregated, item) => {
            const x = item.x.substr(0, 7);
            const key = `${x}`;
            const y = (aggregated[key]?.y + item.y) || item.y;

            aggregated[key] = { x, y };
            return aggregated;
        }, {}));

        //sort extra days added
        yearAggregatedData.sort((a, b) => (a.x > b.x ? 1 : -1));

        this.context.commit('setYearlyAmrChartData', JSON.stringify(yearAggregatedData));
    }

    @Action({ rawError: true })
    public async getContractDates(params: { userId: string, mprn: string, siteRefNum: string, accountNum: string }): Promise<void> {

        this.context.commit('setContractStartDate', '');
        this.context.commit('setContractEndDate', '');

        const result = await this.amrSmartMeteringReportsService.getContractDates_gas(params.userId, params.mprn, params.siteRefNum, params.accountNum);

        const resultDates = Object.keys(result).map(key => result[key]);
        if (resultDates[1]) {
            const startDate = resultDates[1].substr(6, 4) + "-" + resultDates[1].substr(3, 2) + "-" + resultDates[1].substr(0, 2);
            this.context.commit('setContractStartDate', startDate);
            if (resultDates[0]) {
                const endDate = resultDates[0].substr(6, 4) + "-" + resultDates[0].substr(3, 2) + "-" + resultDates[0].substr(0, 2);
                if (startDate != endDate) { this.context.commit('setContractEndDate', endDate); }
            }
        }
        
    }

}