import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import InvoiceService from '@/services/invoicesService';
import { IInvoiceDetails } from '@/interfaces/invoiceDetails';

@Module({ namespaced: true })
export default class Invoices extends VuexModule {
    private invoicesService = new InvoiceService();
    private invoices: IInvoiceDetails[] = [];
    private downloadFileData: { data: Blob | null, fileName: string | null } | null = null;
    private data: Blob | null = null;

    get Invoices() {
        return this.invoices;
    }

    get getDownloadFileData() {
        return this.downloadFileData;
    }

    //Mutations
    @Mutation
    private setInvoices(invoices: any): void {
        this.invoices = invoices;
    }

    @Mutation
    private setDownloadFile(data: any) {
        this.downloadFileData = data;
    }

    @Mutation
    private setData(data: Blob) {
        this.data = data;
    }

    //Actions
    @Action({ rawError: true })
    private async getInvoices(params: { accountNum: string, siteRefNum: string, userId: string }): Promise<void> {
        const invoices = await this.invoicesService.fetchInvoices(params.accountNum, params.siteRefNum, params.userId);
        this.context.commit('setInvoices', invoices);
    }

    @Action({ rawError: true })
    private async getLatestMonthInvoicesForAccount(params: { accountNum: string, userId: string }): Promise<void> {
        const downloadFileData = await this.invoicesService.downloadLatestMonthInvoicesForAccount(params.accountNum, params.userId);
        this.context.commit('setDownloadFile', downloadFileData);
    }

    @Action({ rawError: true })
    private async getLatestYearInvoicesForAccount(params: { accountNum: string, userId: string }): Promise<void> {
        const downloadFileData = await this.invoicesService.downloadLatestYearInvoicesForAccount(params.accountNum, params.userId);
        this.context.commit('setDownloadFile', downloadFileData);
    }

    @Action({ rawError: true })
    private async getCustomRangeInvoicesForAccount(params: { accountNum: string, userId: string, fromMonth: string, toMonth: string }): Promise<void> {
        const downloadFileData = await this.invoicesService.downloadCustomRangeInvoicesForAccount(params.accountNum, params.userId, params.fromMonth, params.toMonth);
        this.context.commit('setDownloadFile', downloadFileData);
    }

    @Action({ rawError: true })
    private async invoiceDownload(params: { accountNum: string, invoiceNo: string, userId: string }): Promise<void> {
        const downloadFileData = await this.invoicesService.invoiceDownload(params.accountNum, params.invoiceNo, params.userId);
        this.context.commit('setDownloadFile', downloadFileData);
    }

    @Action({ rawError: true })
    private async getInvoicesDownloadForAllAccounts(params: { userId: string }) {
        const downloadFileData = await this.invoicesService.downloadInvoicesForAllAccounts(params.userId);
        this.context.commit('setDownloadFile', downloadFileData);
    }

    @Action({ rawError: true })
    private async getCustomRangeInvoicesForAllAccounts(params: { userId: string, fromDate: string, toDate: string }): Promise<void> {
        const downloadFileData = await this.invoicesService.downloadCustomRangeInvoicesForAllAccounts(params.userId, params.fromDate, params.toDate);
        this.context.commit('setDownloadFile', downloadFileData);
    }
}