




























/*Spacing or seperator between account selection and dashboard boxes*/

import { Component, Vue } from 'vue-property-decorator';
import ClipLoader from '@/components/elements/ClipLoader.vue';
import { namespace } from 'vuex-class';
import GasAccountSelector from '@/components/GasAccountSelector.vue';
import PowerAccountSelector from '@/components/PowerAccountSelector.vue';

const Auth = namespace("Auth");
const Products = namespace("Products");

@Component({
    components: {
        GasAccountSelector,
        ClipLoader,
        PowerAccountSelector
    }
})
export default class AccountSelector extends Vue {
    private disableTooltip = false;
    private loading = true;

    private enableTooltips(): void {
        this.disableTooltip = false;
    }

    private disableTooltips(): void {
        this.disableTooltip = true;
        this.$root.$emit('bv::hide::tooltip')
    }

    @Auth.Getter
    private getUserId!: string;

    @Products.Getter
    private getUserProducts!: Array<string>;

    @Products.Getter
    private getSelectedProduct!: string;

    @Products.Mutation
    private setSelectedProduct!: (product: string) => void;

    @Products.Getter
    private getIsProductSelectionDisabled!: boolean;

    @Products.Action
    private loadUserProducts!: (userId: string) => Promise<void>;

    @Products.Action
    private loadUserProductSelections!: (userId: string) => void;

    async mounted() {
        try {
            this.loadUserProductSelections(this.getUserId);
            await this.loadUserProducts(this.getUserId);
            this.loading = false;

        } catch (e) {
            console.error(e);
            this.loading = false;
        }
    }
}
