






















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DropdownHover extends Vue {
    @Prop() readonly componentText!: string;
    @Prop() readonly elementId!: string;

    public onOver(): void {
        (this.$refs.dropdown as Vue & { visible: boolean }).visible = true;
    }

    public onLeave(): void {
        (this.$refs.dropdown as Vue & { visible: boolean }).visible = false;
    }
}
