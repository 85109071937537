import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import PowerGroupInvoicesService from '@/services/powerGroupInvoicesService';

import { IPowerGroupInvoiceSummary } from "@/interfaces/powerGroupInvoiceSummary";

@Module({ namespaced: true })
export default class PowerGroupInvoices extends VuexModule {
    private powerGroupInvoicesService = new PowerGroupInvoicesService();

    // State
    private powerGroupInvoices = new Array<IPowerGroupInvoiceSummary>();
    private downloadFileData: { data: Blob | null, fileName: string | null } | null = null;

    // Getters
    get groupInvoices() {
        return this.powerGroupInvoices;
    }

    get getFileForDownload() {
        return this.downloadFileData;
    }

    // Mutations
    @Mutation
    private setPowerGroupInvoices(groupInvoices: Array<IPowerGroupInvoiceSummary>) {
        this.powerGroupInvoices = groupInvoices;
    }

    @Mutation
    private setFileForDownload(downloadFileData: any): void {
        this.downloadFileData = downloadFileData;
    }

    // Actions
    @Action({ rawError: true })
    public async getGroupInvoiceSummary(params: { userId: string, groupAccountNumber: string }): Promise<void> {
        const powerGroupInvoices = await this.powerGroupInvoicesService.getGroupInvoiceSummary(params.userId, params.groupAccountNumber);

        this.context.commit('setPowerGroupInvoices', powerGroupInvoices);
    }

    @Action({ rawError: true })
    public async downloadGroupInvoice(params: { userId: string, groupAccountNumber: string, groupInvoiceNumber: string }): Promise<void> {
        const groupInvoice = await this.powerGroupInvoicesService.downloadGroupInvoice(params.userId, params.groupAccountNumber, params.groupInvoiceNumber);

        const fileName = this.powerGroupInvoicesService.getFileName(groupInvoice.headers['content-disposition']);
        const model = { data: groupInvoice.data as Blob, fileName: fileName };

        this.context.commit('setFileForDownload', model);
    }

    @Action({ rawError: true })
    public async downloadGroupSummary(params: { userId: string, groupAccountNumber: string, groupInvoiceNumber: string }): Promise<void> {
        const groupInvoice = await this.powerGroupInvoicesService.downloadGroupSummary(params.userId, params.groupAccountNumber, params.groupInvoiceNumber);

        const fileName = this.powerGroupInvoicesService.getFileName(groupInvoice.headers['content-disposition']);
        const model = { data: groupInvoice.data as Blob, fileName: fileName };

        this.context.commit('setFileForDownload', model);
    }

    @Action({ rawError: true })
    public async downloadGroupStatement(params: { userId: string, groupAccountNumber: string, groupInvoiceNumber: string }): Promise<void> {
        const groupInvoice = await this.powerGroupInvoicesService.downloadGroupStatement(params.userId, params.groupAccountNumber, params.groupInvoiceNumber);

        const fileName = this.powerGroupInvoicesService.getFileName(groupInvoice.headers['content-disposition']);
        const model = { data: groupInvoice.data as Blob, fileName: fileName };

        this.context.commit('setFileForDownload', model);
    }
}