import axios from '@/services/axiosService';
import newAxios from "@/services/invoicesAxiosService";
import { IFileDownload } from "@/interfaces/fileDownload";
import getFileName from "@/helpers/fileNameHelper";
import { IAmrSmartMeteringConsumptionDetails } from "../interfaces/amrSmartMeteringConsumption";
import { IAmrSmartMeterChartDetails } from "@/interfaces/amrSmartMeterChart";
import { IDeliveryAgreement } from "@/interfaces/deliveryAgreement";
import { IAmrSmartMeterReportMprns } from "@/interfaces/amrSmartMeterReportMprn"
import { IUserAccount } from '../interfaces/userAccount';
import { IAmrSmartMeterReportBulkRequest } from "@/interfaces/amrSmartMeterReportMprnBulkRequest";


const CONTROLLER = "/AmrSmartMeteringReports/";

export default class AmrSmartMeteringReportsService {    
      
    public async downloadReport_gas(userId: string, mprn: string, siteRefNum: string, accountNum: string, startDate: string, endDate: string): Promise<IFileDownload> {
        const response = await newAxios.get(`${CONTROLLER}DownloadReport_Gas`, {
            params:
            {
                userId: userId,
                mprn: mprn,
                siteRefNum: siteRefNum,
                accountNum: accountNum,
                startDate: startDate,
                endDate: endDate
            }, responseType: "blob"
        });

        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Report could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }

    public async downloadReport_power(userId: string, mpan: string, account: string, startDate: string, endDate: string): Promise<IFileDownload> {
        const response = await newAxios.get(`${CONTROLLER}DownloadReport_Power`, {
            params:
            {
                userId: userId,
                mpan: mpan,
                accountNumber: account,
                startDate: startDate,
                endDate: endDate
            }, responseType: "blob"
        });

        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Report could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }

    public async getAmrChartData_gas(userId: string, mprn: string, siteRefNum: string, accountNum: string): Promise<IAmrSmartMeterChartDetails[]> {

        const response = await newAxios.get(`${CONTROLLER}AmrChart_Gas`, {
            params: {
                userId: userId,
                mprn: mprn,
                siteRefNum: siteRefNum,
                accountNum: accountNum
            }, responseType: "json"
        });

        const ChartData = response.data.content as IAmrSmartMeterChartDetails[];

        return ChartData;
    }

    public async getAmrChartData_power(userId: string, mpan: string, account: string): Promise<IAmrSmartMeterChartDetails[]> {

        const response = await newAxios.get(`${CONTROLLER}AmrChart_Power`, {
            params: {
                userId: userId,
                mpan: mpan,
                accountNumber: account
            }, responseType: "json"
        });

        const ChartData = response.data.content as IAmrSmartMeterChartDetails[];

        return ChartData;
    }

    public async downloadBulkReport_gas(userId: string, accountNum: string, startDate: string, endDate: string, mprns: Array<IDeliveryAgreement>): Promise<IFileDownload> {

        const mprnDtos: Array<IAmrSmartMeterReportMprns> = mprns.map(mprn => ({
            MeterPointReferenceNumber: mprn.MeterPointReferenceNumber,
            SiteReferenceNumber: mprn.SiteRefNum,
            StartDate: mprn.StartDate
        }));

        const payload = {
            UserId: userId,
            AccountNum: accountNum,
            StartDate: new Date(startDate),
            EndDate: new Date(endDate),
            MprnDetail: mprnDtos
        }
        const response = await newAxios.post(`${CONTROLLER}DownloadBulkReport_Gas`,
            payload,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                responseType: "blob"
            });

        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Report could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }

    public async downloadBulkReportsAllAccounts_gas(userId: string, startDate: string, endDate: string, userAccounts: Array<IUserAccount>): Promise<IFileDownload> {

        const userAccountDetails = new Array<IAmrSmartMeterReportBulkRequest>();

        userAccounts.forEach(account => {

            const mprnDtos: Array<IAmrSmartMeterReportMprns> = account.DeliveryAgreements.map(mprn => ({
                MeterPointReferenceNumber: mprn.MeterPointReferenceNumber,
                SiteReferenceNumber: mprn.SiteRefNum,
                StartDate: mprn.StartDate
            }));

            const accountDtos: IAmrSmartMeterReportBulkRequest = ({
                UserId: userId,
                AccountNum: account.AccountNumber,
                StartDate: new Date(startDate),
                EndDate: new Date(endDate),
                MprnDetail: mprnDtos
            });

            userAccountDetails.push(accountDtos);
        });

        
        const payload = { UserAccountDetails: userAccountDetails };
        const response = await newAxios.post(`${CONTROLLER}DownloadBulkReportsAllAccounts_Gas`,
            payload,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                responseType: "blob"
            });

        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Report could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }

    public async downloadBulkReport_power(userId: string, account: string, startDate: string, endDate: string): Promise<IFileDownload> {
        const response = await newAxios.get(`${CONTROLLER}DownloadReport_Power`, {
            params:
            {
                userId: userId,
                accountNumber: account,
                startDate: startDate,
                endDate: endDate
            }, responseType: "blob"
        });

        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Report could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }

    public async getContractDates_gas(userId: string, mprn: string, siteRefNum: string, accountNum: string): Promise<string> {

        const response = await newAxios.get(`${CONTROLLER}AmrChart_ContractDates`, {
            params: {
                userId: userId,
                mprn: mprn,
                siteRefNum: siteRefNum,
                accountNum: accountNum
            }, responseType: "json"
        });

        return response.data;
    }
}