import axios from '@/services/axiosService';
import { ITermsOfUse } from "@/interfaces/termsOfUse"

const CONTROLLER = "/termsofuse/";

export default class TermsService {

    public async hasUserAcceptedTerms(userId: string): Promise<boolean> {
        const response = await axios.get(`${CONTROLLER}accepted/${userId}`);
        const result = (response as any) as boolean;

        return result;
    }

    public async AcceptsTerms(data: ITermsOfUse): Promise<ITermsOfUse> {
        const response = await axios.post(`${CONTROLLER}accepts`, data);
        
        const result = (response as any) as ITermsOfUse;
        return result;
    }
}