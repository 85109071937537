import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import ConsumptionPowerReportsService from '@/services/consumptionPowerReportsService';

import { IPowerConsumptionReportsRequestParams } from '@/interfaces/powerConsumptionReportsRequestParams';
import { IPowerConsumptionReportsRequestMultiDateParams } from '@/interfaces/powerConsumptionReportsRequestMultiDateParams';

@Module({ namespaced: true })
export default class ConsumptionReportsPower extends VuexModule {

    private consumptionPowerReportsService = new ConsumptionPowerReportsService();

    private downloadFileData: { data: Blob | null, fileName: string | null } | null = null;    

    private GraphData: string | '' = '';

    private AdditionalGraphData: string | '' = '';

    get getDownloadFileData() {
        return this.downloadFileData;
    }
    
    get getGraphData() {
        return this.GraphData;
    }

    get getAdditionalGraphData() {
        return this.AdditionalGraphData;
    } 

    @Mutation
    private setGraphData(value: string) {
        this.GraphData = value;
    }

    @Mutation
    private setAdditionalGraphData(value: string) {
        this.AdditionalGraphData = value;
    }

    @Mutation
    private setDownloadFile(data: any) {
        this.downloadFileData = data;
    }   

    @Action({ rawError: true })
    public async submitGetGraphData(params: IPowerConsumptionReportsRequestParams): Promise<void> {
        const GraphData = await this.consumptionPowerReportsService.getGraphData(params);

        this.context.commit('setGraphData', GraphData);
    }

    @Action({ rawError: true })
    public async submitGetAdditionalGetGraphData(params: IPowerConsumptionReportsRequestParams): Promise<void> {
        const AdditionalGraphData = await this.consumptionPowerReportsService.getAdditionalGraphData(params);

        this.context.commit('setAdditionalGraphData', AdditionalGraphData);
    }

    @Action({ rawError: true })
    public async submitDownlaodDailyConsumptionReport(params: IPowerConsumptionReportsRequestParams): Promise<void> {
        const downloadFileData = await this.consumptionPowerReportsService.getDownloadDailyConsumptionReport(params);

        this.context.commit('setDownloadFile', downloadFileData);
    }

    @Action({ rawError: true })
    public async submitDownlaodHalfHourlyMultiDate(params: IPowerConsumptionReportsRequestMultiDateParams): Promise<void> {
        const downloadFileData = await this.consumptionPowerReportsService.getDownloadHalfHourlyCsvMultiDate(params);

        this.context.commit('setDownloadFile', downloadFileData);
    }
}