import Vue from 'vue';
import axios from 'axios';
import store from '../store'
import moment from 'moment';
import { IErrorDetail} from "@/interfaces/errorDetail";

const vm = new Vue({});
const newAxios = axios.create();

newAxios.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

newAxios.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        await errorHandler(error);
        return Promise.reject(error);
    }
);


const errorHandler = async (error: any) => {
    let errorMessage = '';
    let content = '';
    let errorDetail: IErrorDetail ;
    let data: any;
    switch (error.response.status) {
        case 404:
            return error.response.data.message !== "" ? error.response.data.message : "Service not reachable. Try again later.";
        case 401:
            if (document.location.pathname != "/") {

                const isLoggedIn = store.getters["Auth/isLoggedIn"];

                if (isLoggedIn) {
                    store.dispatch('Auth/signOut');
                }

                document.location.href = "/";
            }
            // no break - fall through to the 400 code to display the error.
        case 400:
            if (error.response.data instanceof Blob) {

                const errorData = JSON.parse(await error.response.data.text());

                errorMessage = errorData.message;
                content = errorData.content;

            } else {

                errorMessage = error?.response?.data?.message;
                content = error?.response?.data?.content;

            }

            errorDetail = GetErrorDetail(errorMessage);

            if (content == null) {
                return errorDetail.Message1;
            }

            data = JSON.parse(content);
            return format(data.LatestInvoiceDate == null ? errorDetail.Message1 : errorDetail.Message2, moment(data.StartRange).format('MMM YYYY'), moment(data.EndRange).format('MMM YYYY'), moment(data.LatestInvoiceDate).format('MMM YYYY'));
        default:
            return "Unknown error. Please contact the administrator.";
    }
};

const format = function (value, ...params): string {
    const args = params;
    return value.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != 'undefined'
            ? args[number]
            : match
            ;
    });
};

const GetErrorDetail = function (errorName: string): IErrorDetail {

    switch (errorName) {
        case "ReportNotGenerated":
            return { Message1: "Unfortunately this download is unavailable at this time, please try again later." } as IErrorDetail
    }

    return { Message1:errorName } as IErrorDetail

};

export default newAxios;