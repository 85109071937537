











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BasePanel extends Vue {

    @Prop({ type: String, default: "" }) readonly componentTitle!: string;
    @Prop({ type: Boolean, default: false }) readonly isUnavailable!: boolean;
    @Prop({ type: String, default: "justify-content-between" }) readonly innerBodyClass!: string;

    public defaultBodyClass = "d-flex flex-column pt-3";

    get bodyClass() {
        return this.defaultBodyClass + " " + this.innerBodyClass;
    }
}
