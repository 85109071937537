import axios from '@/services/axiosService';
import newAxios from "@/services/invoicesAxiosService";
import { IFileDownload } from "@/interfaces/fileDownload";
import getFileName from "@/helpers/fileNameHelper"
import { IConsumptionReportsRequestParams } from "@/interfaces/consumptionReportsRequestParams";


const CONTROLLER = "/EnergyCostReports/";

export default class EnergyCostReportsService {    

    public async generateEnergyCostReports(data: IConsumptionReportsRequestParams): Promise<IFileDownload> {
        const response = await newAxios.get(`${CONTROLLER}EnergyCostReport`, {
            params:
            {
                userId: data.userId,
                accountNumber: data.accountNumber,
                siteRef: data.siteRef,
                startDate: data.startDate,
                endDate: data.endDate,
                includeAllSites: data.includeAllSites
            }, responseType: "blob"
        });
      
        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Report could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }
}