import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import MeteringService from '@/services/meteringGasService';
import { IMeterReadsViewModel } from "@/interfaces/meterReadsViewModel";
import { IMeterDetail } from "@/interfaces/meterDetail";
import { IMeterReadingDetail } from "@/interfaces/meterReadingDetail";
import { IMeterReadsParams } from "@/interfaces/meterReadsParams";
import { IFileDownload } from "@/interfaces/fileDownload";
import { IMeterReadSubmitParams } from "@/interfaces/meterReadSubmitParams";
import { IMeterReadSubmitResponse } from "@/interfaces/meterReadSubmitResponse";
import { IMultipleMeterReadSubmitParams } from '../../interfaces/multipleMeterReadSubmitParams';
import { IMultipleMeterReadSubmitAndSendParams } from '../../interfaces/multipleMeterReadSubmitAndSendParams';
import { IMultipleMeterReadSubmitResponse } from '../../interfaces/multipleMeterReadSubmitResponse';
import { IMultipleMeterReadSubmitAndSendResponse } from '../../interfaces/multipleMeterReadSubmitAndSendResponse';

@Module({ namespaced: true })
export default class Metering extends VuexModule {
    private meteringService = new MeteringService();

    // State
    private selectedMeterSerialNumber: (string | null) = null;
    private meterPointAq: (string | null) = null;
    private showDmReads = false;
    private availableMeters = Array<IMeterDetail>();
    private totalMeterReadings = 0;
    private meterReads = Array<IMeterReadingDetail>();
    private dmMeterSelected = false;    
    private fileDownload = {} as IFileDownload;
    private multipleMeterReadsTemplateDownload = {} as IFileDownload;
    private completedMultipleMeterReadsTemplateDownload = {} as IFileDownload;
    private previousMeterRead: (IMeterReadingDetail | null) = { SerialNumber : '',Energy:0,Reading:'',SourceDescription:'',InvoiceDate:'',ReadDate:undefined,EarliestMeterReadingDate:undefined };                                
    private submitMeterReadResponse: (IMeterReadSubmitResponse | null) = null;
    private submitMultipleMeterReadsResponse: (IMultipleMeterReadSubmitResponse | null) = null;
    private submitAndSendMultipleMeterReadsResponse: (IMultipleMeterReadSubmitAndSendResponse | null) = null;

    // Getters
    get getSelectedMeterSerialNumber(): string | null {
        return this.selectedMeterSerialNumber;
    }

    get getMeterPointAq(): string | null {
        return this.meterPointAq;
    }

    get getShowDmReads(): boolean {
        return this.showDmReads;
    }

    get getAvailableMeters(): Array<IMeterDetail> {
        return this.availableMeters;
    }

    get getTotalMeterReadings(): number {
        return this.totalMeterReadings;
    }

    get getMeterReads(): Array<IMeterReadingDetail> {
        return this.meterReads;
    }

    get getDmMeterSelected(): boolean {
        return this.dmMeterSelected;
    }
    
    get fileToDownload(): IFileDownload {
        return this.fileDownload;
    }

    
    get multipleMeterReadsTemplateToDownload(): IFileDownload {
        return this.multipleMeterReadsTemplateDownload;
    }

    get completedMultipleMeterReadsTemplateToDownload(): IFileDownload {
        return this.completedMultipleMeterReadsTemplateDownload;
    }

    get getPreviousMeterRead(): IMeterReadingDetail | null {
        return this.previousMeterRead;
    }

    get getMeterReadSubmitResponse(): IMeterReadSubmitResponse | null {
        return this.submitMeterReadResponse;
    }

    get getMultipleMeterReadSubmitResponse(): IMultipleMeterReadSubmitResponse | null {
        return this.submitMultipleMeterReadsResponse;
    }

    get getMultipleMeterReadSubmitAndSendResponse(): IMultipleMeterReadSubmitAndSendResponse | null {
        return this.submitAndSendMultipleMeterReadsResponse;
    }


    // Mutations
    @Mutation
    private setMeterReads(meterReads: IMeterReadsViewModel) {
        this.selectedMeterSerialNumber = meterReads.SelectedMeterSerialNumber;
        this.meterPointAq = meterReads.MeterPointAq;
        this.showDmReads = meterReads.ShowDmReads;
        this.availableMeters = meterReads.AvailableMeters;
        this.totalMeterReadings = meterReads.TotalMeterReadings;
        this.meterReads = meterReads.Reads;
    }

    @Mutation
    private setFileForDownload(file: IFileDownload) {
        this.fileDownload = file;
    }

    @Mutation
    private setMultipleMeterReadsTemplateForDownload(file: IFileDownload) {
        this.multipleMeterReadsTemplateDownload = file;
    }

    @Mutation
    private setCompletedMultipleMeterReadsTemplateForDownload(file: IFileDownload) {
        this.completedMultipleMeterReadsTemplateDownload = file;
    }

    @Mutation
    public setSelectedMeterSerialNumber(meterSerialNumber: string | null) {
        this.selectedMeterSerialNumber = meterSerialNumber;
    }

    @Mutation
    public setDmMeterSelection(value: boolean) {
        this.dmMeterSelected = value;
    }

    @Mutation
    public clearState() {
        this.selectedMeterSerialNumber = '';
        this.meterPointAq = null;
        this.showDmReads = false;
        this.availableMeters = Array<IMeterDetail>();
        this.totalMeterReadings = 0;
        this.meterReads = Array<IMeterReadingDetail>();
        this.dmMeterSelected = false;
        this.fileDownload = {} as IFileDownload;
        this.previousMeterRead = { SerialNumber: '', Energy: 0, Reading: '', SourceDescription: '', InvoiceDate: '', ReadDate: undefined,EarliestMeterReadingDate: undefined };
    }

    @Mutation
    private setPreviousMeterRead(meterRead: IMeterReadingDetail) {
        this.previousMeterRead = meterRead;
    }

    @Mutation
    private setMeterReadSubmitResponse(meterReadSubmitResponse: IMeterReadSubmitResponse) {
        this.submitMeterReadResponse = meterReadSubmitResponse;
    }

    @Mutation
    private setMultipleMeterReadsSubmitResponse(multipleMeterReadsSubmitResponse: IMultipleMeterReadSubmitResponse) {
        this.submitMultipleMeterReadsResponse = multipleMeterReadsSubmitResponse;
    }

    @Mutation
    private setMultipleMeterReadsSubmitAndSendResponse(multipleMeterReadsSubmitAndSendResponse: IMultipleMeterReadSubmitAndSendResponse) {
        this.submitAndSendMultipleMeterReadsResponse = multipleMeterReadsSubmitAndSendResponse;
    }

    // Actions
    @Action({ rawError: true })
    public async fetchMeterReads(params: IMeterReadsParams): Promise<void> {
        const meterReads = await this.meteringService.getMeterReads(params);

        this.context.commit('setMeterReads', meterReads);
    }

    @Action({ rawError: true })
    public async downloadReport(params: IMeterReadsParams): Promise<void> {
        const report = await this.meteringService.downloadReport(params);

        this.context.commit('setFileForDownload', report);
    }

    @Action({ rawError: true })
    public async downloadMultipleMeterReadsTemplate(userId: string): Promise<void> {
        const template = await this.meteringService.downloadMultipleMeterReadsTemplate(userId); 

        this.context.commit('setMultipleMeterReadsTemplateForDownload', template);
    }

    @Action({ rawError: true })
    public async downloadCompletedMultipleMeterReadsTemplate(params: { userId: string, fileName: string }): Promise<void> {
        const template = await this.meteringService.downloadCompletedMultipleMeterReadsTemplate(params.userId, params.fileName);

        this.context.commit('setCompletedMultipleMeterReadsTemplateForDownload', template);
    }

    @Action({ rawError: true })
    public async fetchPreviousMeterRead(params: IMeterReadsParams): Promise<void> {
        const previousMeterRead = await this.meteringService.getPreviousMeterRead(params);

        this.context.commit('setPreviousMeterRead', previousMeterRead);
    }    

    @Action({ rawError: true })
    public async submitMeterRead(params: IMeterReadSubmitParams): Promise<void> {
        const submitMeterReadResponse = await this.meteringService.submitMeterRead(params);

        this.context.commit('setMeterReadSubmitResponse', submitMeterReadResponse);
    }

    @Action({ rawError: true })
    public async submitMultipleMeterReads(params: IMultipleMeterReadSubmitParams): Promise<void> {
        const submitMultipleMeterReadsResponse = (await this.meteringService.submitMultipleMeterReads(params)) as IMultipleMeterReadSubmitResponse;        

        this.context.commit('setMultipleMeterReadsSubmitResponse', submitMultipleMeterReadsResponse);
    }

    @Action({ rawError: true })
    public async submitAndSendMultipleMeterReads(params: IMultipleMeterReadSubmitAndSendParams): Promise<void> {
        const submitAndSendMultipleMeterReadsResponse = (await this.meteringService.submitAndSendMultipleMeterReads(params)) as IMultipleMeterReadSubmitAndSendResponse;

        this.context.commit('setMultipleMeterReadsSubmitAndSendResponse', submitAndSendMultipleMeterReadsResponse);
    }
}