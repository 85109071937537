





































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CookiePopup extends Vue {

    public showCookieSettings = false;
    private essentialCookies = true;
    private acceptedAnalytics = false;

    public handleCustomiseCookies(bvModalEvent: any): void {
        if (this.acceptedAnalytics === false) {
            this.$emit("declinedAnalytical");
        }
        else {
            this.$emit("acceptedAllCookies");
        }
    }

    public handleAcceptAllCookies(bvModalEvent: any): void {
        this.$emit("acceptedAllCookies");
    }
}
