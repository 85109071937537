import Vue from 'vue'
import VueBrowserUpdate from 'vue-browserupdate';

Vue.use(VueBrowserUpdate, {
    options: {
        // Your browser update options
        container: document.body,
    }
})

