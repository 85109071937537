








































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AlertBanner from '@/components/AlertBanner.vue';
import { IBanner } from '../interfaces/banner';

const Auth = namespace("Auth");
const BannerContent = namespace("BannerContent")

@Component({
    components: {
        AlertBanner
    }
})
export default class BannerManager extends Vue {

    private bannerText = "";
    private existingBannerContent!: IBanner;
    private savedSuccessfully = false;
    private sameContent = false;

    @Auth.Getter
    private getUserId!: string;

    @Auth.Getter
    private isLoggedIn!: boolean;

    @Auth.Getter
    private isGazpromAdmin!: boolean;

    @BannerContent.Getter
    private getLatestBannerContent!: IBanner;

    @BannerContent.Action
    private saveBannerContent!: (params: { userId: string, bannerText: string }) => Promise<void>;

    @BannerContent.Action
    private fetchLatestBannerContent!: (params: { userId: string }) => Promise<void>;

    async setLatestContent() {
        await this.fetchLatestBannerContent({ userId: this.getUserId });
        this.existingBannerContent = this.getLatestBannerContent;
    }

    async Save() {
        this.savedSuccessfully = false;
        this.sameContent = false;
        if (this.bannerText != this.existingBannerContent.bannerContent) {
            this.saveBannerContent({ userId: this.getUserId, bannerText: this.bannerText }).then(async () => {
                this.savedSuccessfully = true;
                await this.setLatestContent();
            });
        } else {
            this.sameContent = true;
        }
    }

    async mounted() {
        await this.setLatestContent();
        if (this.existingBannerContent != null) {
            this.bannerText = this.existingBannerContent.bannerContent
        }
    }
}
