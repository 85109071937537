import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import AdminService from '@/services/adminService';
import GasAccountsService from '@/services/gasAccountsService';
import { IUserType } from "@/interfaces/userType";
import { IAddUserRequestParams } from "@/interfaces/addUserRequestParams";
import { IUpdateUserRequestParams } from "@/interfaces/updateUserRequestParams";
import { IUserAccount } from "@/interfaces/userAccount";
import { IUsers } from "@/interfaces/users";
import { IUser } from "@/interfaces/user";
import PowerAccountsService from '@/services/powerAccountsService';
import { UserTypes } from '../../utilities/Enums/UserTypes';

@Module({ namespaced: true })
export default class Admin extends VuexModule {

    private adminService = new AdminService();
    private gasAccountsService = new GasAccountsService();
    private powerAccountsService = new PowerAccountsService();

    private manageUserTypes: IUserType[] | null = null;
    private userAccounts = Array<IUserAccount>();
    private userPowerAccounts = Array<IUserAccount>();
    private powerAccountsCount = 0;
    private page = 1;

    private users: IUsers | null = null;
    private userAddedEmailSent: boolean | null = null;
    private selectedUserIdForManage: string | null = null;

    get getSelectedUserIdForManage() {
        return this.selectedUserIdForManage;
    }

    get getSelectedUserForManage() : IUser | null {
        if (this.users != null && this.users.UserList != null) {
            const selectedUsers = this.users.UserList.filter(_ => _.Id == this.selectedUserIdForManage);
            if (selectedUsers.length > 0) {
                return selectedUsers[0];
            }
        }
        return null;
    }

   

    get getManageUserTypes() {
        return this.manageUserTypes;
    }

    get getUserAccounts(): Array<IUserAccount> {
        return this.userAccounts;
    }

    get getPowerUserAccounts(): Array<IUserAccount> {
        return this.userPowerAccounts;
    }

    get getPowerAccountsCount(): number {
        return this.powerAccountsCount;
    }

    get getUsers() {
        return this.users;
    }

    @Mutation
    private setSelectedUserIdForManage(data: string) {
        this.selectedUserIdForManage = data;
    }

    @Mutation
    private setUsers(data: any) {
        this.users = data;
    }

    @Mutation
    private setManageUserTypes(data: any) {
        this.manageUserTypes = data;
    }


    @Mutation
    private setUserAccounts(userAccounts: Array<IUserAccount>) {
        this.userAccounts = userAccounts;
        //this.userAccountsLoaded = true;
    }

    @Mutation
    private setPowerUserAccounts(userPowerAccounts: Array<IUserAccount>) {
        this.userPowerAccounts = userPowerAccounts;
        //this.userAccountsLoaded = true;
    }

    @Mutation
    private setPowerAccountsCount(powerAccountsCount: number) {
        this.powerAccountsCount = powerAccountsCount;
    }

    @Mutation
    private resetAccountsSearch(accountNumber: string) {
        this.userAccounts = Array<IUserAccount>();
    }

    @Action({ rawError: true })
    public async doesClaimExistForUser(params: { id: string, claim: string }): Promise<boolean> {
        if (this.users != null && this.users.UserList != null) {
            const selectedUsers = this.users.UserList.filter(_ => _.Id == params.id);
            if (selectedUsers.length > 0) {
                const user = selectedUsers[0];
                const matches = user.Claims.filter(_ => _.Type == params.claim)
                return matches.length > 0;
            }
        }
        return false;
    }

    @Action({ rawError: true })
    public async isEditingExternalTpiOrStandardUser(params: { id: string }): Promise<boolean> {
        if (this.users != null && this.users.UserList != null) {
            const selectedUsers = this.users.UserList.filter(_ => _.Id === params.id && (_.UserTypeId === UserTypes.Standard || _.UserTypeId === UserTypes.ExternalAdmin || _.UserTypeId === UserTypes.TPI));

            return selectedUsers.length > 0;
        }
        return false;
    }

    @Action({ rawError: true })
    public async saveSelectedUserIdForManageAction(params: { userId: string }): Promise<void> {

        this.context.commit('setSelectedUserIdForManage', params.userId);
    }

    @Action({ rawError: true })
    public async getManageUserTypesAction(params: { userId: string }): Promise<void> {
        const manageUserTypes = await this.adminService.getManageUserTypes(params.userId);

        this.context.commit('setManageUserTypes', manageUserTypes);
    }

    @Action({ rawError: true })
    public async addUser(addUserRequestParams: IAddUserRequestParams): Promise<boolean> {
        return (await this.adminService.addUser(addUserRequestParams));
    }

    @Action({ rawError: true })
    public async userExists(params: { userId: string, email: string }) {
        return (await this.adminService.userExists(params.userId, params.email));
    }

    @Action({ rawError: true })
    public async searchUserAccounts(params: { userId: string, query: string, appendResult: (boolean | null) }): Promise<void> {
        const pageSize = 200;
        const userAccountsData = await this.gasAccountsService.searchUserAccounts(params.userId,
            params.query,
            this.page,
            pageSize,
            //this.isLargeUser
            false);

        this.context.commit('setUserAccounts', userAccountsData.UserAccounts);
    }

    @Action({ rawError: true })
    public async fetchUserAccounts(params: { userId: string, accountNumberQuery: string | null }): Promise<void> {
        const pageSize = 200;

        const userAccountsData = await this.gasAccountsService.fetchUserAccounts(params.userId, pageSize, params.accountNumberQuery, //this.isLargeUser
            false);

        this.context.commit('setUserAccounts', userAccountsData.UserAccounts);
    }


    @Action({ rawError: true })
    public async fetchPowerUserAccounts(params: { userId: string, groupAccountNumberQuery: string | null }): Promise<void> {
        const pageSize = 200;
        const userAccountsData = await this.powerAccountsService.fetchUserAccounts(params.userId, pageSize, null//accountNumber
        );

        this.context.commit('setPowerUserAccounts', userAccountsData.UserAccounts);
        this.context.commit('setPowerAccountsCount', userAccountsData.TotalAccounts);
    }

    @Action({ rawError: true })
    public async searchPowerUserAccounts(params: { userId: string, groupAccountNumberQuery: string, appendResult: boolean }): Promise<void> {
        const pageSize = 200;
        const userAccountsData = await this.powerAccountsService.searchUserAccounts(params.userId, params.groupAccountNumberQuery, this.page, pageSize);
        this.context.commit('setPowerUserAccounts', userAccountsData.UserAccounts);
    }

    @Action({ rawError: true })
    public async getUsersAction(params: { userId: string, page: number, size: number, preload: boolean }): Promise<void> {
        const pageSize = 200;

        const users = await this.adminService.getUsers(params.userId, params.page, params.size, params.preload);

        this.context.commit('setUsers', users);
    }

    @Action({ rawError: true })
    public async searchUsersAction(params: { searchString: string, userId: string, page: number, size: number }): Promise<void> {
        const pageSize = 200;
        const users = await this.adminService.searchUsers(params.searchString, params.userId, params.page, params.size);

        this.context.commit('setUsers', users);
    }

    @Action({ rawError: true })
    public async filterUsersAction(params: { searchString: string }): Promise<void> {

        const users = this.users;
        const uppercaseSearch = params.searchString.toUpperCase();

        if (users != null) {
            users.UserList = this.users?.UserList.filter(_ =>
                _.EmailAddress.toUpperCase().includes(uppercaseSearch)
                || _.LastName.toUpperCase().includes(uppercaseSearch)
                || _.FirstName.toUpperCase().includes(uppercaseSearch)
            ) as Array<IUser>;
        }

        this.context.commit('setUsers', users);
    }

    @Action({ rawError: true })
    public async updateUser(addUserRequestParams: IUpdateUserRequestParams): Promise<boolean> {
        return (await this.adminService.updateUser(addUserRequestParams));
    }

    @Action({ rawError: true })
    public async removeAccountGas_Update(params: { userId: string, targetUserId: string, accountNumber: string }): Promise<boolean> {
        return (await this.adminService.removeAccountGas_Update(params.userId, params.targetUserId, params.accountNumber));
    }

    @Action({ rawError: true })
    public async updateAccountGas_Update(addUserRequestParams: IUpdateUserRequestParams): Promise<boolean> {
        return (await this.adminService.updateAccountGas_Update(addUserRequestParams));
    }

    @Action({ rawError: true })
    public async addAccountGas_Update(addUserRequestParams: IUpdateUserRequestParams): Promise<boolean> {
        return (await this.adminService.addAccountGas_Update(addUserRequestParams));
    }

    @Action({ rawError: true })
    public async removeAccountPower_Update(params: { userId: string, targetUserId: string, accountNumber: string }): Promise<boolean> {
        return (await this.adminService.removeAccountPower_Update(params.userId, params.targetUserId, params.accountNumber));
    }

    @Action({ rawError: true })
    public async updateAccountPower_Update(addUserRequestParams: IUpdateUserRequestParams): Promise<boolean> {
        return (await this.adminService.updateAccountPower_Update(addUserRequestParams));
    }

    @Action({ rawError: true })
    public async addBaskets_Update(addUserRequestParams: IUpdateUserRequestParams): Promise<boolean> {
        return (await this.adminService.addBaskets_Update(addUserRequestParams));
    }

    @Action({ rawError: true })
    public async removeBaskets_Update(params: { userId: string, targetUserId: string, basketId: number }): Promise<boolean> {
        return (await this.adminService.removeBaskets_Update(params.userId, params.targetUserId, params.basketId));
    }

    @Action({ rawError: true })
    public async addAccountPower_Update(addUserRequestParams: IUpdateUserRequestParams): Promise<boolean> {
        return (await this.adminService.addAccountPower_Update(addUserRequestParams));
    }

    @Action({ rawError: true })
    public async updateUserPreload(params: { userId: string, userIdEdit }): Promise<boolean> {
        return (await this.adminService.UpdateUserPreload(params.userId, params.userIdEdit));
    }

    @Action({ rawError: true })
    public async updateUserPreloadBaskets(params: { userId: string, requestingUserId }): Promise<boolean> {
        return (await this.adminService.UpdateUserPreloadBaskets(params.userId, params.requestingUserId));
    }
}