import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import DMConsumptionReportsService  from '@/services/dmConsumptionReportsService';
import { IDMConsumptionPrepopulatedDataResponse } from "@/interfaces/dmConsumptionPrepopulatedDataResponse";
import { IDMDownloadConsumptionGraphRequestParams } from "@/interfaces/dmDownloadConsumptionGraphRequestParams";
import { IDMConsumptionGraphDataResponse } from "@/interfaces/dmConsumptionGraphDataResponse";

@Module({ namespaced: true })
export default class DMConsumptionReports extends VuexModule {

    private dmConsumptionReportsService = new DMConsumptionReportsService();

    private downloadFileData: { data: Blob | null, fileName: string | null } | null = null;

    private IsActiveDM: boolean | false = false;

    private DMConsumptionPrepopulateDataResponse: IDMConsumptionPrepopulatedDataResponse | null = null;

    private DMConsumptionGraphDataResponse: IDMConsumptionGraphDataResponse | null = null;

    get getDownloadFileData() {
        return this.downloadFileData;
    }

    get getIsActiveDM() {
        return this.IsActiveDM;
    }

    get getPrepopulatedData() {
        return this.DMConsumptionPrepopulateDataResponse;
    }

    get getDMConsumptionGraphData() {
        return this.DMConsumptionGraphDataResponse;
    }

    @Mutation
    private setDownloadFile(data: any) {
        this.downloadFileData = data;
    }

    @Mutation
    private setDmMeterFlag(value: boolean) {
        this.IsActiveDM = value;
    }

    @Mutation
    private setConsumptionGraphData(data: IDMConsumptionGraphDataResponse) {
        this.DMConsumptionGraphDataResponse = data;
    }

    @Mutation
    private setPrepopulatedData(data: IDMConsumptionPrepopulatedDataResponse) {
        this.DMConsumptionPrepopulateDataResponse = data;
    }

    @Action({ rawError: true })
    public async submitIsActiveDM(params: { userId: string, accountNum: string, siteRef: string }): Promise<void> {
        const DmFlag = await this.dmConsumptionReportsService.getIsActiveDM(params.userId,params.accountNum,params.siteRef);

        this.context.commit('setDmMeterFlag', DmFlag);
    }

    @Action({ rawError: true })
    public async submitGetPrepopulatedValues(params: { userId: string, accountNum: string, siteRef: string }): Promise<void> {
        const result = await this.dmConsumptionReportsService.getPrepopulatedData(params.userId, params.accountNum, params.siteRef);

        this.context.commit('setPrepopulatedData', result);
    }

    @Action({ rawError: true })
    public async submitDMDownloadConsumptionGraph(params: IDMDownloadConsumptionGraphRequestParams): Promise<void> {
        const downloadFileData = await this.dmConsumptionReportsService.downloadDMConsumptionGraph(params);

        this.context.commit('setDownloadFile', downloadFileData);
    }

    @Action({ rawError: true })
    public async submitGetDMConsumptionGraph(params: IDMDownloadConsumptionGraphRequestParams): Promise<void> {
        const result = await this.dmConsumptionReportsService.getDMConsumptionGraphData(params);

        this.context.commit('setConsumptionGraphData', result);
    }

}