import axios from '@/services/axiosService';
import { IUserProductsViewModel } from "@/interfaces/userProductsViewModel"

const CONTROLLER = "/products/";

export default class AccountsService {
    public async getUserProducts(userId: string): Promise<IUserProductsViewModel> {
        const response = await axios.get(`${CONTROLLER}getUserProducts`, { params: { userId: userId } });
        const result = JSON.parse((response as any).content) as IUserProductsViewModel;

        return result;
    }
}