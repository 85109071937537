import axios from '@/services/axiosService';
import newAxios from "@/services/invoicesAxiosService";
import { IFileDownload } from "@/interfaces/fileDownload";
import getFileName from "@/helpers/fileNameHelper"
import { IDMConsumptionPrepopulatedDataResponse } from "@/interfaces/dmConsumptionPrepopulatedDataResponse";
import { IDMDownloadConsumptionGraphRequestParams } from "@/interfaces/dmDownloadConsumptionGraphRequestParams";
import { IDMConsumptionGraphDataResponse } from "@/interfaces/dmConsumptionGraphDataResponse";

const CONTROLLER = "/DMConsumptionReports/";

export default class DMConsumptionReportsService {    

    public async getIsActiveDM(userId: string, accountNum : string, siteRef: string): Promise<boolean> {
        let IsActiveDM = false;

        const response = await newAxios.get(`${CONTROLLER}GetAgreementIsActiveDM`, {
            params:
            {
                userId: userId,
                accountNum: accountNum,
                siteRef: siteRef
            }, responseType:"json"
        }).then(result => {
            IsActiveDM = JSON.parse(result.data);
        });

        return IsActiveDM;
    }

    public async getPrepopulatedData(userId: string, accountNum: string, siteRef: string): Promise<IDMConsumptionPrepopulatedDataResponse> {
        const response = await newAxios.get(`${CONTROLLER}getPrepopulatedData?siteRef=${siteRef}&accountNum=${accountNum}&userId=${userId}`);
        const result = JSON.parse(response.data.content) as IDMConsumptionPrepopulatedDataResponse;

        return result;
    }

    public async getDMConsumptionGraphData(data: IDMDownloadConsumptionGraphRequestParams): Promise<IDMConsumptionPrepopulatedDataResponse> {
        const response = await newAxios.get(`${CONTROLLER}DMConsumptionGraph`, {
            params:
            {
                userId: data.userId,
                accountNum: data.accountNumber,
                siteRef: data.siteRef,
                startDate: data.startDate,
                endDate: data.endDate,
                meterPointReferenceNumber: data.meterPointReferenceNumber
            }
        });

        const result = JSON.parse(response.data.content) as IDMConsumptionGraphDataResponse;

        return result;
    }

    public async downloadDMConsumptionGraph(data: IDMDownloadConsumptionGraphRequestParams): Promise<IFileDownload> {
        const response = await newAxios.get(`${CONTROLLER}DownloadDMConsumptionGraph`, {
            params:
            {
                userId:data.userId,
                accountNumber: data.accountNumber,
                siteRef: data.siteRef,
                startDate: data.startDate,
                endDate: data.endDate,
                meterPointReferenceNumber: data.meterPointReferenceNumber
            }, responseType: "blob"
        });

        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Report could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }
}