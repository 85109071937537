import { Vue } from 'vue-property-decorator';

export class GtagHelper extends Vue {
    public Event(eventName: string, userId: string): void {
        try {
            this.$gtag.event(eventName, { userId: userId })
        } catch (error) {
            console.log("Gtag Event error: " + eventName);
        }
    }
}