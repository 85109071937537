














import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ItemLabel extends Vue {
    @Prop({ type: String, required: true }) readonly itemLabel!: string;
    @Prop({ type: String, required: true }) readonly itemValue!: string;
    @Prop({ type: Number, default: 0 }) readonly indentationValue!: number;

    get style() {
        return {
            'text-indent ': (this.indentationValue + 'px')
        }
    }
}
