import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import BannerContentService from '@/services/bannerContentService';
import { IBanner } from "@/interfaces/banner";


@Module({ namespaced: true })
export default class BannerContent extends VuexModule {

    private bannerContentService = new BannerContentService();
    private bannerContent: (IBanner | null) = null;

    // Getters
    get getLatestBannerContent(): IBanner | null {
        return this.bannerContent;
    }

    @Action({ rawError: true })
    public async fetchLatestBannerContent(params: { userId: string }): Promise<void> {
        const banner = await this.bannerContentService.getLatest(params.userId);
        this.context.commit('setBannerContent', banner);
    }

    @Action({ rawError: true })
    public async saveBannerContent(params: { userId: string, bannerText: string }): Promise<void> {
        const banner = await this.bannerContentService.add(params.userId, params.bannerText);
        this.context.commit('setBannerContent', banner);
    }

    @Mutation
    public clearState(): void {
        this.bannerContent = null;
    }

    @Mutation
    private setBannerContent(content: IBanner) {
        this.bannerContent = content;
    }
}