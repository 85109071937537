














import { Component, Watch, Vue } from 'vue-property-decorator';
import CookiesConsenseService from '@/services/cookiesConsenseService';
import { namespace } from 'vuex-class';    
import { ILoginRequestResponse } from '@/interfaces/loginRequestResponse';
import moment from 'moment';
import router from '@/router'
import CookiePopup from '@/components/CookiePopup.vue';

import { VeeValidate_Custom } from '@/utilities/VeeValidate_Custom';



@Component({
    components: {
        CookiePopup
    },
})

export default class App extends Vue {
    public dismissed = false;
    private cookiesConsenseService = new CookiesConsenseService(this.$cookies);

    private transitionName = 'fade';
    public bannershow = false;

    private VeeValidateCustom = new VeeValidate_Custom();

    //@Watch('$route.path', { immediate: true, deep: true })
    //onUrlChange(to: string, from: string) {
    //    if (to == null || to == undefined) {
    //        this.transitionName = 'fade';
    //        return;
    //    }
    //    const toDepth = to.split('/').length
    //    const fromDepth = from?.split('/').length
    //    this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    //}     

    handleCookiesConsense(): void {
        this.dismissed = this.cookiesConsenseService.getConsentStatus();
    }

    showCookieModal(): void {
        this.$bvModal.show("CookiePopup")
    }

    public handleAcceptAllCookies(bvModalEvent: any): void {
        this.cookiesConsenseService.setConsentStatus();
        this.cookiesConsenseService.setAnalytics();
        this.dismissed = this.cookiesConsenseService.getConsentStatus();
    }

    public handleDeclineAnalyticCookies(bvModalEvent: any): void {
        this.cookiesConsenseService.setConsentStatus();
        this.dismissed = this.cookiesConsenseService.getConsentStatus();
    }

    created() {
        this.dismissed = this.cookiesConsenseService.getConsentStatus();


        this.loadMenuItems();
        
        //Vee Validate Custom rules
        this.$validator.extend('earlierToDate', {
            getMessage(field, val) {
                return 'Report date to is earlier than Report date from'
            },
            validate(value, [otherValue]: any) {
                var fromDate = moment(otherValue);
                var toDate = moment(value);

                if (fromDate.isAfter(toDate)) {
                    return false;
                }
                return true
            }
        }, {
            hasTarget: true
        });

        this.$validator.extend('futureToDate', {
            getMessage(field, val) {
                return 'This date is in the future, please select an earlier date'
            },
            validate(value) {

                var currentDate = moment();
                var toDate = moment(value);

                if (toDate.isAfter(currentDate)) {
                    return false;
                }
                return true
            }
        });

        this.$validator.extend('dateRangeTwelveMonth', {
            getMessage(field, val) {
                return 'Date range cannot be greater than 12 months'
            },
            validate(value, [otherValue]: any) {
                var fromDate = moment(otherValue);
                var toDate = moment(value);

                if (fromDate.add(1, 'years') < toDate) {
                    return false;
                }
                return true
            }
        }, {
            hasTarget: true
        });

        this.$validator.extend('dateRangeThirtyOneDays', {
            getMessage(field, val) {
                return 'Date range cannot be greater than 31 days'
            },
            validate(value, [otherValue]: any) {
                var fromDate = moment(otherValue);
                var toDate = moment(value);

                var dayDifference = toDate.diff(fromDate, 'days', true);
                if (dayDifference > 31) {
                    return false;
                }
                return true
            }
        }, {
            hasTarget: true
        });

        this.$validator.extend('isAnExcelFile', {
            getMessage(field, val) {
                return 'File is not recognised as a spreadsheet, please select another file'
            },
            validate(file : File) {
                
                if (file !== null) {

                    var foundFilename = file.name.match(/.([^.]+)$/);
                    if (foundFilename !== null) {

                        let ext = foundFilename[1].toLowerCase();
                        if (ext === 'xlsx' || ext === 'xls') {
                            return true;
                        }
                    }                        
                }

                return false;
            }
        });

        this.$validator.extend('confirmEmailMatch', {
            getMessage(field, val) {
                return 'Email addresses entered do not match'
            },
            validate(value, [otherValue]: any) {
                var email = otherValue;
                var confirmEmail = value;

                if (email !== confirmEmail) {
                    return false;
                }
                return true
            }
        }, {
            hasTarget: true
        });

        this.$validator.extend('emailDomain', {
            getMessage(field, val) {
                return 'Email address must end in @sefe.eu or @sefe-energy.com'
            },
            validate(value: string) {

                if (value.endsWith('@gazprom-energy.com') || value.endsWith('@sefe-energy.com') || value.endsWith('@sefe.eu')) {
                    return true;
                }
                return false
            }
        });

        this.$validator.extend('enterDateInFuture', {
            getMessage(field, val) {
                return 'This date is in the past. Please select a date in the future'
            },
            validate(value) {

                var currentDate = moment();
                var selectedDate = moment(value);

                if (selectedDate.isAfter(currentDate)) {
                    return true;
                }
                return false;
            }
        });

        //Vee Validate Custom Rules ---- END

        this.VeeValidateCustom.setupValidationMessages();

    }

    async mounted() {
        await this.handleCookiesConsense();

        if (!this.dismissed && this.$route.name != 'CookiePolicy') {
            this.$bvModal.show("modal-cookiepopup");
        }

        if (this.$route.name == 'Index') {
            this.bannershow = true;
        }
    }

    public loadMenuItems(): void {

        router.addRoute({
            path: '/invoicing',
            name: 'Invoicing',
            component: () => import('@/views/Invoicing.vue'),
            meta: { requiresAuth: true }
        });

        router.addRoute({
            path: '/balance',
            name: 'Balance',
            component: () => import('@/views/Balance.vue'),
            meta: { requiresAuth: true }
        });

        router.addRoute({
            path: '/metering',
            name: 'Metering',
            component: () => import('@/views/Metering.vue'),
            meta: { requiresAuth: true }
        });

        router.addRoute({
            path: '/billing',
            name: 'Billing',
            component: () => import('@/views/Billing.vue'),
            meta: { requiresAuth: true }
        });

        router.addRoute({
            path: '/consumption',
            name: 'Consumption',
            component: () => import('@/views/Consumption.vue'),
            meta: { requiresAuth: true }
        });

        router.addRoute({
            path: '/contract-information',
            name: 'ContractInformation',
            component: () => import('@/views/ContractInformation.vue'),
            meta: { requiresAuth: true }
        });

        router.addRoute({
            path: '/billing-details',
            name: 'BillingDetails',
            component: () => import('@/views/BillingDetails.vue'),
            meta: { requiresAuth: true }
        });

        router.addRoute({
            path: '/site-address',
            name: 'SiteAddress',
            component: () => import('@/views/SiteAddress.vue'),
            meta: { requiresAuth: true }
        });

        router.addRoute({
            path: '/emergency-contact',
            name: 'EmergencyContact',
            component: () => import('@/views/EmergencyContact.vue'),
            meta: { requiresAuth: true }
        });

        router.addRoute({
            path: '/help-and-support',
            name: 'HelpAndSupport',
            component: () => import('@/views/HelpAndSupport.vue'),
            meta: { requiresAuth: true }
        });

        router.addRoute({
            path: '/change-password',
            name: 'ChangePassword',
            component: () => import('@/views/UsernameAndPassword.vue'),
            meta: { requiresAuth: true }
        });

        router.addRoute({
            path: '*',
            name: 'notFound',
            component: () => import('@/views/NotFound.vue')
        });

        router.addRoute({
            path: '/manageaccount',
            name: 'ManageAccount',
            component: () => import('@/views/ManageAccount.vue'),
            meta: { requiresAuth: true }
        });
        router.addRoute({
            path: '/manageusers',
            name: 'ManageUsers',
            component: () => import('@/views/ManageUsers.vue'),
            meta: { requiresAuth: true }
        });
        router.addRoute({
            path: '/requestaccess',
            name: 'RequestAccess',
            component: () => import('@/views/RequestAccess.vue'),
            meta: { requiresAuth: true }
        })
    }

}
