import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import PanelService from '@/services/panelService';
import { IAccountBalanceInformation } from '../../interfaces/accountBalanceInformation';
import { IInvoice } from '../../interfaces/invoice';
import { IMeterReading } from '../../interfaces/meterReading';
import { IContractDetails } from '../../interfaces/contractDetails';

@Module({ namespaced: true })
export default class Panel extends VuexModule {
    private panelService = new PanelService();

    private accountsBalanceInformation: IAccountBalanceInformation | null = null;
    private accountDetails = null;
    private invoices: IInvoice | null = null;
    private meterReadings: IMeterReading | null = null;
    private contractDetails: IContractDetails | null = null;

    // Getters
    get AccountBalanceInformation(): IAccountBalanceInformation | null {
        return this.accountsBalanceInformation;
    }

    get AccountDetails(): any {
        return this.accountDetails;
    }

    get Invoices(): IInvoice | null {
        return this.invoices;
    }

    get MeterReadings(): IMeterReading | null {
        return this.meterReadings;
    }

    get ContractDetails(): IContractDetails | null {
        return this.contractDetails;
    }

    //Mutations
    @Mutation
    private setAccountBalanceInformation(balanceInfo: any) {
        
        this.accountsBalanceInformation = balanceInfo;

        if (this.accountsBalanceInformation != null && balanceInfo?.isPendingAgreement === undefined) {
            this.accountsBalanceInformation.isPendingAgreement = false;
        }
    }

    @Mutation
    private setAccountDetails(accountDetails: any) {
        this.accountDetails = accountDetails;
    }

    @Mutation
    private setInvoices(invoices: any) {
        this.invoices = invoices;
    }

    @Mutation
    private setMeterReadings(meterReadings: any) {
        this.meterReadings = meterReadings;
    }

    @Mutation
    private setContractDetails(contractDetails: any) {
        this.contractDetails = contractDetails;
    }

    @Mutation
    public clearState(): void {
        this.accountsBalanceInformation = null;
        this.accountDetails = null;
        this.invoices = null;
        this.meterReadings = null;
        this.contractDetails = null;
    }

    //Actions
    @Action({ rawError: true })
    public async getBalanceInformation(userData: any): Promise<any> {
        const result = await this.panelService.getBalanceInformation(userData);
        this.context.commit('setAccountBalanceInformation', result);
    }

    @Action({ rawError: true })
    public async getAccountDetails(userData: any): Promise<any> {
        const result = await this.panelService.getAccountDetails(userData);
        this.context.commit('setAccountDetails', result);
    }

    @Action({ rawError: true })
    public async getInvoices(userData: any): Promise<any> {
        const result = await this.panelService.getInvoices(userData);
        this.context.commit('setInvoices', result);
    }

    @Action({ rawError: true })
    public async getMeterReadings(userData: any): Promise<any> {
        const result = await this.panelService.getMeterReadings(userData);
        this.context.commit('setMeterReadings', result);
    }

    @Action({ rawError: true })
    public async getContractDetails(userData: any): Promise<any> {
        const result = await this.panelService.getContractDetails(userData);
        this.context.commit('setContractDetails', result);
    }
}