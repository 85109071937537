import Axios from "@/services/axiosService";

const CONTROLLER = "/panel/";

export default class PanelService {

    public async getBalanceInformation(data: any) {
        const axios = Axios.create();
        const response = await axios.get(`${CONTROLLER}BalanceInformation`, { params: { userId: data.UserId, accountNumber: data.AccountNumber, siteRefNum: data.SiteRefNumber, contractId: data.ContractId, isLargeUser: data.isLargeUser } });
        return response.data;
    }

    public async getAccountDetails(data: any) {
        const axios = Axios.create();
        const response = await axios.get(`${CONTROLLER}accountDetails`, { params: { userId: data.UserId, accountNumber: data.AccountNumber, siteRefNum: data.SiteRefNumber, contractId: data.ContractId } });
        return response.data;
    }

    public async getInvoices(data: any) {
        const axios = Axios.create();
        const response = await axios.get(`${CONTROLLER}invoices`, { params: { userId: data.UserId, accountNumber: data.AccountNumber, siteRefNum: data.SiteRefNumber, contractId: data.ContractId, isLargeUser: data.isLargeUser } });
        return response.data;
    }

    public async getMeterReadings(data: any) {
        const axios = Axios.create();
        const response = await axios.get(`${CONTROLLER}meterReadings`, { params: { userId: data.UserId, accountNumber: data.AccountNumber, siteRefNum: data.SiteRefNumber, contractId: data.ContractId, isLargeUser: data.isLargeUser } });
        return response.data;
    }

    public async getContractDetails(data: any) {
        const axios = Axios.create();
        const response = await axios.get(`${CONTROLLER}contractDetails`, { params: { userId: data.UserId, accountNumber: data.AccountNumber, siteRefNum: data.SiteRefNumber, contractId: data.ContractId, isLargeUser: data.isLargeUser } });
        
        return response.data;
    }

}