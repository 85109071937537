
import { BvToastOptions } from "bootstrap-vue";

export default class ToastService {

    public static autoHideDelay = 30000;
    public static ErrorMessageDefaults(): BvToastOptions {
     
        const toastErrorParams = {
            title: `Error Message`,
            variant: "danger",
            solid: true,
            autoHideDelay: this.autoHideDelay,
            appendToast: true
        };

        return toastErrorParams;
    }

    public static SuccessMessageDefaults(): BvToastOptions {

        const toastErrorParams = {
            title: `Success Message`,
            variant: "success",
            solid: true,
            autoHideDelay: this.autoHideDelay,
            appendToast: true
        };

        return toastErrorParams;
    }
 }