import axios from '@/services/axiosService';
import newAxios from "@/services/invoicesAxiosService";
import { IFileDownload } from "@/interfaces/fileDownload";
import getFileName from "@/helpers/fileNameHelper"
import { IBillingReportRequestParams } from "@/interfaces/billingReportRequestParams";
import { IBillingReportRequestResponse } from '@/interfaces/billingReportRequestResponse';

const CONTROLLER = "/Billing/";

export default class BillingService {    

    public async generateBillingReport(data: IBillingReportRequestParams): Promise<IFileDownload> {        
        const response = await newAxios.get(`${CONTROLLER}GenerateBillingReport`, {
            params:
            {
                userId: data.userId,
                accountNumber: data.accountNumber,
                previousReportId: data.previousReportId,
                invoiceStartDate: data.invoiceStartDate,
                invoiceEndDate: data.invoiceEndDate,
                selectedBillingReportOptionValues : data.selectedBillingReportOptionValues                
            }, responseType: "blob"
        });
      
        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Report could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }

    public async generateBillingReportForAllAccounts(data: IBillingReportRequestParams): Promise<IFileDownload> {
        const response = await newAxios.get(`${CONTROLLER}GenerateBillingReportsForAllAccounts`, {
            params:
            {
                userId: data.userId,
                invoiceStartDate: data.invoiceStartDate,
                invoiceEndDate: data.invoiceEndDate,
                selectedBillingReportOptionValues: data.selectedBillingReportOptionValues
            }, responseType: "blob"
        });

        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Report could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }

    public async getGeneratedBillingReports(userId: string, accountNumber: string): Promise<IBillingReportRequestResponse[]> {
        
        const response = await axios.get(`${CONTROLLER}GetGeneratedBillingReports?accountNumber=${accountNumber}&userId=${userId}`);
        const result = JSON.parse((response as any).content) as IBillingReportRequestResponse[];

        return result;
    }

    public async removeGeneratedBillingReport(userId: string, accountNumber:string, id: string): Promise<boolean> {

        const response = await axios.post(`${CONTROLLER}RemoveGeneratedBillingReport?accountNumber=${accountNumber}&id=${id}&userId=${userId}`);

        return true; // failures will automatically get picked up by the error handler
    }
}