import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import BalanceService  from '@/services/balanceService';
import { IFileDownload } from "@/interfaces/fileDownload";
import { IAccountBalanceDetails } from "@/interfaces/accountBalanceDetails";

@Module({ namespaced: true })
export default class Balance extends VuexModule {

    private balanceService = new BalanceService();

    private downloadFileData: { data: Blob | null, fileName: string | null } | null = null;

    private accountBalanceDetails: IAccountBalanceDetails | null = null;
    
    // Getters
    get getBalanceDetails(): IAccountBalanceDetails | null {
        return this.accountBalanceDetails;
    }

    get getDownloadBalanceFileData() {
        return this.downloadFileData;
    }

    @Mutation
    private setBalanceDetails(accountBalanceDetails: IAccountBalanceDetails) {
        this.accountBalanceDetails = accountBalanceDetails;
    }

    @Mutation
    private setDownloadFile(data: any) {
        this.downloadFileData = data;
    }

    @Action({ rawError: true })
    public async submitGetBalanceDetails(params: { userId: string, accountNumber: string }): Promise<void> {
        const BalanceDetails = await this.balanceService.getAccountBalanceDetails(params.userId, params.accountNumber);

        this.context.commit('setBalanceDetails', BalanceDetails);
    }

    @Action({ rawError: true })
    public async submitDownloadDDManadate(params: { userId: string, accountNumber: string }): Promise<void> {
        const downloadFileData = await this.balanceService.DownloadDDMandate(params.userId, params.accountNumber);

        this.context.commit('setDownloadFile', downloadFileData);
    }

    @Action({ rawError: true })
    public async submitDownloadBalanceXlsx(params: { userId: string, accountNumber: string }): Promise<void> {
        const downloadFileData = await this.balanceService.submitDownloadBalanceXlsx(params.userId, params.accountNumber);

        this.context.commit('setDownloadFile', downloadFileData);
    }

    @Action({ rawError: true })
    public async submitDownloadBalancePDF(params: { userId: string, accountNumber: string }): Promise<void> {
        const downloadFileData = await this.balanceService.submitDownloadBalancePDF(params.userId, params.accountNumber);

        this.context.commit('setDownloadFile', downloadFileData);
    }
}