import axios from "@/services/axiosService";
import { ILoginDetails } from "@/interfaces/loginDetails";
import { IResetPasswordDetails } from "@/interfaces/resetPasswordDetails";
import { IRegistrationDetails } from "@/interfaces/registrationDetails";
import { IRequestResponseMessage } from '@/interfaces/requestResponseMessage';
import { IChangePassword } from "../interfaces/changePassword";

const CONTROLLER = "/userAccess/";

export default class AuthService {
    public cookies: any;

    constructor(cookies: any) {
        this.cookies = cookies;
    }

    public login(loginDetails: ILoginDetails) {
        const email = loginDetails.Email;
        const password = loginDetails.Password;

        return axios.post<IRequestResponseMessage>(`${CONTROLLER}login`, { email, password })
            .then(response => {
                
                if ((response as any).content != null) {
                    this.cookies.set("account", (response as any).content, 0);
                }
                return response;
            }, (error) => {
                console.error(error);
                throw error;
            });
    }

    public registerAccount(registrationDetails: IRegistrationDetails) {
        return axios.post(`${CONTROLLER}register`, { ...registrationDetails })
            .then(response => {
                const result = JSON.parse((response as any).content).EmailRequestSent;
                return result;
            }, (error) => {
                console.error(error);
                return false;
            });
    }

    public registerTpiAccount(registrationDetails: IRegistrationDetails) {
        return axios.post(`${CONTROLLER}register/tpi`, { ...registrationDetails })
            .then(response => {
                const result = JSON.parse((response as any).content).EmailRequestSent;
                return result;
            }, (error) => {
                console.error(error);
                return false;
            });
    }

    public requestTpiAccess(requestDetails: IRegistrationDetails) {
        return axios.post<IRequestResponseMessage>("/userAccess/register/submitTPIRequest", requestDetails)
            .then(response => {
                if ((response as any).content != null) {
                    const tpiResponse = JSON.parse((response as any).content);
                    return tpiResponse;
                }
            }, (error) => {
                console.error(error);
                return false;
            });
    }

    public logout(userId: string) {
                
        const response = axios.get(`${CONTROLLER}get-old-portal-domain`, {
            params:
            {
                userId: userId
            }, responseType: "json"
        }).then(result => {

            const content = JSON.parse((result as any).content);

            const oldPortalDomain = content.OldPortalDomain;

            const rand = Math.floor((Math.random() * 1000000) + 1);

            const oldPortalLogoutUrl = oldPortalDomain + "/Account/Logout_Token?userId=" + userId + "&rand=" + rand;

            const logoutSpan = document.getElementById("logoutSpan");

            if (logoutSpan != null) {
                // this iframe code is needed to log the user out of old portal at the same time
                logoutSpan.innerHTML += '<iframe style="height:1px;width:1px;display:none" id="logoutIframe" src="' + oldPortalLogoutUrl + '">Logging out...</iframe>';
            }

            return axios.post(`${CONTROLLER}logout`)
                .then(response => {                        
                    this.cookies.remove("account");
                    this.cookies.remove("impersonatedAccount");
                    return response;
            });

        });
    }

    public sendPasswordResetLink(email: string) {
        return axios.get(`${CONTROLLER}send-reset-password-email`, { params: { email: email } })
            .then(response => {
                const result = JSON.parse((response as any).content).EmailRequestSent;
                return result;
            }, (error) => {
                console.error(error);
                return false;
            });
    }

    public getImpersonatedUserId(currentUserId: string,impersonatedUserEmail: string) {
        return axios.get(`${CONTROLLER}get-impersonated-user-id`, { params: { userId: currentUserId, impersonatedUserEmail: impersonatedUserEmail } })
            .then(response => {
                const result = JSON.parse((response as any).content).UserId;

                const identityUser = JSON.parse((response as any).content).IdentityUser;

                this.cookies.set("impersonatedAccount", identityUser);
                return result;
            }, (error) => {
                //console.error(error);
                return null;
            });
    }

    public getImpersonatedUserEmail(currentUserId: string, impersonatedUserId: string) {
        return axios.get(`${CONTROLLER}get-impersonated-user-email`, { params: { userId: currentUserId, impersonatedUserId: impersonatedUserId } })
            .then(response => {
                const result = JSON.parse((response as any).content).Email;

                const identityUser = JSON.parse((response as any).content).IdentityUser;

                this.cookies.set("impersonatedAccount", identityUser);
                return result;
            }, (error) => {
                console.error(error);
                return null;
            });
    }

    public async verifyResetToken(token: string) {
        return await axios.get(`${CONTROLLER}verify-password-token`, { params: { token: token } })
            .then(response => {
                return response;
            }, (error) => {
                console.error(error);
                throw error;
            });
    }   

    public async getPortalAuthTokenUrl(userId: string): Promise<string> {
        let authTokenUrl =  "";

        const response = await axios.get(`${CONTROLLER}get-auth-token-url`, {
            params:
            {
                userId: userId
            }, responseType: "json"
        }).then(result => {
            const content = JSON.parse((result as any).content);
                
            authTokenUrl = content.AuthTokenUrl;
        });

        return authTokenUrl;
    }



    public async changePassword(changePassword: IChangePassword) {
        return await axios.post(`${CONTROLLER}change-password`, { ...changePassword })
            .then(response => {
                return response;
            }, (error) => {
                console.error(error);
                throw error;
            });
    }

    public async resetPassword(resetPasswordDetails: IResetPasswordDetails) {
        const email = resetPasswordDetails.Email;
        const password = resetPasswordDetails.Password;
        const token = resetPasswordDetails.Token;
        return await axios.post(`${CONTROLLER}reset-password`, { email, password , token})
            .then(response => {
                const result = JSON.parse((response as any).content).ResettedPassword;
                return result;
            }, (error) => {
                console.error(error);
                return false;
            });
    }

}