import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import MeteringPowerService from '@/services/meteringPowerService';
import { IFileDownload } from "@/interfaces/fileDownload";
import { IPowerMeterReadData } from "@/interfaces/powerMeterReadData";

@Module({ namespaced: true })
export default class PowerMetering extends VuexModule {
    private meteringPowerService = new MeteringPowerService();

    // State
    private selectedMeterSerialNumber: (string | null) = null;
    private meterPointAq: (string | null) = null;
    private showDmReads = false;
    private availableMeters = Array<any>();
    private totalMeterReadings = 0;
    private meterReads = Array<IPowerMeterReadData>();
    private meterReadStatementAdd = Array<any>();
    private dmMeterSelected = false;
    private fileDownload = {} as IFileDownload;

    // Getters
    get getSelectedMeterSerialNumber(): string | null {
        return this.selectedMeterSerialNumber;
    }

    get getMeterPointAq(): string | null {
        return this.meterPointAq;
    }

    get getShowDmReads(): boolean {
        return this.showDmReads;
    }

    get getAvailableMeters(): Array<any> {
        return this.availableMeters;
    }

    get getTotalMeterReadings(): number {
        return this.totalMeterReadings;
    }

    get getMeterReads(): Array<IPowerMeterReadData> {
        return this.meterReads;
    }

    get getMeterReadStatementAdd(): Array<any> {
        return this.meterReadStatementAdd;
    }

    get getDmMeterSelected(): boolean {
        return this.dmMeterSelected;
    }

    get fileToDownload(): IFileDownload {
        return this.fileDownload;
    }

    // Mutations
    @Mutation
    private setMeterReads(meterReads: any) {
        if (meterReads.length > 0) {
            this.selectedMeterSerialNumber = meterReads[0].MeterSerialNumber;
        }
        else {
            this.selectedMeterSerialNumber = null;
        }
        this.totalMeterReadings = meterReads.length;
        this.meterReads = meterReads;
    }

    @Mutation
    private setFileForDownload(file: IFileDownload) {
        this.fileDownload = file;
    }

    @Mutation
    public setSelectedMeterSerialNumber(meterSerialNumber: string | null) {
        this.selectedMeterSerialNumber = meterSerialNumber;
    }

    @Mutation
    public setDmMeterSelection(value: boolean) {
        this.dmMeterSelected = value;
    }

    @Mutation
    public setMeterReadStatementAdd(meterReadStatementAdd: any) {
        this.meterReadStatementAdd = meterReadStatementAdd;
    }

    @Mutation
    public clearState() {
        this.selectedMeterSerialNumber = null;
        this.meterPointAq = null;
        this.showDmReads = false;
        this.availableMeters = Array<any>();
        this.totalMeterReadings = 0;
        this.meterReads = Array<any>();
        this.dmMeterSelected = false;
        this.fileDownload = {} as IFileDownload;
    }

    // Actions
    @Action({ rawError: true })
    public async fetchMeterReads(params: { userId: string, accountNum: string, mpan: string }): Promise<void> {
        const meterReads = await this.meteringPowerService.getPowerMeterReads(params);
        this.context.commit('setMeterReads', meterReads);
    }

    @Action({ rawError: true })
    public async fetchMeterReadStamentsAdd(params: {userId: string, mpan: string, accountNum:string, meterid: string }): Promise<void> {
        const meterReadStatementAdd = await this.meteringPowerService.getPowerMeterReadStamentsAdd(params);
        this.context.commit('setMeterReadStatementAdd', meterReadStatementAdd);
    }

    @Action({ rawError: true })
    public async addPowerMeterRead(params: { userId: string, mpan: string, accountNum:string, prevMeterReadDate: string, currentMeterReadDate: string }): Promise<boolean> {
        const model = { UserId: params.userId, Mpan: params.mpan, AccountNum: params.accountNum,  PreviousMeterReadDate: params.prevMeterReadDate, CurrentMeterReadDate: params.currentMeterReadDate, PowerMeterReadList: JSON.stringify(this.meterReads) };
        console.log("Model ",model);
        const meterReadStatementAddResult = await this.meteringPowerService.addPowerMeterRead(model);
        console.log(meterReadStatementAddResult);
        return meterReadStatementAddResult;
    }

    @Action({ rawError: true })
    public async updateCurrentMeterReads(meterReadCurrentMeterReadArray: any): Promise<void>  {
        this.context.commit('setCurrentMeterRead', meterReadCurrentMeterReadArray);
    }

    @Action({ rawError: true })
    public async sendCurrentMeterReadsToServer(params: { userId: string, mpan: string, accountNum: string, groupAccountNum: string, currentMeterReadDate: string, currentMeterReadArray: any }): Promise<boolean> {
        const model = { UserId: params.userId, Mpan: params.mpan, AccountNum: params.accountNum, GroupAccountNum: params.groupAccountNum, CurrentMeterReadDate: params.currentMeterReadDate, PowerMeterReadList: JSON.stringify(params.currentMeterReadArray) };
        const result = await this.meteringPowerService.addPowerMeterRead(model);
        return result;
    }
}