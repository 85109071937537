import Vue from 'vue';
import axios from 'axios';
import store from '../store'
import NProgress from 'nprogress'

const vm = new Vue({});

axios.interceptors.request.use(
  config => {
    NProgress.start();
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
        NProgress.done();
    return response.data;
  },
    error => {      
      vm.$bvToast.toast(errorHandler(error), {
      title: `Error Message`,
      variant: "danger",
      solid: true,
      autoHideDelay: 30000,
      appendToast: true
    });
    NProgress.done();
    return Promise.reject(error);
  }
);


const errorHandler = (error: any) => {
  switch (error.response.status) {
    case 404:
          return error.response.data.message !== "" ? error.response.data.message : "Service not reachable. Try again later.";
      case 401:
          if (document.location.pathname != "/") {

              const isLoggedIn = store.getters["Auth/isLoggedIn"];

              if (isLoggedIn) {
                  Vue.$cookies.remove("account");
                  Vue.$cookies.remove("impersonatedAccount");
                  store.dispatch('Auth/signOut');
                  document.location.href = "/";
              }
          }
          return error.response.data.message !== "" ? error.response.data.message : "Your session has expired.";
    case 400:  
        return error.response.data.message;
    default:
        return "Unknown error. Please contact the administrator.";
  }
};

export default axios;