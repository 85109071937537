

































import BasePanel from '@/components/panels/BasePanel.vue';
import ClipLoader from '@/components/elements/ClipLoader.vue';
import ItemLabel from '@/components/elements/ItemLabel.vue';
import MainItemLabel from '@/components/elements/MainItemLabel.vue';
import CtaButton from '@/components/elements/CtaButton.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from "vuex-class";
import { IMeterReading } from '../../interfaces/meterReading';

const Auth = namespace("Auth");
const GasAccounts = namespace("GasAccounts");
const Panel = namespace("Panel");
const FeatureToggle = namespace("FeatureToggle");

@Component({
    components: {
        BasePanel,
        ClipLoader,
        ItemLabel,
        MainItemLabel,
        CtaButton
    }
})
export default class LatestGasReadPanel extends Vue {

    private loading = true;
    public componentLabels = {
        title: "My latest gas reading",
        readingLabel: "Meter reading",
        DueDateLabel: "Latest reading on",
        ctaButtonLabel: "Submit a meter reading",

    };

    public unwatch: any;

    @Auth.Getter
    private getUserId!: string;

    @GasAccounts.Getter
    private getSelectedSiteRefNumber!: string;

    @GasAccounts.Getter
    private getSelectedContractId!: number;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @FeatureToggle.Getter
    private getInvoiceAndMeterReadPanelFeatureToggleStatus!: boolean;

    @GasAccounts.Getter
    private isLargeUser!: boolean;

    @Panel.Getter
    private MeterReadings!: IMeterReading | null;

    @Panel.Action
    private getMeterReadings!: (userData: any) => Promise<any>;

    @FeatureToggle.Action
    private getInvoiceAndMeterReadPanelFeatureToggleStatusAction!: (userId: any) => Promise<any>;

    async created() {
        try {
            this.unwatch = this.$store.watch(
                (state, getters) => getters["GasAccounts/getSelectedDeliveryAgreementNumber"],
                (newValue, oldValue) => {
                    if (newValue === null || newValue === oldValue) {
                        return;
                    }
                    this.loading = true;

                    const siteRefNumber = this.getSelectedSiteRefNumber;
                    const contractId = this.getSelectedContractId;

                    this.getInvoiceAndMeterReadPanelFeatureToggleStatusAction(this.getUserId).then(x => {

                        if (this.getInvoiceAndMeterReadPanelFeatureToggleStatus && siteRefNumber !== null && contractId !== 0) {
                            this.getMeterReadings({ UserId: this.getUserId, AccountNumber: this.getSelectedAccount, SiteRefNumber: siteRefNumber, ContractId: contractId, isLargeUser: this.isLargeUser }).then(() => {
                                this.loading = false;
                            }).catch(reason => {
                                this.loading = false;
                                console.error(reason);
                            });
                        } else {
                            this.loading = false;
                        }
                    });
                }
                , { immediate: true });
        } catch (e) {
            this.loading = false;
            console.error(e);
        }
    }

    beforeDestroy() {
        this.unwatch();
    }
}
