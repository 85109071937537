import Vue from 'vue';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import PowerAccountsService from '@/services/powerAccountsService';

import { IUserAccount } from "@/interfaces/userAccount";
import { IPowerSite } from "@/interfaces/powerSite";
import { IUserPowerSelections } from "@/interfaces/userPowerSelections";
import { MpanTypes } from '@/interfaces/mpanTypes';

@Module({ namespaced: true })
export default class PowerAccounts extends VuexModule {
    private powerAccountsService = new PowerAccountsService();
    private cookieKey = "userPowerSelections";

    // State
    private page = 1;
    private pageSize = 200;
    private userAccountsCount = -1;
    private accountsCountForCurrentSearch = -1;
    private userAccounts = Array<IUserAccount>();
    private userPowerSites = Array<IPowerSite>();
    private userPowerDistinctMpans = Array<string>();
    private userPowerSitesLinkedToMpan = Array<IPowerSite>();

    private isMpanSelectionDisabled = false;
    private isGroupAccountSelectionDisabled = false;
    private isAccountSelectionDisabled = false;

    private userPowerSelections = {
        UserId: null,
        SelectedGroupAccount: null,
        SelectedAccount: null,
        SelectedMpan: null,
        SelectedMeterType: null
    } as IUserPowerSelections;

    //Getters
    get getIsMorePages(): boolean {
        return (this.page * this.pageSize) < this.accountsCountForCurrentSearch;
    }

    get getUserAccountsCount(): number {
        return this.userAccountsCount;
    }

    get getPowerUserAccountsCount(): number {
        return this.userAccountsCount;
    }

    get getUserAccounts(): Array<IUserAccount> {
        return this.userAccounts;
    }

    get getUserPowerSites(): Array<IPowerSite> {
        return this.userPowerSites;
    }

    get getUserPowerSitesLinkedToMpan(): Array<IPowerSite> {
        return this.userPowerSitesLinkedToMpan;
    }

    get getUserPowerDistinctMpans(): Array<string> {
        return this.userPowerDistinctMpans;
    }

    get getSelectedGroupAccount(): string | null {
        return this.userPowerSelections.SelectedGroupAccount;
    }

    get getSelectedAccount(): string | null {
        return this.userPowerSelections.SelectedAccount;
    }

    // Is a duplicate of the above getter, needed because gasAccounts also has a method called getSelectedAccount and this causes a name conflict if you have both modules on a component
    get getSelectedAccount_Power(): string | null {
        return this.getSelectedAccount;
    }

    get getSelectedMpan(): string | null {
        return this.userPowerSelections.SelectedMpan;
    }

    get getSelectedMeterType(): string | null {
        return this.userPowerSelections.SelectedMeterType;
    }

    get isHalfHourly(): boolean {
        if (this.userPowerSelections.SelectedMeterType!= null)
            return MpanTypes.HalfHourlyMeterTypes().includes(this.userPowerSelections.SelectedMeterType as string);
        return false;
    }

    get getIsMpanSelectionDisabled(): boolean {
        return this.isMpanSelectionDisabled;
    }

    get getIsAccountSelectionDisabled(): boolean {
        return this.isAccountSelectionDisabled;
    }

    get getIsGroupAccountSelectionDisabled(): boolean {
        return this.isGroupAccountSelectionDisabled;
    }

    // Mutations
    @Mutation
    private resetPageNumber() {
        this.page = 1;
    }

    @Mutation
    private nextPage() {
        if ((this.page * this.pageSize) < this.accountsCountForCurrentSearch)
            this.page += 1;
    }

    @Mutation
    private setUserAccountsCount(accountsCount: number) {
        this.userAccountsCount = accountsCount;
    }

    @Mutation
    private setAccountsCountForCurrentSearch(accountsCount: number) {
        this.accountsCountForCurrentSearch = accountsCount;
    }

    @Mutation
    private setUserAccounts(userAccounts: Array<IUserAccount>) {
        this.userAccounts = userAccounts;
    }

    @Mutation
    private setUserAccounts_Append(returnedAccounts: Array<IUserAccount>) {
        
        for (let i = 0; i < returnedAccounts.length; i++) {

            const match = this.userAccounts.filter(x => x.AccountNumber === returnedAccounts[i].AccountNumber);

            if (match) {
                returnedAccounts.splice(i, 1);
                i--;
            }
        }


        this.userAccounts = [...this.userAccounts, ...returnedAccounts];
    }

    @Mutation
    private setUserPowerSites(powerSites: Array<IPowerSite>) {
        this.userPowerSites = powerSites;
    }

    @Mutation
    private setUserPowerMpans(mpans: Array<string>) {
        this.userPowerDistinctMpans = mpans;
    }

    @Mutation
    private loadUserPowerSelectionsFromCookie(userId: string) {
        const userSelections = Vue.$cookies.get(this.cookieKey) as IUserPowerSelections;
        const userIdInCookies = userSelections?.UserId;

        if (userId === userIdInCookies) {
            this.userPowerSelections = userSelections;
        }

        this.userPowerSelections.UserId = userId;
        Vue.$cookies.set(this.cookieKey, this.userPowerSelections);
    }

    @Mutation
    public clearState() {
        this.userPowerSelections = {
            UserId: null,
            SelectedGroupAccount: null,
            SelectedAccount: null,
            SelectedMpan: null,
            SelectedMeterType: null
        };
        this.userAccountsCount = -1;
        this.accountsCountForCurrentSearch = -1;
        this.userAccounts = Array<IUserAccount>();
        this.userPowerSites = Array<IPowerSite>();
        this.page = 1;
    }

    @Mutation
    public setSelectedPowerSite(accountNumber: string) {
        const powerSite = this.userPowerSites.find(x => x.AccountNumber === accountNumber);
        this.userPowerSelections.SelectedAccount = powerSite?.AccountNumber ?? null;
        this.userPowerSelections.SelectedMeterType = powerSite?.MeterType ?? null;
        Vue.$cookies.set(this.cookieKey, this.userPowerSelections);
    }

    @Mutation
    public setSelectedPowerMpan(Mpan: string) {
        this.userPowerSelections.SelectedMpan = Mpan;
        Vue.$cookies.set(this.cookieKey, this.userPowerSelections);
    }

    @Mutation
    public setUserPowerSitesLinkedToMPAN(Mpan: string) {
        const PowerSitesLinkedToMpan = this.userPowerSites.filter(x => x.Mpan == Mpan);
        this.userPowerSitesLinkedToMpan = PowerSitesLinkedToMpan;
    }

    @Mutation
    public setSelectedGroupAccount(groupAccountNumber: string) {
        const groupAccount = this.userAccounts.find(x => x.AccountNumber === groupAccountNumber);
        this.userPowerSelections.SelectedGroupAccount = groupAccount?.AccountNumber ?? null;
        this.userPowerSelections.SelectedAccount = null;
        this.userPowerSelections.SelectedMpan = null;
        this.userPowerSelections.SelectedMeterType = null;
        Vue.$cookies.set(this.cookieKey, this.userPowerSelections);
    }

    @Mutation
    public setMpanSelectionDisabled(disabled: boolean) {
        this.isMpanSelectionDisabled = disabled;
    }

    @Mutation
    public setAccountSelectionDisabled(disabled: boolean) {
        this.isAccountSelectionDisabled = disabled;
    }

    @Mutation
    public setGroupAccountSelectionDisabled(disabled: boolean) {
        this.isGroupAccountSelectionDisabled = disabled;
    }

    // Actions
    @Action({ rawError: true })
    public async fetchUserAccountsCount(userId: string): Promise<void> {
        const userAccountsCount = await this.powerAccountsService.fetchUserAccountsCount(userId);
        this.context.commit('setUserAccountsCount', userAccountsCount);
    }

    @Action({ rawError: true })
    public async fetchUserAccounts(params: { userId: string, groupAccountNumberQuery: string | null }): Promise<void> {
        const pageSize = this.userAccountsCount > 200 ? 200 : 0;
        const accountNumber = this.userAccountsCount > 200 ? params.groupAccountNumberQuery : null;

        const userAccountsData = await this.powerAccountsService.fetchUserAccounts(params.userId, pageSize, accountNumber);
        const selectedGroupAccountNumber = this.userPowerSelections.SelectedGroupAccount ?? userAccountsData.SelectedGroupAccount;

        const powerSites = await this.powerAccountsService.fetchPowerSitesForAccount(params.userId,selectedGroupAccountNumber);

        const selectedAccountNumber = this.userPowerSelections.SelectedAccount ?? powerSites[1];
        const selectedMpan = this.userPowerSelections.SelectedMpan ?? powerSites[3];

        this.context.commit('setAccountsCountForCurrentSearch', userAccountsData.TotalAccounts);
        this.context.commit('setUserAccounts', userAccountsData.UserAccounts);
        this.context.commit('setUserPowerSites', powerSites[0]);
        this.context.commit('setSelectedGroupAccount', selectedGroupAccountNumber);
        this.context.commit('setSelectedPowerSite', selectedAccountNumber);

        this.context.commit('setUserPowerMpans', powerSites[2]);
        this.context.commit('setSelectedPowerMpan', selectedMpan);
        this.context.commit('setUserPowerSitesLinkedToMPAN', selectedMpan);
    }

    @Action({ rawError: true })
    public async searchUserAccounts(params: { userId: string, groupAccountNumberQuery: string, appendResult: boolean }): Promise<void> {
        const pageSize = this.userAccountsCount > 200 ? 200 : 0;

        const userAccountsData = await this.powerAccountsService.searchUserAccounts(params.userId, params.groupAccountNumberQuery, this.page, pageSize);

        this.context.commit('setAccountsCountForCurrentSearch', userAccountsData.TotalAccounts);

        if (params.appendResult) {
            this.context.commit('setUserAccounts_Append', userAccountsData.UserAccounts);
            return;
        }

        this.context.commit('setUserAccounts', userAccountsData.UserAccounts);
    }

    @Action({ rawError: true })
    public async fetchPowerSitesForGroupAccount(params: { userId: string, groupAccountNumber: string }): Promise<void> {
        const data = await this.powerAccountsService.fetchPowerSitesForAccount(params.userId ,params.groupAccountNumber);

        if (!data) {
            return;
        }

        const selectedMpan = data[3];

        this.context.commit('setUserPowerSites', data[0]);
        this.context.commit('setSelectedPowerSite', data[1]);
        this.context.commit('setUserPowerMpans', data[2]);
        this.context.commit('setSelectedPowerMpan', selectedMpan);
        this.context.commit('setUserPowerSitesLinkedToMPAN', selectedMpan);
    }

    @Action({ rawError: true })
    public loadUserPowerSelections(userId: string): void {
        this.context.commit('loadUserPowerSelectionsFromCookie', userId);
    }

    @Action({ rawError: true })
    public onMpanSelection(Mpan: string): void {
        this.context.commit('setSelectedPowerMpan', Mpan);
        this.context.commit('setUserPowerSitesLinkedToMPAN', Mpan);
        const selectedPowerSite = this.userPowerSitesLinkedToMpan.filter(x => x.Mpan === Mpan)[0];
        this.context.commit('setSelectedPowerSite', selectedPowerSite.AccountNumber);
    }
}