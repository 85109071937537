import axios from '@/services/axiosService';
import newAxios from "@/services/invoicesAxiosService";
import { IFileDownload } from "@/interfaces/fileDownload";
import getFileName from "@/helpers/fileNameHelper"
import { ICogBasket } from "@/interfaces/cogBasket";
import { ICogReport } from "@/interfaces/cogREport";


const CONTROLLER = "/CostOfGas/";

export default class CostOfGasReportsService {    
  
    public async getBaskets(userId: string): Promise<ICogBasket[]> {

        const response = await axios.get(`${CONTROLLER}GetBaskets?userId=${userId}`);
        const result = JSON.parse((response as any).content) as ICogBasket[];

        return result;
    }

    public async getCogReports(userId: string, basketId : number): Promise<ICogReport[]> {

        const response = await axios.get(`${CONTROLLER}GetCogReports?userId=${userId}&basketId=${basketId}`);
        const result = JSON.parse((response as any).content) as ICogReport[];

        return result;
    }

    // if basketId is null, the latest reports for the userId are donwloaded
    public async downloadCogReports(userId: string, basketId: number | null): Promise<IFileDownload> {
        const response = await newAxios.get(`${CONTROLLER}DownloadReports`, {
            params:
            {
                userId: userId,
                basketId: basketId,
            }, responseType: "blob"
        });

        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Report could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }

    public async downloadCogReport(userId: string, filePath: string ): Promise<IFileDownload> {
        const response = await newAxios.get(`${CONTROLLER}DownloadReport`, {
            params:
            {
                userId: userId,
                filePath: filePath,                
            }, responseType: "blob"
        });

        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Report could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }


}