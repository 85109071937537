










import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
//import components
import CenteredLayout from "@/components/layouts/CenteredLayout.vue";
import LoginTab from "@/components/LoginTab.vue"
import RegisterTab from "@/components/RegisterTab.vue"
import ForgottenTab from "@/components/ForgottenTab.vue"

const Auth = namespace("Auth");

@Component({
    components: {
        CenteredLayout,
        LoginTab,
        RegisterTab,
        ForgottenTab
    },
})
export default class Index extends Vue {
    public title = "Landing"

    private step = 0;

    created() {
        document.title = this.title;
        if (this.$route.query.id == 'resetpassword') {
            this.step = 2;
        }
    }

    

}
