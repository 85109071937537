import newAxios from "@/services/invoicesAxiosService";
import { IInvoiceDetails } from "../interfaces/invoiceDetails";
import { IFileDownload } from "@/interfaces/fileDownload";
import getFileName from "@/helpers/fileNameHelper"

const CONTROLLER = "/invoicegas/";
export default class InvoicesService {

    public async fetchInvoices(accountNum: string, siteRefNum: string, userId: string) {
        const response = await newAxios.get(`${CONTROLLER}siteInvoices`, { params: { accountNum: accountNum, siteRefNum: siteRefNum, userId: userId } });
        const result = JSON.parse((response as any).data.content) as IInvoiceDetails[];
        return result;
    }

    public async invoiceDownload(accountNum: string, invoiceNo: string, userId: string) {
        const response = await newAxios.get(`${CONTROLLER}siteInvoices/download`, { params: { invoiceNo, userId }, responseType: "blob" });
        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Report could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }

    public async downloadLatestMonthInvoicesForAccount(accountNum: string, userId: string) {

        const response = await newAxios.get(`${CONTROLLER}latest-month-account-invoices`, { params: { accountNum: accountNum, userId: userId }, responseType: "blob" });
        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Invoices could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }

    public async downloadLatestYearInvoicesForAccount(accountNum: string, userId: string) {
        const response = await newAxios.get(`${CONTROLLER}latest-year-account-invoices`, { params: { accountNum: accountNum, userId: userId }, responseType: "blob" });
        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Invoices could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }

    public async downloadCustomRangeInvoicesForAccount(accountNum: string, userId: string, fromMonth: string, toMonth: string) {
        const response = await newAxios.get(`${CONTROLLER}custom-range-account-invoices`, { params: { accountNum: accountNum, userId: userId, fromMonth: fromMonth, toMonth: toMonth }, responseType: "blob" });
        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Invoices could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }

    public async downloadInvoicesForAllAccounts(userId: string) {
        const response = await newAxios.get(`${CONTROLLER}latest-month-all-accounts-invoices`, { params: { userId: userId }, responseType: "blob" });
        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Invoices could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }

    public async downloadCustomRangeInvoicesForAllAccounts(userId: string, fromDate: string, toDate: string) {
        const response = await newAxios.get(`${CONTROLLER}custom-range-all-accounts-invoices`, { params: { userId: userId, fromDate: fromDate, toDate: toDate }, responseType: "blob" });
        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Invoices could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }
}