























































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IUserAccount } from "@/interfaces/userAccount";
import { IDeliveryAgreement } from "@/interfaces/deliveryAgreement";
import ToastService from '@/services/toastService';

const Auth = namespace("Auth");
const GasAccounts = namespace("GasAccounts");
const InvoicingWizard = namespace("InvoicingWizard");

@Component
export default class GasAccountSelector extends Vue {
    private disableTooltip = false;
    private observer: any = null;
    private searchString = '';
    private watchAccountSelection: any;

    private enableTooltips(): void {
        this.disableTooltip = false;
    }

    private disableTooltips(): void {
        this.disableTooltip = true;
        this.$root.$emit('bv::hide::tooltip')
    }

    @Auth.Getter
    private getUserId!: string;

    @GasAccounts.Getter
    private getUserAccountsCount!: number;

    @GasAccounts.Getter
    private getUserAccounts!: Array<IUserAccount>;

    @GasAccounts.Getter
    private getUserDeliveryAgreements!: Array<IDeliveryAgreement>;

    @GasAccounts.Getter
    private getUserDeliveryAgreementsLinkedToMPRN!: Array<IDeliveryAgreement>;

    @GasAccounts.Getter
    private getDistinctMPRNS!: Array<IDeliveryAgreement>;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @GasAccounts.Getter
    private getSelectedMprn!: string;

    @GasAccounts.Getter
    private getSelectedDeliveryAgreementId!: string;

    @GasAccounts.Getter
    private getSelectedDeliveryAgreementNumber!: string;

    @GasAccounts.Getter
    private getIsMorePages!: boolean;

    @GasAccounts.Getter
    private getIsMprnSelectionDisabled!: boolean;

    @GasAccounts.Getter
    private getIsAgreementSelectionDisabled!: boolean;

    @GasAccounts.Getter
    private getIsAccountSelectionDisabled!: boolean;

    @GasAccounts.Mutation
    private setSelectedAccount!: (accountNumber: string) => void;

    @GasAccounts.Mutation
    private setDeliveryAgreementsByAccountNumber!: (accountNumber: string) => void;

    @GasAccounts.Mutation
    private resetPageNumber!: () => void;

    @GasAccounts.Mutation
    private nextPage!: () => void;

    @GasAccounts.Mutation
    private setSelectedDeliveryAgreementAndMprn!: (agreementId: string) => void;

    @GasAccounts.Mutation
    private setSelectedMeterPointReferenceNumber!: (MPRN: string) => void;

    @GasAccounts.Action
    private fetchUserAccountsCount!: (userId: string) => Promise<void>;

    @GasAccounts.Action
    private fetchUserAccounts!: (params: { userId: string, accountNumberQuery: string | null }) => Promise<void>;

    @GasAccounts.Action
    private fetchUserAccountsAfterSearch!: (params: { userId: string, accountNumberQuery: string | null }) => Promise<void>;

    @GasAccounts.Action
    private searchUserAccounts!: (params: { userId: string, query: string, appendResult: boolean }) => Promise<void>;

    @GasAccounts.Action
    private fetchDeliveryAgreementsForAccount!: (params: { userId: string, accountNumber: string }) => Promise<void>;

    @GasAccounts.Action
    private fetchAgreementIdToSelect!: (params: { userId: string, accountNumber: string }) => Promise<void>;

    @GasAccounts.Action
    private fetchAgreementIdToSelectAfterMPRNSelection!: (params: { userId: string, accountNumber: string, MPRN: string }) => Promise<void>;

    @GasAccounts.Action
    private fetchDeliveryAgreementsWithLabelsAfterMPRNSelection!: (params: { userId: string, MPRN: string }) => Promise<void>;

    @GasAccounts.Action
    private loadUserGasSelections!: (userId: string) => void;

    @InvoicingWizard.Action
    private resetJourneyNode!: () => Promise<void>;

    private onSetSelectedAccount(accountNumber: string): void {
        this.searchString = accountNumber;

        this.setDeliveryAgreementsByAccountNumber(accountNumber);

        // If delivery agreements collection is not empty then find call the API to find out which agreement ID should be selected
        // (for smaller users accounts data is returned along with relevant agreements)
        if (this.getUserDeliveryAgreements.length > 0 && this.getDistinctMPRNS.length > 0) {
            this.fetchAgreementIdToSelect({ userId: this.getUserId, accountNumber: accountNumber });
            // Otherwise get delivery agreements for the supplied account number
        } else {
            this.fetchDeliveryAgreementsForAccount({ userId: this.getUserId, accountNumber: accountNumber });
        }
    }

    private onSetSelectedMPRN(MPRN: string): void {
        //this.setSelectedMeterPointReferenceNumber(MPRN);
        this.fetchDeliveryAgreementsWithLabelsAfterMPRNSelection({ userId: this.getUserId, MPRN: MPRN });
        this.fetchAgreementIdToSelectAfterMPRNSelection({ userId: this.getUserId, accountNumber: this.getSelectedAccount, MPRN: MPRN });
    }
    
    get getMprns(){
        return this.getDistinctMPRNS.filter(i => i !== null);
    }

    get isFilterable(): boolean {
        return this.getUserAccountsCount <= 200 ?? false;
    }

    private async onOpen() {
        await this.$nextTick()

        if (this.$refs.load === undefined) return;

        this.observer.observe(this.$refs.load);
    }

    private onClose() {
        this.observer.disconnect();
    }

    private async onSearch(search: string, loading: (isLoading: boolean) => void) {
        if (this.isFilterable) {
            loading(false);
            return;
        }

        this.onClose();

        if (search.length === 0) {
            this.resetPageNumber();
            await this.fetchUserAccountsAfterSearch({ userId: this.getUserId, accountNumberQuery: this.searchString });
            this.onOpen();
        }

        this.searchString = search.trim();

        if (search.length > 2) {
            loading(true);
            this.search(this.searchString, loading);
        }
    }

    private async search(search: string, loading: (isLoading: boolean) => void) {
        try {
            this.resetPageNumber();
            await this.searchUserAccounts({ userId: this.getUserId, query: search, appendResult: false });
            this.onOpen();
        } catch (e) {
            console.error(e);
        } finally {
            loading(false);
        }
    }

    async mounted() {
        try {
            this.observer = new IntersectionObserver(async (entry) => {
                if ((entry[0] as any).isIntersecting) {
                    const ul = ((entry[0] as any).target).offsetParent;
                    const scrollTop = ((entry[0] as any).target).offsetParent.scrollTop;
                    this.nextPage();
                    await this.searchUserAccounts({ userId: this.getUserId, query: this.searchString, appendResult: true });
                    await this.$nextTick();
                    ul.scrollTop = scrollTop;
                }
            });
            await this.fetchUserAccountsCount(this.getUserId);
            this.loadUserGasSelections(this.getUserId);
            await this.fetchUserAccounts({ userId: this.getUserId, accountNumberQuery: this.isFilterable && this.getSelectedAccount?.length === 0 ? null : this.getSelectedAccount });

            this.watchAccountSelection = this.$store.watch((state, getters) => getters["GasAccounts/getSelectedAccount"], (newValue, oldValue) => {
                if (newValue === null || newValue === oldValue) {
                    return;
                }
                this.resetJourneyNode();
            });

        } catch (e) {
            console.error(e);
        }
    }

    beforeDestroy() {
        this.observer;
        this.watchAccountSelection();
    }


    private copyToClipboard(copyType: string): void {
        var TextToCopy = '';
        if (copyType === 'MPRN') {
            TextToCopy = this.getSelectedMprn;
        } else if (copyType === 'Agreement') {
            TextToCopy = this.getSelectedDeliveryAgreementNumber;
        } else if (copyType === 'Account') {
            TextToCopy = this.getSelectedAccount;
        }

        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText(TextToCopy).then(() => {
                this.notifyTextCopied(copyType);
            })
            .catch(() => {
                this.$bvToast.toast("Something went wrong!", ToastService.ErrorMessageDefaults());
            });
        } else {
            this.unsecuredCopyToClipboard(TextToCopy);
            this.notifyTextCopied(copyType);
        }
    }

    private notifyTextCopied(copyType: string) {
        const SuccessMessageDefaults = ToastService.SuccessMessageDefaults();
        SuccessMessageDefaults.autoHideDelay = 3000;
        SuccessMessageDefaults.variant = '#cfdf9a';
        SuccessMessageDefaults.bodyClass = 'copy-success-message';
        SuccessMessageDefaults.headerClass = 'copy-success-message';
        SuccessMessageDefaults.noCloseButton = true;
        SuccessMessageDefaults.title = '';
        this.$bvToast.toast(copyType + " copied", SuccessMessageDefaults);
    }

    private unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value = text; document.body.appendChild(textArea); textArea.focus(); textArea.select(); try { document.execCommand('copy') } catch (err) { console.error('Unable to copy to clipboard', err) } document.body.removeChild(textArea) };
}
