import axios from '@/services/axiosService';
import { IPowerMeterReadData } from '../interfaces/powerMeterReadData';

const CONTROLLER = "/MeterReadsPower/";

export default class MeteringPowerService {

    public async getPowerMeterReads(params: { mpan: string, accountNum: string, userId: string }): Promise<IPowerMeterReadData> {
        const response = await axios.get(`${CONTROLLER}GetMeterReads`, { params: params });
        const result = JSON.parse((response as any).content);
        const powerMeterReads = result as IPowerMeterReadData;
        return powerMeterReads;
    }

    public async getPowerMeterReadStamentsAdd(params: { mpan: string, accountNum: string, meterid: string, userId: string }): Promise<any> {
        const response = await axios.get(`${CONTROLLER}GetPowerMeterReadStamentsAdd`, { params: params });
        const result = JSON.parse((response as any).content);
        return result;
    }

    public async addPowerMeterRead(powerMeterReadAddData: any): Promise<any> {
        const response = await axios.post(`${CONTROLLER}PowerMeterReadAdd`, powerMeterReadAddData);
        const result = JSON.parse((response as any));
        return result;
    }

}