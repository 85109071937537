export class MpanTypes {

    public static NonHalfHourlyMeterTypes(): Array<string> {
        return [EnumMeterType[EnumMeterType.NHH], EnumMeterType[EnumMeterType.S]];
    }

    public static HalfHourlyMeterTypes(): Array<string> {
        return [EnumMeterType[EnumMeterType.HH], EnumMeterType[EnumMeterType.IHH]];
    }

    public static AllMeterTypes(): Array<string> {
        return [EnumMeterType[EnumMeterType.NHH], EnumMeterType[EnumMeterType.S], EnumMeterType[EnumMeterType.HH], EnumMeterType[EnumMeterType.IHH]];
    }
}

export enum EnumMeterType {
    IHH,
    NHH,
    HH ,
    S
}