import axios from '@/services/axiosService';
import newAxios from "@/services/invoicesAxiosService";
import { IFileDownload } from "@/interfaces/fileDownload";
import getFileName from "@/helpers/fileNameHelper"

import { IPowerConsumptionReportsRequestParams } from '@/interfaces/powerConsumptionReportsRequestParams';
import { IPowerConsumptionReportsRequestMultiDateParams } from '@/interfaces/powerConsumptionReportsRequestMultiDateParams';

const CONTROLLER = "/ConsumptionReportsPower/";

export default class ConsumptionPowerReportsService {       

    public async getGraphData(data: IPowerConsumptionReportsRequestParams): Promise<string> {
        let GraphData = '';

        const response = await newAxios.get(`${CONTROLLER}GetGraphData`, {
            params:
            {
                userId: data.userId,
                accountNumber: data.accountNumber,
                mpan: data.mpan,
                fromDate: data.fromDate,
                toDate: data.toDate
            }, responseType: "json"
        }).then(result => {
            GraphData = result.data.content;
        });

        return GraphData;
    }

    public async getAdditionalGraphData(data: IPowerConsumptionReportsRequestParams): Promise<string> {
        let AdditionalGraphData = '';

        const response = await newAxios.get(`${CONTROLLER}HalfHourlyData`, {
            params:
            {
                userId: data.userId,
                accountNumber: data.accountNumber,
                mpan: data.mpan,
                startDate: data.fromDate,
                endDate: data.toDate
            }, responseType: "json"
        }).then(result => {
            AdditionalGraphData = result.data.content;
        });

        return AdditionalGraphData;
    }

    public async getDownloadDailyConsumptionReport(data: IPowerConsumptionReportsRequestParams): Promise<IFileDownload> {
        const response = await newAxios.get(`${CONTROLLER}HalfHourlyCsv`, {
            params:
            {
                userId: data.userId,
                accountNumber: data.accountNumber,
                mpan: data.mpan,
                fromDate: data.fromDate,
                toDate: data.toDate
            }, responseType: "blob"
        });

        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Report could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }

    public async getDownloadHalfHourlyCsvMultiDate(data: IPowerConsumptionReportsRequestMultiDateParams): Promise<IFileDownload> {
        const response = await newAxios.get(`${CONTROLLER}HalfHourlyCsvMultiDate`, {
            params:
            {
                userId: data.userId,
                accountNumber: data.accountNumber,
                mpan: data.mpan,
                commaSeperatedDates: data.dates
            }, responseType: "blob"
        });

        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Report could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;
    }
}