import Vue from "vue";
import Vuex from "vuex";

import Auth from "./modules/auth.module";
import Panel from "./modules/panel.module";
import Products from "./modules/products.module";
import GasAccounts from "./modules/gasAccounts.module";
import PowerAccounts from "./modules/powerAccounts.module";
import Terms from "./modules/terms.module";
import InvoicingWizard from "./modules/invoicingWizard.module";
import MeteringWizard from "./modules/meteringWizard.module";
import BillingWizard from "./modules/billingWizard.module";
import ConsumptionWizard from "./modules/consumptionWizard.module";
import Invoices from "./modules/invoices.module";
import Metering from "./modules/metering.module";
import PowerInvoices from "./modules/powerInvoices.module";
import PowerGroupInvoices from "./modules/powerGroupInvoices.module";
import PowerMetering from "./modules/powerMetering.module";
import Billing from "./modules/billing.module";
import ConsumptionReports from "./modules/consumptionReports.module";
import EnergyCostReports from "./modules/energyCostReports.module";
import CostOfGasReports from "./modules/costOfGasReports.module";
import AmrSmartMeteringReports from "./modules/amrSmartMeteringReports.module";
import DMConsumptionReports from "./modules/dmConsumptionReports.module";
import DailyConsumptionReports from "./modules/dailyConsumptionReports.module";
import ConsumptionReportsPower from "./modules/consumptionPowerReports.module";
import Balance from "./modules/balance.module";
import Manage from "./modules/manage.module";
import FeatureToggle from "./modules/featureToggle.module";
import BannerContent from "./modules/bannerContent.module";
import Admin from "./modules/admin.module";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {},
    mutations: {},
    actions: {
        clearAllStates({ commit }) {
            commit("Auth/clearState");
            commit("Panel/clearState");            
            commit("Terms/clearState");
            commit("Products/clearState");
            commit("GasAccounts/clearState");
            commit("PowerAccounts/clearState");
            commit("Metering/clearState");
            commit("InvoicingWizard/resetJourney");
            commit("MeteringWizard/resetJourney");
            commit("BillingWizard/resetJourney");
            commit("ConsumptionWizard/resetJourney");
            commit("BannerContent/clearState");
        }
    },
    modules: {
        Auth,        
        Panel,
        Products,
        GasAccounts,
        PowerAccounts,
        Terms,
        InvoicingWizard,
        MeteringWizard,
        BillingWizard,
        ConsumptionWizard,
        Invoices,
        Metering,
        PowerInvoices,
        PowerGroupInvoices,
        PowerMetering,
        Billing,
        ConsumptionReports,
        EnergyCostReports,
        CostOfGasReports,
        AmrSmartMeteringReports,
        DMConsumptionReports,
        DailyConsumptionReports,
        ConsumptionReportsPower,
        Balance,
        Manage,
        FeatureToggle,
        BannerContent,
        Admin
    },
});

export default store