import axios from '@/services/axiosService';
import { IPowerAccountsViewModel } from "@/interfaces/powerAccountsViewModel"
import { IPowerSite } from "@/interfaces/powerSite"


const CONTROLLER = "/PowerAccounts/";

export default class PowerAccountsService {
    public async fetchUserAccountsCount(userId: string): Promise<number> {
        const response = await axios.get(`${CONTROLLER}GetUserGroupAccountsCount`, { params: { userId: userId } });
        const result = JSON.parse((response as any).content) as Promise<number>;

        return result;
    }

    public async fetchUserAccounts(userId: string, size: number, groupAccountNumberQuery: (string | null)): Promise<IPowerAccountsViewModel> {
        const response = await axios.get(`${CONTROLLER}GetUserAccounts`, { params: { userId: userId, size: size, groupAccountNumberQuery: groupAccountNumberQuery } });
        const result = JSON.parse((response as any).content) as IPowerAccountsViewModel;

        return result;
    }

    public async searchUserAccounts(userId: string, groupAccountNumberQuery: string, page: number, size: number): Promise<IPowerAccountsViewModel> {
        const response = await axios.get(`${CONTROLLER}SearchUserAccounts`, { params: { userId: userId, groupAccountNumberQuery: groupAccountNumberQuery, page: page, size: size } });
        const result = JSON.parse((response as any).content) as IPowerAccountsViewModel;

        return result;
    }

    public async fetchPowerSitesForAccount(userId: string, groupAccountNumber: string): Promise<[IPowerSite[], string, Array<string>, string]> {
        const response = await axios.get(`${CONTROLLER}GetPowerSites`, { params: { userId : userId, groupAccountNumber: groupAccountNumber } });

        const result = JSON.parse((response as any).content);

        const powerSites = result.PowerSites as IPowerSite[];
        const selectedPowerSite = result.SelectedSite as string;
        const distinctMpans = result.DistinctMPANS as Array<string>;
        const selectedMpan = result.SelectedMpan as string;

        return [powerSites, selectedPowerSite, distinctMpans, selectedMpan];
    }
}