

























































































































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TermsOfUseModal extends Vue {
    
    private acceptBtnDisabled = true;

    public handleAcceptTermOfUse(bvModalEvent: any): void {
        this.$emit("acceptedTerms");
    }

    public handleDeclineTermOfUse(bvModalEvent: any): void {
        this.$emit("declinedTerms");
    }

    public onScroll(event: any) {            
        
        var scrollMaxHeightPosition = ((event.srcElement as HTMLElement).scrollHeight) - (event.srcElement as HTMLElement).offsetHeight;

        var currentScrollPosition = event.srcElement.scrollTop;

        var tolerance = 100

        if (currentScrollPosition + tolerance >= scrollMaxHeightPosition) {
            this.acceptBtnDisabled = false;
        }              
    }

    mounted() {
        this.$root.$on('bv::modal::shown', (bvEvent: any, modalId: any) => {
            const element = document.getElementById('modal-scrollable___BV_modal_body_');               
            (element as HTMLElement).addEventListener('scroll', this.onScroll, { passive: true });
        });
    }
}
