


























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import stickybits from 'stickybits';

import FlexFillLayout from "@/components/layouts/FlexFillLayout.vue";
import HeaderSection from '@/components/HeaderSection.vue';
import NavMenu from '@/components/NavMenu.vue';
import AlertBanner from '@/components/AlertBanner.vue';
import AccountSelector from '@/components/AccountSelector.vue';
//import Breadcrumb from '@/components/elements/Breadcrumb.vue'
import FooterSection from '@/components/FooterSection.vue';
import { IBanner } from '@/interfaces/banner';

const Auth = namespace('Auth');
const FeatureToggle = namespace("FeatureToggle");
const BannerContent = namespace("BannerContent");

@Component({
    components: {
        FlexFillLayout,
        HeaderSection,
        NavMenu,
        AlertBanner,
        AccountSelector,
        //Breadcrumb,
        FooterSection
    }
})
export default class BasePage extends Vue {
    @Auth.Getter
    private isLoggedIn!: boolean;

    private isStaticPage = false;
    private existingBannerContent!: IBanner;
    private bannerText = "";

    @FeatureToggle.Getter
    private getInvoiceAndMeterReadPanelFeatureToggleStatus!: boolean;

    @FeatureToggle.Action
    private getInvoiceAndMeterReadPanelFeatureToggleStatusAction!: (userId: any) => Promise<any>;

    @Auth.Getter
    private getUserId!: string;

    @BannerContent.Getter
    private getLatestBannerContent!: IBanner;

    @BannerContent.Action
    private fetchLatestBannerContent!: (params: { userId: string }) => Promise<void>;

    private InvoiceAndMeterPanelOn = true;

    async mounted() {
        await this.fetchLatestBannerContent({ userId: this.getUserId });
        this.existingBannerContent = this.getLatestBannerContent;
        if (this.existingBannerContent != null && this.existingBannerContent?.bannerContent != null) {
            this.bannerText = this.existingBannerContent.bannerContent
        }
        this.getInvoiceAndMeterReadPanelFeatureToggleStatusAction(this.getUserId).then(x => {
            this.InvoiceAndMeterPanelOn = this.getInvoiceAndMeterReadPanelFeatureToggleStatus;
            if (this.$route.name === 'CookiePolicy' || this.$route.name === 'TermsOfUse' || this.$route.name === 'ChangePassword' || this.$route.name == 'HelpAndSupport') {
                this.isStaticPage = true;
            }
            if (!this.isLoggedIn && !this.isStaticPage) {
                this.$router.push("/");
            }
            stickybits('#stickybit-header-nav-menu');
        });
    }
}
