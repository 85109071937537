




































import BasePage from "@/components/pages/BasePage.vue";
import MyBalancePanel from '@/components/panels/MyBalancePanel.vue';
import LatestInvoicePanel from '@/components/panels/LatestInvoicePanel.vue';
import LatestGasReadPanel from '@/components/panels/LatestGasReadPanel.vue';
import ContractDetailPanel from '@/components/panels/ContractDetailPanel.vue';
import SimplePanel from '@/components/panels/SimplePanel.vue';
import TermsOfUseModal from '@/components/elements/TermsOfUseModal.vue';

import { Component, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { ITermsOfUse } from "@/interfaces/termsOfUse";
import moment from 'moment';

const Auth = namespace("Auth");
const Products = namespace("Products");
const Terms = namespace("Terms");
const PowerAccounts = namespace("PowerAccounts");
const GasAccounts = namespace("GasAccounts");


@Component({
    components: {
        TermsOfUseModal,
        BasePage,
        MyBalancePanel,
        LatestInvoicePanel,
        LatestGasReadPanel,
        ContractDetailPanel,
        SimplePanel,
        ctaButtonHref: { 'name': "Invoicing" },
    },
})
export default class Dashboard extends Vue {
    private title = "Home";
    public readTermOfUse = false;
    public noCloseOnEsc = true;
    public noCloseOnBackdrop = true;
    public showPanelIfHH = false;
    private watchIsHalfHourly: any;

    @Auth.Getter
    private getUserId!: string;

    @Products.Getter
    private getSelectedProduct!: string;

    @Terms.Getter
    private getHasAcceptedTermsOfUse!: boolean;

    @Terms.Action
    private hasAcceptedTermsOfUse!: (userId: string) => Promise<void>;

    @Terms.Action
    private AcceptsTermsOfUse!: (data: ITermsOfUse) => Promise<void>;

    @Auth.Action
    private signOut!: () => any;


    @GasAccounts.Getter
    private getUserAccountsCount!: number;

    @GasAccounts.Getter
    private getUserAccountsLoaded!: boolean;


    public async handleAcceptTermOfUse(): Promise<void> {
        this.readTermOfUse = true;
        const terms: ITermsOfUse = { UserId: this.getUserId, DateAccepted: moment(Date.now()).format() };
        await this.AcceptsTermsOfUse(terms);
    }

    public handleDeclineTermOfUse(): void {
        this.signOut();
        this.$router.push("/");
    }

    get hasNoAccounts() {

        var userAccountsLoaded = this.getUserAccountsLoaded;

        var userAccountsCount = this.getUserAccountsCount;

        if (userAccountsLoaded === true && userAccountsCount === 0) {
            return true;
        }

        return false;
    }

    created() {
        document.title = this.title;
    }

    async mounted() {
        await this.hasAcceptedTermsOfUse(this.getUserId);
        if (!this.getHasAcceptedTermsOfUse) {
            this.$bvModal.show("modal-scrollable");
        }

        try {
            this.watchIsHalfHourly = this.$store.watch(
                (state, getters) => getters["PowerAccounts/isHalfHourly"],
                (newValue, oldValue) => {
                    this.showPanelIfHH = newValue;
                }
                , { immediate: true });
        } catch (e) {
            this.showPanelIfHH = false;
            console.error(e);
        }
    }

}
