

















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MainItemLabel extends Vue {
    @Prop({ type: String, required: true }) readonly itemLabel!: string;
    @Prop({ type: String, required: true }) readonly itemValue!: string;
    @Prop({ type: String }) readonly responsiveClass!: string;

    get amountClass() {
        if (this.itemValue.length > 8) {

            return 'col-xs-8'; // large value, make the column larger.
        }

        return 'col-xs-7'; // standard sized column;
    }
}
