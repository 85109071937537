import Vue from 'vue';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import ProductsService from '@/services/productsService';

import { IUserProductSelections } from "@/interfaces/userProductSelections";

@Module({ namespaced: true })
export default class Products extends VuexModule {
    private productsService = new ProductsService();
    private readonly cookieKey = "userProductSelections";

    // State
    private userProducts = Array<string>();
    private userProductSelection = {
        UserId: null,
        SelectedProduct: null
    } as IUserProductSelections;
    private isProductSelectionDisabled = false;

    // Getters
    get getUserProducts(): Array<string> {
        return this.userProducts;
    }

    get getSelectedProduct(): (string | null) {
        return this.userProductSelection.SelectedProduct;
    }

    get getIsProductSelectionDisabled(): boolean {
        return this.isProductSelectionDisabled;
    }

    // Mutations
    @Mutation
    private setUserProducts(userProducts: Array<string>) {
        this.userProducts = userProducts;
    }

    @Mutation
    private loadUserProductSelectionFromCookies(userId: string) {
        const userSelection = Vue.$cookies.get(this.cookieKey) as IUserProductSelections;
        const userIdInCookies = userSelection?.UserId;

        if (userId === userIdInCookies) {
            this.userProductSelection = userSelection;
        }

        this.userProductSelection.UserId = userId;
        Vue.$cookies.set(this.cookieKey, this.userProductSelection);
    }

    @Mutation
    public clearState() {
        this.userProducts = Array<string>();
        this.userProductSelection = {
            UserId: null,
            SelectedProduct: null
        }
    }

    @Mutation
    public setSelectedProduct(product: string) {
        this.userProductSelection.SelectedProduct = product;
        Vue.$cookies.set(this.cookieKey, this.userProductSelection);
    }

    @Mutation
    public setProductSelectionDisabled(disabled: boolean) {
        this.isProductSelectionDisabled = disabled;
    }

    // Actions
    @Action({ rawError: true })
    public async loadUserProducts(userId: string): Promise<void> {
        const userProducts = await this.productsService.getUserProducts(userId);

        const selectedProduct = this.userProductSelection.SelectedProduct ?? userProducts.SelectedProduct;

        this.context.commit('setUserProducts', userProducts.UserProducts);
        this.context.commit('setSelectedProduct', selectedProduct);
    }

    @Action({ rawError: true })
    public loadUserProductSelections(userId: string): void {
        this.context.commit('loadUserProductSelectionFromCookies', userId);
    }
}