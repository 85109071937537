import axios from '@/services/axiosService';
import newAxios from "@/services/invoicesAxiosService";
import { IUserType } from "@/interfaces/userType";
import { IAddUserRequestParams } from "@/interfaces/addUserRequestParams";
import { IUpdateUserRequestParams } from "@/interfaces/updateUserRequestParams";

const CONTROLLER = "/Admin/";

export default class AdminService {    

    public async getManageUserTypes(userId: string): Promise<IUserType[]> {
        const response = await newAxios.get(`${CONTROLLER}GetManageUserTypes?userId=${userId}`);
        const result = JSON.parse(response.data.content) as IUserType[];

        return result;
    }
    
    public async addUser(addUserRequestParams: IAddUserRequestParams): Promise<boolean> {
        let userAdded = false;

        const response = await newAxios
            .post(`${CONTROLLER}CreateUser`, {
                FirstName: addUserRequestParams.FirstName,
                Surname: addUserRequestParams.Surname,
                Telephone: addUserRequestParams.Telephone,
                Email: addUserRequestParams.Email,
                ConfirmEmail: addUserRequestParams.ConfirmEmail,
                SelectedUserTypeId: addUserRequestParams.SelectedUserTypeId,
                SelectedUserTypeName: addUserRequestParams.SelectedUserTypeName,
                SelectedGasAccounts: addUserRequestParams.SelectedGasAccounts,
                SelectedPowerAccounts: addUserRequestParams.SelectedPowerAccounts,
                SelectedBasketIds: addUserRequestParams.SelectedBasketIds,
                UserId: addUserRequestParams.UserId
            })
            .then(result => {
                userAdded = JSON.parse(result.data.content)
            });

        return userAdded;
    }
   
    public async userExists(userId: string, email: string): Promise<boolean> {
        let userExists = false;
        const response = await newAxios.get(`${CONTROLLER}UserExists?userId=${userId}&email=${email}`)
            .then(result => {
                userExists = JSON.parse(result.data.content)
            });

        return userExists;
    }

    public async getUsers(userId: string, page: number, size: number, preload: boolean): Promise<boolean> {
        let users = false;
        const response = await newAxios.get(`${CONTROLLER}ManageUsers?userId=${userId}&page=${page}&size=${size}&preload=${preload}`)
            .then(result => {
                users = JSON.parse(result.data.content)
            });

        return users;
    }

    public async searchUsers(searchString: string, userId: string, page: number, size: number): Promise<boolean> {
        let users = false;
        const response = await newAxios.get(`${CONTROLLER}SearchUsers?searchString=${searchString}&userId=${userId}&page=${page}&size=${size}`)
            .then(result => {
                users = JSON.parse(result.data.content)
            });

        return users;
    }

    public async updateUser(updateUserRequestParams: IUpdateUserRequestParams): Promise<boolean> {
        let userUpdated = false;

        const response = await newAxios
            .post(`${CONTROLLER}UpdateUser`, {
                FirstName: updateUserRequestParams.FirstName,
                Surname: updateUserRequestParams.Surname,
                Telephone: updateUserRequestParams.Telephone,
                Email: updateUserRequestParams.Email,
                ConfirmEmail: updateUserRequestParams.ConfirmEmail,
                UserId: updateUserRequestParams.UserId,
                UserIdEdit: updateUserRequestParams.UserIdEdit,
                IsLocked: updateUserRequestParams.IsLocked
            })
            .then(result => {
                userUpdated = JSON.parse(result.data.content)
            });

        return userUpdated;
    }

    public async removeAccountGas_Update(userId: string, targetUserId: string, accountNumber: string): Promise<boolean> {
        let userUpdated = false;

        const response = await newAxios
            .get(`${CONTROLLER}RemoveAccountGas_Update?userId=${userId}&targetUserId=${targetUserId}&accountNumber=${accountNumber}`)
            .then(result => {
                userUpdated = JSON.parse(result.data.content)
            });

        return userUpdated;
    }

    public async updateAccountGas_Update(updateUserRequestParams: IUpdateUserRequestParams): Promise<boolean> {
        let userUpdated = false;

        const response = await newAxios
            .post(`${CONTROLLER}UpdateAccountGas_Update`, {
                UserId: updateUserRequestParams.UserId,
                UserIdEdit: updateUserRequestParams.UserIdEdit,
                SelectedGasAccounts: updateUserRequestParams.SelectedGasAccounts
            })
            .then(result => {
                userUpdated = JSON.parse(result.data.content)
            });

        return userUpdated;
    }

    public async addAccountGas_Update(updateUserRequestParams: IUpdateUserRequestParams): Promise<boolean> {
        let userUpdated = false;

        const response = await newAxios
            .post(`${CONTROLLER}AddAccountGas_Update`, {
                UserId: updateUserRequestParams.UserId,
                UserIdEdit: updateUserRequestParams.UserIdEdit,
                SelectedGasAccounts: updateUserRequestParams.SelectedGasAccounts
            })
            .then(result => {
                userUpdated = JSON.parse(result.data.content)
            });

        return userUpdated;
    }

    public async removeAccountPower_Update(userId: string, targetUserId: string, accountNumber: string): Promise<boolean> {
        let userUpdated = false;

        const response = await newAxios
            .get(`${CONTROLLER}RemoveAccountPower_Update?userId=${userId}&targetUserId=${targetUserId}&accountNumber=${accountNumber}`)
            .then(result => {
                userUpdated = JSON.parse(result.data.content)
            });

        return userUpdated;
    }

    public async updateAccountPower_Update(updateUserRequestParams: IUpdateUserRequestParams): Promise<boolean> {
        let userUpdated = false;

        const response = await newAxios
            .post(`${CONTROLLER}UpdateAccountPower_Update`, {
                UserId: updateUserRequestParams.UserId,
                UserIdEdit: updateUserRequestParams.UserIdEdit,
                SelectedPowerAccounts: updateUserRequestParams.SelectedPowerAccounts
            })
            .then(result => {
                userUpdated = JSON.parse(result.data.content)
            });

        return userUpdated;
    }

    public async addAccountPower_Update(updateUserRequestParams: IUpdateUserRequestParams): Promise<boolean> {
        let userUpdated = false;

        const response = await newAxios
            .post(`${CONTROLLER}AddAccountPower_Update`, {
                UserId: updateUserRequestParams.UserId,
                UserIdEdit: updateUserRequestParams.UserIdEdit,
                SelectedPowerAccounts: updateUserRequestParams.SelectedPowerAccounts
            })
            .then(result => {
                userUpdated = JSON.parse(result.data.content)
            });

        return userUpdated;
    }

    public async addBaskets_Update(updateUserRequestParams: IUpdateUserRequestParams): Promise<boolean> {
        let userUpdated = false;

        const response = await newAxios
            .post(`${CONTROLLER}AddBasket_Update`, {
                UserId: updateUserRequestParams.UserId,
                UserIdEdit: updateUserRequestParams.UserIdEdit,
                SelectedBasketIds: updateUserRequestParams.SelectedBasketIds
            })
            .then(result => {
                userUpdated = JSON.parse(result.data.content)
            });

        return userUpdated;
    }

    public async removeBaskets_Update(userId: string, targetUserId: string, basketId: number): Promise<boolean> {
        let userUpdated = false;

        const response = await newAxios
            .get(`${CONTROLLER}RemoveBasket_Update?userId=${userId}&targetUserId=${targetUserId}&basketId=${basketId}`)
            .then(result => {
                userUpdated = JSON.parse(result.data.content)
            });

        return userUpdated;
    }

    public async UpdateUserPreload(userId: string, userIdEdit: string): Promise<any> {
        let PreloadData: any;
        const response = await newAxios.get(`${CONTROLLER}UpdateUserPreload?userId=${userId}&userIdEdit=${userIdEdit}`)
            .then(result => {
                PreloadData = JSON.parse(result.data.content)
            });

        return PreloadData;
    }

    public async UpdateUserPreloadBaskets(userId: string, requestingUserId: string): Promise<any> {
        let PreloadData: any;
        const response = await newAxios.get(`${CONTROLLER}UpdateUserPreloadBaskets?userId=${userId}&requestingUserId=${requestingUserId}`)
            .then(result => {
                PreloadData = JSON.parse(result.data.content)
            });

        return PreloadData;
    }
}