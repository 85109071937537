import newAxios from "@/services/invoicesAxiosService";
import { IInvoiceDetailsPower } from "../interfaces/invoiceDetailsPower";
import { IFileDownload } from "@/interfaces/fileDownload";
import getFileName from "@/helpers/fileNameHelper"

const CONTROLLER = "/invoicepower/";
export default class InvoicesPowerService {

    public async fetchInvoices(accountNum: string, userId: string) {
        const response = await newAxios.get(`${CONTROLLER}siteInvoices`, { params: { accountNum: accountNum, userId: userId } });
        const result = JSON.parse((response as any).data.content) as IInvoiceDetailsPower[];
        return result;
    }

    public async invoiceDownload(accountNum, invoiceNo, userId) {
        const response = await newAxios.get(`${CONTROLLER}siteInvoices/download`, { params: { invoiceNo, userId, accountNum }, responseType: "blob" });
        const fileName = getFileName(response.headers['content-disposition']);

        if (!fileName) {
            throw new Error("Invoices could not be downloaded");
        }

        const file = {
            fileName: fileName,
            data: response.data as Blob
        } as IFileDownload;

        return file;

    }
}