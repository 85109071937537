






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class WizardBaseStep extends Vue {
    @Prop({ type: String, default: '' }) readonly title!: string;
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: String, default: '' }) readonly accordionGroupName!: string;
    @Prop({ type: Boolean, default: false }) readonly visible!: boolean;
    private switchStatus = false;
    private visibilityStatus = false;


    mounted(): void {
        this.$root.$on('bv::collapse::state', (collapseId: string, isJustShown: boolean) => {
            if (collapseId == this.id) {
                this.switchStatus = isJustShown;
            }
        });
        this.visibilityStatus = this.visible;
    }
}
