import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import BillingService  from '@/services/billingService';
import { IFileDownload } from "@/interfaces/fileDownload";
import { IBillingReportRequestParams } from "@/interfaces/billingReportRequestParams";
import { IBillingReportRequestResponse } from "@/interfaces/billingReportRequestResponse";

@Module({ namespaced: true })
export default class Billing extends VuexModule {

    private billingService = new BillingService();


    private billingReportRequestResponse: (IBillingReportRequestResponse | null) = null;

    private downloadFileData: { data: Blob | null, fileName: string | null } | null = null;

    private billingReports: IBillingReportRequestResponse[] = [];
    

    // Getters
    get getBillingReportRequestResponse(): IBillingReportRequestResponse | null {
        return this.billingReportRequestResponse;
    }

    get getBillingReports(): IBillingReportRequestResponse[] {
        return this.billingReports;
    }

    get getDownloadFileData() {
        return this.downloadFileData;
    }


    @Action({ rawError: true })
    public async fetchBillingReports(params: { userId: string, accountNumber: string }): Promise<void> {
        
        const billingReports = await this.billingService.getGeneratedBillingReports(params.userId,params.accountNumber);

        this.context.commit('setBillingReports', billingReports);
    }

    @Mutation
    private setBillingReports(billingReports: IBillingReportRequestResponse[]) {
        this.billingReports = billingReports;
    }

    @Mutation
    private setDownloadFile(data: any) {
        this.downloadFileData = data;
    }   

    @Action({ rawError: true })
    public async submitBillingReportRequest(params: IBillingReportRequestParams): Promise<void> {
        const downloadFileData = await this.billingService.generateBillingReport(params);

        this.context.commit('setDownloadFile', downloadFileData);
    }


    @Action({ rawError: true })
    public async submitBillingReportForAllAccountsRequest(params: IBillingReportRequestParams): Promise<void> {
        const downloadFileData = await this.billingService.generateBillingReportForAllAccounts(params);

        this.context.commit('setDownloadFile', downloadFileData);
    }

    @Action({ rawError: true })
    public async removeGeneratedBillingReport(params: { userId: string, accountNumber: string, id: string }): Promise<boolean> {
        const result = await this.billingService.removeGeneratedBillingReport(params.userId, params.accountNumber, params.id);
       
        return result;
    }    
}