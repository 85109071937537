import Vue from 'vue';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import GasAccountsService from '@/services/gasAccountsService';

import { IUserAccount } from "@/interfaces/userAccount";
import { IDeliveryAgreement } from "@/interfaces/deliveryAgreement";
import { IUserGasSelections } from "@/interfaces/userGasSelections";

@Module({ namespaced: true })
export default class GasAccounts extends VuexModule {
    private gasAccountsService = new GasAccountsService();
    private readonly cookieKey = "userGasSelections";

    // State
    private page = 1;
    private pageSize = 200;
    private userAccountsCount = -1;
    private accountsCountForCurrentSearch = -1;
    private userAccounts = Array<IUserAccount>();
    private userDeliveryAgreements = Array<IDeliveryAgreement>();
    private userDeliveryAgreementsLinkedToMPRN = Array<IDeliveryAgreement>();
    private distinctMPRNS = Array<string>();
    private userAccountsLoaded = false;

    private isMprnSelectionDisabled = false;
    private isAgreementSelectionDisabled = false;
    private isAccountSelectionDisabled = false;

    private userGasSelections = {
        UserId: null,
        SelectedGasAccount: null,
        SelectedAgreementId: null,
        SelectedGasDeliveryAgreement: null,
        SelectedGasSiteRefNumber: null,
        SelectedGasMprn: null,
        SelectedGasContractId: null
    } as IUserGasSelections;

    //Getters
    get isSingleSite(): boolean {
        const result = this.userDeliveryAgreements.filter((i) => i.ContractId > 0);
        if (result == null || result == undefined || result.length == 0 ) {
            return false;
        }
        const mprn = (result[0] as IDeliveryAgreement).MeterPointReferenceNumber;
        return result.every((i) => i.MeterPointReferenceNumber == mprn);
    }

    get isSass(): boolean {
        return this.userAccountsCount == 1 && this.isSingleSite;
    }

    get isSams(): boolean {
        return this.userAccountsCount == 1 && !this.isSingleSite;
    }

    get getIsMorePages(): boolean {
        return (this.page * this.pageSize) < this.accountsCountForCurrentSearch;
    }
        
    get getUserAccountsCount(): number {

        // Not sure why this was added
        // Commenting out as it slows down getting count of accounts for users which have less than 50 accounts
        //if (!this.isLargeUser) { // use getUserAccounts as is more accurate
        //    return this.getUserAccounts.length ?? 0;
        //}

        return this.userAccountsCount;
    }

    get getUserAccounts(): Array<IUserAccount> {
        return this.userAccounts;
    }

    get getUserAccountsLoaded(): boolean {
        return this.userAccountsLoaded;
    }

    get getUserDeliveryAgreements(): Array<IDeliveryAgreement> {
        return this.userDeliveryAgreements;
    }

    get getUserDeliveryAgreementsLinkedToMPRN(): Array<IDeliveryAgreement> {
        return this.userDeliveryAgreementsLinkedToMPRN;
    }

    get getDistinctMPRNS(): Array<string> {
        return this.distinctMPRNS;
    }

    get getSelectedAccount(): string | null {
        return this.userGasSelections.SelectedGasAccount;
    }

    get getSelectedDeliveryAgreement(): IDeliveryAgreement | null {
        return this.userDeliveryAgreements.find(x => x.DeliveryAgreementId === this.getSelectedDeliveryAgreementId) ?? null;
    }

    get getSelectedDeliveryAgreementId(): string | null {
        return this.userGasSelections.SelectedAgreementId;
    }

    get getSelectedDeliveryAgreementNumber(): string | null {
        return this.userGasSelections.SelectedGasDeliveryAgreement;
    }

    get getSelectedSiteRefNumber(): string | null {
        return this.userGasSelections.SelectedGasSiteRefNumber;
    }

    get getSelectedMprn(): string | null {
        return this.userGasSelections.SelectedGasMprn;
    }

    get getSelectedContractId(): number | null {
        return this.userGasSelections.SelectedGasContractId;
    }

    get isLargeUser(): boolean {
        return this.userAccountsCount > 50;
    }

    get getIsMprnSelectionDisabled(): boolean {
        return this.isMprnSelectionDisabled;
    }

    get getIsAgreementSelectionDisabled(): boolean {
        return this.isAgreementSelectionDisabled;
    }

    get getIsAccountSelectionDisabled(): boolean {
        return this.isAccountSelectionDisabled;
    }

    // Mutations
    @Mutation
    private resetPageNumber() {
        this.page = 1;
    }

    @Mutation
    private nextPage() {
        if ((this.page * this.pageSize) < this.accountsCountForCurrentSearch)
            this.page += 1;
    }

    @Mutation
    private setUserAccountsCount(accountsCount: number) {
        this.userAccountsCount = accountsCount;
    }

    @Mutation
    private setAccountsCountForCurrentSearch(accountsCount: number) {
        this.accountsCountForCurrentSearch = accountsCount;
    }

    @Mutation
    private setUserAccounts(userAccounts: Array<IUserAccount>) {
        this.userAccounts = userAccounts;
        this.userAccountsLoaded = true;
    }

    @Mutation
    private setUserAccounts_Append(returnedAccounts: Array<IUserAccount>) {
        
        for (let i = 0; i < returnedAccounts.length; i++) {

            const match = this.userAccounts.filter(x => x.AccountNumber === returnedAccounts[i].AccountNumber);

            if (match) {
                returnedAccounts.splice(i, 1);
                i--;
            }
        }

        this.userAccounts = [...this.userAccounts, ...returnedAccounts];
    }

    @Mutation
    private setUserDeliveryAgreements(deliveryAgreements: Array<IDeliveryAgreement>) {
        this.userDeliveryAgreements = deliveryAgreements;
    }

    @Mutation
    private setDistinctMPRNS(MPRNS: Array<string>) {
        this.distinctMPRNS = MPRNS;
    }

    @Mutation
    private loadUserGasSelectionsFromCookie(userId: string) {
        const userSelections = Vue.$cookies.get(this.cookieKey) as IUserGasSelections;
        const userIdInCookies = userSelections?.UserId;

        if (userId === userIdInCookies) {
            this.userGasSelections = userSelections;
        }

        this.userGasSelections.UserId = userId;
        Vue.$cookies.set(this.cookieKey, this.userGasSelections);
    }

    @Mutation
    public clearState() {
        this.userGasSelections = {
            UserId: null,
            SelectedGasAccount: null,
            SelectedAgreementId: null,
            SelectedGasDeliveryAgreement: null,
            SelectedGasSiteRefNumber: null,
            SelectedGasMprn: null,
            SelectedGasContractId: null
        };
        this.userAccountsCount = -1;
        this.accountsCountForCurrentSearch = -1;
        this.userAccounts = Array<IUserAccount>();
        this.userDeliveryAgreements = Array<IDeliveryAgreement>();
        this.page = 1;
        this.userAccountsLoaded = false;
    }

    @Mutation
    public setDeliveryAgreementsByAccountNumber(accountNumber: string) {
        const deliveryAgreements = this.userAccounts.find(x => x.AccountNumber === accountNumber)?.DeliveryAgreements;
        const distinctMPRNS = this.userAccounts.find(x => x.AccountNumber === accountNumber)?.DistinctMPRNS;
        this.userDeliveryAgreements = deliveryAgreements ?? new Array<IDeliveryAgreement>();
        this.distinctMPRNS = distinctMPRNS ?? new Array<string>();
    }

    @Mutation
    public setSelectedDeliveryAgreementAndMprn(agreementId: string) {
        const agreement = this.userDeliveryAgreements.find(x => x.DeliveryAgreementId === agreementId);
        this.userGasSelections.SelectedAgreementId = agreement?.DeliveryAgreementId ?? null;
        this.userGasSelections.SelectedGasDeliveryAgreement = agreement?.UmaxSiteRefNum ?? agreement?.SiteRefNum ?? null;
        this.userGasSelections.SelectedGasSiteRefNumber = agreement?.SiteRefNum ?? null;
        this.userGasSelections.SelectedGasContractId = agreement?.ContractId ?? null;
        Vue.$cookies.set(this.cookieKey, this.userGasSelections);
    }

    @Mutation
    public setSelectedMeterPointReferenceNumber(MPRN: string) {
        const agreement = this.userDeliveryAgreements.find(x => x.MeterPointReferenceNumber === MPRN);
        this.userGasSelections.SelectedGasMprn = agreement?.MeterPointReferenceNumber ?? null;
        Vue.$cookies.set(this.cookieKey, this.userGasSelections);
    }

    @Mutation
    public setUserDeliveryAgreementsLinkedToMPRN(deliveryAgreementsWithLabels: IDeliveryAgreement[]) {
        this.userDeliveryAgreementsLinkedToMPRN = deliveryAgreementsWithLabels;
    }

    @Mutation
    public setSelectedAccount(accountNumber: string) {
        const account = this.userAccounts.find(x => x.AccountNumber === accountNumber);
        this.userGasSelections.SelectedGasAccount = account?.AccountNumber ?? null;
        Vue.$cookies.set(this.cookieKey, this.userGasSelections);
    }

    @Mutation
    public setMprnSelectionDisabled(disabled: boolean) {
        this.isMprnSelectionDisabled = disabled;
    }

    @Mutation
    public setAgreementSelectionDisabled(disabled: boolean) {
        this.isAgreementSelectionDisabled = disabled;
    }

    @Mutation
    public setAccountSelectionDisabled(disabled: boolean) {
        this.isAccountSelectionDisabled = disabled;
    }

    // Actions
    @Action({ rawError: true })
    public async fetchUserAccountsCount(userId: string): Promise<void> {
        const userAccountsCount = await this.gasAccountsService.fetchUserAccountsCount(userId);
        this.context.commit('setUserAccountsCount', userAccountsCount);
    }

    @Action({ rawError: true })
    public async fetchUserAccounts(params: { userId: string, accountNumberQuery: string | null }): Promise<void> {
        const pageSize = this.userAccountsCount > 200 ? 200 : 0;

        const userAccountsData = await this.gasAccountsService.fetchUserAccounts(params.userId, pageSize, params.accountNumberQuery, this.isLargeUser);
        const selectedAccountNumber = this.userGasSelections.SelectedGasAccount ?? userAccountsData.SelectedAccount;
        const selectedAgreementId = this.userGasSelections.SelectedAgreementId ?? userAccountsData.SelectedAgreementId;
        const selectedMPRN = this.userGasSelections.SelectedGasMprn ?? userAccountsData.SelectedMPRN;

        for (let i = 0; i < userAccountsData.UserAccounts.length; i++) {
            const item = userAccountsData.UserAccounts[i];

            if (item.DeliveryAgreements.length === 0) {
                continue;
            }

            item.DeliveryAgreements = await this.gasAccountsService.addCategoryLabelsToAgreements(item.DeliveryAgreements,params.userId);
        }

        this.context.commit('setAccountsCountForCurrentSearch', userAccountsData.TotalAccounts);
        this.context.commit('setUserAccounts', userAccountsData.UserAccounts);
        this.context.commit('setDeliveryAgreementsByAccountNumber', selectedAccountNumber);
        this.context.commit('setSelectedDeliveryAgreementAndMprn', selectedAgreementId);
        this.context.commit('setSelectedAccount', selectedAccountNumber);

        this.context.dispatch('fetchDeliveryAgreementsWithLabelsAfterMPRNSelection', ({ userId: params.userId, MPRN: selectedMPRN }));
    }

    @Action({ rawError: true })
    public async fetchUserAccountsAfterSearch(params: { userId: string, accountNumberQuery: string | null }): Promise<void> {
        const pageSize = this.userAccountsCount > 200 ? 200 : 0;

        const userAccountsData = await this.gasAccountsService.fetchUserAccounts(params.userId, pageSize, params.accountNumberQuery, this.isLargeUser);

        this.context.commit('setAccountsCountForCurrentSearch', userAccountsData.TotalAccounts);
        this.context.commit('setUserAccounts', userAccountsData.UserAccounts);
    }

    @Action({ rawError: true })
    public async searchUserAccounts(params: { userId: string, query: string, appendResult: (boolean | null) }): Promise<void> {
        const pageSize = this.userAccountsCount > 200 ? 200 : 0;
        const userAccountsData = await this.gasAccountsService.searchUserAccounts(params.userId, params.query, this.page, pageSize, this.isLargeUser);

        this.context.commit('setAccountsCountForCurrentSearch', userAccountsData.TotalAccounts);

        if (params.appendResult) {
            this.context.commit('setUserAccounts_Append', userAccountsData.UserAccounts);
            return;
        }

        this.context.commit('setUserAccounts', userAccountsData.UserAccounts);
      
    }

    @Action({ rawError: true })
    public async fetchDeliveryAgreementsForAccount(params: { userId: string, accountNumber: string }): Promise<void> {

        const data = await this.gasAccountsService.fetchDeliveryAgreementsForAccount(params.userId, params.accountNumber, this.isLargeUser);

        if (!data) {
            return;
        }

        this.context.commit('setUserDeliveryAgreements', data[0]);
        this.context.commit('setSelectedDeliveryAgreementAndMprn', data[1]);
        this.context.commit('setDistinctMPRNS', data[2]);
        this.context.commit('setSelectedAccount', params.accountNumber);

        this.context.dispatch('fetchDeliveryAgreementsWithLabelsAfterMPRNSelection', ({ userId: params.userId, MPRN: data[3] }));
    }

    @Action({ rawError: true })
    public async fetchDeliveryAgreementsForAllAccounts(params: { userId: string }): Promise<void> {
        const userAccounts = this.userAccounts;
        const userAccountsCount = userAccounts.length;

        for (let i = 0; i < userAccountsCount; i++) {
            const accountNumber = userAccounts[i].AccountNumber;
            const data = await this.gasAccountsService.fetchDeliveryAgreementsForAccount(params.userId, accountNumber, this.isLargeUser);
            if (!data) {
                continue;
            }

            userAccounts[i].DeliveryAgreements = data[0];
        }

        this.context.commit('setUserAccounts', userAccounts);
    }

    @Action({ rawError: true })
    public async fetchAgreementIdToSelect(params: { userId: string, accountNumber: string }): Promise<void> {
        const data = await this.gasAccountsService.getAgreementIdToSelect(this.userDeliveryAgreements, params.userId);

        this.context.commit('setSelectedDeliveryAgreementAndMprn', data[0]);
        this.context.commit('setSelectedAccount', params.accountNumber);
        this.context.dispatch('fetchDeliveryAgreementsWithLabelsAfterMPRNSelection', ({ userId: params.userId, MPRN: data[1] }));
    }

    @Action({ rawError: true })
    public async fetchDeliveryAgreementsWithLabelsAfterMPRNSelection(params: { userId: string, MPRN: string }): Promise<void> {
            const deliveryAgreementsLinkedToMPRN = this.userDeliveryAgreements.filter(x => x.MeterPointReferenceNumber === params.MPRN);
            const deliveryAgreementsWithLabelsLinkedToMPRN = await this.gasAccountsService.addCategoryLabelsToAgreements(deliveryAgreementsLinkedToMPRN, params.userId);
            this.context.commit('setUserDeliveryAgreementsLinkedToMPRN', deliveryAgreementsWithLabelsLinkedToMPRN);
            this.context.commit('setSelectedMeterPointReferenceNumber', params.MPRN);
    }

    @Action({ rawError: true })
    public async fetchAgreementIdToSelectAfterMPRNSelection(params: { userId: string, accountNumber: string, MPRN: string }): Promise<void> {
        const deliveryAgreementsLinkedToMPRN = this.userDeliveryAgreements.filter(x => x.MeterPointReferenceNumber === params.MPRN);
        const data = await this.gasAccountsService.getAgreementIdToSelect(deliveryAgreementsLinkedToMPRN, params.userId);

        this.context.commit('setSelectedDeliveryAgreementAndMprn', data[0]);
    }

    @Action({ rawError: true })
    public loadUserGasSelections(userId: string): void {
        this.context.commit('loadUserGasSelectionsFromCookie', userId);
    }
}