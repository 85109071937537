import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '@/store'

import Index from '../views/Index.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Dashboard from '../views/Dashboard.vue'
import CookiePolicy from '../views/CookiePolicy.vue'
import TermsOfUse from '../views/TermsOfUse.vue'
import FaqsPage from '../views/FaqsPage.vue'
import ContentManager from '../views/ContentManager.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Index',
        component: Index
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword
    },
    {
        path: '/home',
        name: 'Home',
        component: Dashboard,
        meta: { requiresAuth: true }
    },

    {
        path: '/faqs',
        name: 'FaqsPage',
        component: FaqsPage
    },
    {
        path: '/cookiepolicy',
        name: 'CookiePolicy',
        component: CookiePolicy
    },
    {
        path: '/termsofuse',
        name: 'TermsOfUse',
        component: TermsOfUse
    },
    {
        path: '/contentmanager',
        name: 'ContentManager',
        component: ContentManager,
        meta: { requiresAuth: true }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeResolve((to, from, next) => {
    
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start()
    }
    next()
})

router.beforeEach((to, from, next) => {
    const isLoggedIn = store.getters["Auth/isLoggedIn"];
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    if (requiresAuth && !isLoggedIn) {
        // Redirect user to login page if trying to access a protected route without being logged in
        next('/');
    } else {
        next();
    }
})

router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    NProgress.done()
})

export default router
