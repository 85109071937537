










import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CtaButton extends Vue {
    @Prop({ type: String, required: true }) readonly ctaButtonLabel!: string;
    @Prop({ type: String, default: '#' }) readonly ctaButtonHref!: string;

}
