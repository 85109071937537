import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import FeatureToggleService from '@/services/featureToggleService';

@Module({ namespaced: true })
export default class FeatureToggle extends VuexModule {
    private featureToggleService = new FeatureToggleService();

    private getInvoiceMeterReadPanelFeatureToggleStatus: boolean | false = false;

    // Getters
    get getInvoiceAndMeterReadPanelFeatureToggleStatus() {
        return this.getInvoiceMeterReadPanelFeatureToggleStatus;
    }

    //Mutations
    @Mutation
    private setMeterReadPanelFeatureToggleStatus(status: any) {
        this.getInvoiceMeterReadPanelFeatureToggleStatus = status;
    }

    //Actions
    @Action({ rawError: true })
    public async getInvoiceAndMeterReadPanelFeatureToggleStatusAction(userId: any): Promise<any> {
        const result = await this.featureToggleService.getStatus({ userId: userId, featureToggleName: 'Invoice and Meter read dashboard panels'});
        this.context.commit('setMeterReadPanelFeatureToggleStatus', result);
    }
}